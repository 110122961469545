<template>
  <div class="smsVerificationWrapper text-center col-sm-10">
    <ValidationObserver ref="numberForm" id="numberForm">
      <div class="inputWrapper">
        <div class="col-lg-3" ref="selectWrapper">
          <cool-select
            v-model="selectedCountry"
            :items="options"
            item-value="code"
            item-text="name"
            disable-search
            @select="onCountrySelected"
            @input="hideOptions"
            @blur="hideOptions"
            :inputElCustomAttributes="{ class: 'inp-holder' }"
          >
            <template slot="item" slot-scope="{ item }">
              <div class="country-item">
                <div>
                  <country-flag  :country='item.code' size='normal'/>
                </div>
                <div>
                  <b>{{ $t(item.phoneCode) }}</b>
                </div>
              </div>
            </template>
            <template slot="selection" slot-scope="{ item }">
              <div style="display: flex; align-items: center; column-gap: 10px;">
                <div>
                  <country-flag  :country='item.code' size='normal'/>
                </div>
                <div><b>{{ item.phoneCode }}</b></div>
              </div>
            </template>
          </cool-select>
        </div>
        <div class="col-lg-9">
          <textInput
            name="phoneNumber"
            id="phoneNumber"
            v-model="tel"
            class="phoneNumber col-lg-12"
            label=""
            placeholder="Mobile phone number"
            :validation="`required|phoneNumber`"
            :labelOnTop="false"
            :noLabel="true"
            :styles="inputStyles"
            :slideUp="tel != ''"
          />

        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import textInput from "@/components/renderers/text-input.vue";
import { CoolSelect } from "vue-cool-select";
import CountryFlag from 'vue-country-flag'
import("vue-cool-select/dist/themes/bootstrap.css");
import { mapMutations } from 'vuex';

export default {
  name: "sms-verification",
  components: {
    textInput,
    CoolSelect,
    CountryFlag
  },
  props: {
    successAction: Object,
    generateUrl: String,
    authUrl: String,
    onSuccess: {
      type: Object
    },
    inputStyles:{
      type:Object,
      required:false,
      default:()=>{}
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      countryCode: "",
      selectedCountry: "",
      tel: ""
    };
  },
  methods: {
    ...mapMutations(['setCountryCode', 'setTel']),
    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    async goto (page) {
      const isValid = await this.$refs.numberForm.validate();
      if(!isValid) {
        this.scrollTo(`[id=numberForm`);
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      }

      const formData = {
        mobile: `+${this.countryCode}${this.tel}`
      };
      const endpoint = this.generateUrl;

      this.$store.dispatch("generateCode", { formData, endpoint })
        .then(r => {
          this.setCountryCode(this.countryCode);
          this.setTel(this.tel);
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
        })
        .catch (err => {
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: false
          });
        })
      ;
    },
    onCountrySelected (item) {
      if (item.code === "other") {
        this.$refs.customInput.focus()
        this.countryCode = ""
      }
      if (item.phoneCode) {
        this.countryCode = +item.phoneCode
      }
      this.hideOptions();
    },
    showOptions () {
      this.$refs.selectWrapper.classList.remove('hide')
    },
    hideOptions () {
      this.$refs.selectWrapper.classList.add('hide')
    }
  },
  mounted () {
    this.$eventHub.$on('sendSms-event', this.goto)
    this.selectedCountry = this.options[0].code
    this.countryCode = +this.options[0].phoneCode
  },
  beforeDestroy () {
    this.$eventHub.$off('sendSms-event')
  }
};
</script>
<style lang="scss" scoped>

.inputWrapper {
  display: flex;
  justify-content: center;
  div {
    margin: 0;
    padding: 0;
  }

  ::v-deep .IZ-select__input.IZ-select__input--selection-slot {
    height: 46px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.country-selected-item {
  height: 48px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  width: 100%;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #D1E7FF !important;
  cursor: pointer;
  border-radius: var(--field-radius);
  &.is-invalid {
    border-color: #dc3545;
    background-color: #fff0f1;
  }

  .custom-input-wrap {
    padding-left: .75rem;
    display: inline-flex;
    align-items: center;

    .custom-input {
      padding-left: 4px;
      padding-right: 28px;
    }
    .custom-input-warn {
      position: absolute;
      right: 15px;
      width: 16px;
    }
  }
}

.country-item {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
</style>
