<template>
  <div class="steps" :class="mainWrapperClass" :style="[cssVars]">
    <div v-for="(step, idx) in steps" :key="idx" class="step" :class="`${idx}`" :style="styles">
      <div class="image-wrapper stepImageWrapperSize" :style="step.icon.bgStyles">
        <img class="step-image" :class="`step-image-${idx+1}`" :src="step.icon.src" :alt="step.icon.alt" :style="step.icon.styles">
      </div>
      <div class="step-title" v-html="$t(step.stepText)"/>
      <div> 
        <img class="step-arrow-mobile" v-if="!(idx+1 === steps.length)" src="/assets/steps/step-arrow-down.svg"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "steps-horizontal",
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    styles: {
      type: Object,
      default: () => {}
    },
    colorSchema: {
      type: String,
      default: ""
    },
    mainWrapperClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.step-image{
  @media(max-width: 741px){
    top: 14px !important;
  }
  @media(max-width: 450px){
    top: 10px !important;
  }
}
.step-arrow-mobile{
  display: none;
  @media (max-width: 450px){
    display: flex;
    width: 35px;
    height: 35px;
    margin-top: 40px;
    margin-bottom: -50px;
  }
}
.stepImageWrapperSize{
  @media(max-width: 450px){
    position: relative;
    overflow: hidden;
    width: 30vw !important;
    height: 30vw !important;
  }
}
  .steps {
    font-weight: normal;
    font-size: 21px;
    line-height: 25px;
    justify-content: space-between;
    display: flex;
    width: 85%;
    @media (max-width: 667px) {
      flex-direction: column;
      align-items: center;
      row-gap: 100px;
    }
    .step:not(:last-child) {
      .image-wrapper::after {
        content: ' ';
        width: 2.4vw;
        height: 2vw;
        max-width: 100%;
        background-image: url('@/assets/steps/arrow-black.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
        margin-left: 7%;
        @media(min-width: 2559px){
          width: 3.4vw !important;
          height: 3vw !important;
          margin-left: 68% !important;
        }
        @media(min-width: 1901px){
          width: 3.4vw;
          height: 3vw;
          margin-left: 32%;
        }
        @media(max-width: 1901px){
          width: 3.4vw;
          height: 3vw;
          margin-left: 32%;
        }
        @media(max-width: 1690px){
          width: 3.4vw;
          height: 3vw;
          margin-left: 15%;
        }
        @media(max-width: 1600px){
          width: 3.4vw;
          height: 3vw;
          margin-left: 12%;
        }
        @media(max-width: 1441px){
          width: 2.4vw;
          height: 2vw;
          margin-left: 9%
        }
        @media(max-width: 1220px){
          width: 2.4vw;
          height: 2vw;
          margin-left: 5%
        }
        @media(max-width: 1025px){
          width: 2.4vw;
          height: 2vw;
          margin-left: 5%
        }
        @media(max-width: 741px){
          margin-left: 12%;
        }
        @media (max-width: 667px) {
            width: 100%;
            height: 30px;
            margin-left: 50%;
            background-image: none;
        }
        @media (max-width: 450px){
            width: 60%;
            height: 30px;
            margin-left: 15%;
            background-image: none;
        }
      }
    }
  }
  .step {
    width: 100%;
    position: relative;
    @media (max-width: 767px) {
      max-width: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .image-wrapper {
    width: 100%;
    position: relative;
  }
  
.step-title {
  margin-top: 30px;
  text-align: center;
  color: #14489E;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 21px;
    margin-top: 5px;
  }
}
</style>
