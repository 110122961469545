<template>
  <div class="d-flex justify-content-center align-items-center">
    <img v-if="mobile" :src="`/assets/${campaignName}/icons/participation.svg`" >
    <p :class="classString" :style="cssString" @input="updateComponent" v-html="`${html} <b> ${participationNumber} </b>`" @click="triggerActions"></p>
    <img v-if="!mobile" :src="`/assets/${campaignName}/icons/participation.svg`" >
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import axios from 'axios';

export default {
  name: "participation",
  props: {
    text: {
      type: String,
      default: "Empty paragraph"
    },
    grid: {
      type: Object,
      default: () => ({
        backgroundColor: "#fff"
      })
    },
    actions: {
      type: Array
    },
    mobile: {
      type: Boolean,
      default: false
    },
    variables: Object,
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    classes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    campaignName: {
      type: String,
      default: "liners"
    },
    totalAllowedParticipations: {
      type: String,
      default: "50.000"
    }
  },
  data () {
    return {
      cssString: "",
      classString:"",
      totalParticipations: "nan",
      isLoading: false
    };
  },
  computed: {
    html () {
      let result = this.text;
      if (this.variables) {
        for (const variable in this.variables) {
          let value = this.$store.state.formData[variable];
          if(variable === "referenceNumber") {
            value = this.$store.getters.getReferenceNumber
          }
          result = result.replace(new RegExp(`{${variable}}`, "g"), value);
        }
      }
      return result;
    },

    participationNumber () {

      if (!isNaN(this.totalParticipations)) {

        let participationsLeft = (this.totalParticipations + "");

        // If participations are less than 1000, we do not need the dot in between for styling
        // Else Add a dot after the 1000
        return participationsLeft < 1000 ? participationsLeft : `${participationsLeft.slice(0, participationsLeft.length - 3)}.${participationsLeft.slice(participationsLeft.length - 3)}`
      }
      return this.totalAllowedParticipations
    }
  },
  watch: {
    styles: {
      immediate: true,
      handler () {
        const keys = Object.keys(this.styles);
        const vals = Object.values(this.styles);
        keys.forEach((key, idx) => (this.cssString += key + ":" + vals[idx]));
      }
    },
    classes: {
      immediate: true,
      handler () {
        this.classes.forEach(cls=>{
          this.classString += cls+" ";
        })
      }
    }
  },
  methods: {
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000),
    triggerActions () {
      if (this.actions) {
        this.actions.forEach(action => {
          this.$store.dispatch(action, { val: 123 });
        });
      }
    }
  },
  mounted () {
    axios.post('https://template-v2.justsnap.eu/api/participationCount', {
      campaignId: this.$store.state.campaignId
    })
      .then(({ data }) => {
        this.totalParticipations = data.remainingParticipation;
        this.isLoading = false
      })
  }
};
</script>

<style lang="scss" scoped>
p {
  outline: 0;
  margin-bottom: 0;
}

.heading {
  margin: 0px 20px;
  color: var(--color2);
  text-align: center;
  font: normal normal 500 22px/25px;
  letter-spacing: 0px;
  opacity: 1;
}

img { width: 40px; height: 40px; }

@media (max-width: 500px) {
  .heading {font-size: 15px; line-height: 15px; margin: 0px ;}
  img { width: 50px; height: 50px; }
}

.AvenirLight {
  font-family: avenir_lt_35_lightregular;
}

.AvenirMedium {
  font-family: avenir_lt_65_mediumregular;
}

.headingNightPad {
  margin: 5px 10px 0 0;
  font-size: 20px;
}

</style>
