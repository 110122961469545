<template>
  <div class="barcode-scanner__search d-flex center" :style="`--brand-color: ${colorSchema}`">
    <b-input-group
      class="barcode-scanner__search-input"
      style="width: 100% !important"
    >
      <b-form-input
        :value="barcode"
        @input="handleInput($event)"
        :state="inputStatus"
        :placeholder="$t(texts.barcodePlaceholder)"
        class="search-form-input"
      >
      </b-form-input>
      <b-input-group-append>
        <button-component
          v-if="!disabled"
          class="btn btn-append"
          style="width: 100%; z-index: 0 !important; height: 48px"
          :class="`btn_${status} ${classesObject.scanButton}`"
          :disabled="isLoading"
          @click.native="triggerSearch( barcode, maxProductCount )"
          :text="$t(texts.searchButton)"
        />
        <button
          v-if="disabled"
          type="button"
          class="btn btn-append"
          style="width: 100%; z-index: 0 !important"
          :class="`btn_disabled`"
        >
          Add
        </button>
      </b-input-group-append>
      <div class="validation-error" :class="triggerShake? 'shake-text':''" v-if="disabled && error">
        <div class="error_message">{{ $t(errorMessage) }}</div>
      </div>
      <b-form-invalid-feedback id="input-live-feedback" :class="triggerShake? 'shake-text':''" v-html="$t(texts.invalidMessage)" class="feedback-padding">
      </b-form-invalid-feedback>
      <b-form-valid-feedback id="input-live-feedback" :class="triggerShake? 'shake-text':''" v-html="$t(texts.validMessage)" class="feedback-padding">
      </b-form-valid-feedback>
    </b-input-group>
    <div class="d-none optional-button-container" :class="marginAdjust? 'adjust-margin-top': ''">
      <b-button
        pill
        block
        @click="triggerSearch( barcode, maxProductCount )"
        class="scan_btn"
        :class="classesObject.scanButton"
        >{{ $t(texts.searchButton) }}</b-button
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import buttonComponent from "../button-component.vue";

export default {
  name: "searchModule",
  components: {
    buttonComponent
  },
  data () {
    return {
      disabled: false,
      error: false,
      errorMessage:
        "Bitte trage exakt 13 Stellen für einen gültigen Barcode ein.",
      isLoading: false,
      triggerShake: false,
      marginAdjust: false,
    };
  },
  props: {
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    maxProductCount: Number,
    colorSchema: {
      type: String,
      default: "#5a3374"
    },
    classesObject: {
      type: Object,
      required: false
    }
  },
  computed: {
    ...mapState({
      barcode: (state) => {
        return state.productCode
      },
      status: (state) => {
        return state.searchStatus
      }
    }),
    inputStatus () {
      let state;
      switch (this.status) {
      case "success":
        state = true;
        break;
      case "not_found":
        state = false;
        break;
      case null:
        state = null;
        break;
      default:
        break;
      }
      return state;
    }
  },
  methods: {
    ...mapActions([
      "handleProductCode",
      "updateSearchStatus",
      "searchProductByBarcode"
    ]),
    async triggerSearch(barcode, maxProductCount){
      let status = await this.searchProductByBarcode({ barcode, maxProductCount })
      if(!status){
        this.triggerShake = true
        setTimeout(()=> {
          this.triggerShake = false
        }, 200)
      }
      this.marginAdjust = true;
    },
    handleInput (ev) {
      //   console.log("BARCODE" , ev.length)
      //   if (ev.length != 13) {
      //     this.disabled = true;
      //     this.error = true;

      //     this.updateSearchStatus('not_found')
      //     this.handleProductCode(ev)
      //   } else {
      //     this.disabled = false;
      //     this.error = false;

      this.updateSearchStatus(null);
      this.handleProductCode(ev);
      //   }
    },
    resetSelectedProducts () {
      this.$store.commit("resetProducts");
      this.$store.commit("setProductCode", "");
      this.$store.commit("setSearchStatus", null);
    }
  },
  mounted () {
    this.code = this.barcode;
    this.$eventHub.$on("resetProducts-event", this.resetSelectedProducts);
  }
};
</script>

<style scoped lang="scss">
.shake-text{
  font-size: 12px !important;
  font-weight: 600 !important;
}
.search-form-input{
  z-index: 0 !important;
  background: #ffffff !important;
  border: 1px solid #aeaeae !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}
.feedback-padding{
  text-align: center;
  width: 100%;
  justify-content: center;
  padding-left: 0px;
}
.btn_disabled {
  font-size: 16px;
  font-weight: 500;
}
input[type="file"] {
  position: absolute;
  left: -99999px;
}
.hide {
  display: none;
}
.form-control {
  display: block;
  width: 40%;
  font-size: 12px;
}
label {
  margin-bottom: 0;
}
.was-validated .form-control:invalid,
.form-control.is-invalid,
.form-control:valid,
.form-control.is-valid {
  background-image: none !important;
}
.barcode-scanner {
  &__search {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 0 !important;
    position: relative !important;
    &-input {
      input {
        font-size: 14px;
        line-height: 32px;
        height: 48px;
        border-radius: 27px 0 0 27px !important;
        @media (max-width: 425px) {
          font-size: 10.5px;
        }
      }
    }
  }
  &__divider {
    width: 100%;
    margin-top: 50px;
    hr {
      border: 1px solid #939393;
    }
    .col-6 {
      font-size: 20px;
      line-height: 32px;
    }
  }
}

@media (max-width: 768px) {
  .barcode-scanner__search {
    padding: 0;
  }
}

.btn {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  background-color: var(--brand-color);
  border: 1px solid var(--brand-color);
  box-sizing: border-box;
  border-radius: 100px !important;
  color: white;

  &_not_found {
    background: #dd0019 !important;
    border-color: #dd0019 !important;
    height: 68% !important;
  }

  &_success {
    background: var(--brand-color) !important;
    border-color: var(--brand-color) !important;
    height: 68% !important;
  }

  &_button {
    color: #fff;
    background: #6f6f6f;
    border-color: #6f6f6f;
  }

  &_disabled {
    background: rgba(28, 135, 150, 0.1) !important;
    border-color: rgba(28, 135, 150, 0.1) !important;
    color: var(--color2) !important;
  }

  &-append {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 138px !important;
    height: 100%;
    top: 0;
    right: 0;
    position: absolute;
    max-height: 100%;
    border-radius: 0 4px 4px 0 !important;
    @media (max-width: 991px) {
      font-size: 13px;
      width: 118px !important;
    }
    @media (max-width: 425px) {
      font-size: 11px;
      width: 98px !important;
    }
  }
}

.error_message {
  display: inline-block;
}
</style>
