<template>
  <div class="barcode-scanner-container" :class="mainContainerClass">
    <div v-if="true" class="row fullWidthRow">
      <div class="col-md-4 BC-holder" v-if="false">
        <barcodeInformation :descriptionText="texts.description" />
      </div>
      <div class="responsiveMargin" :class="showModules === 3 ? 'col-md-8': 'col-md-4'">
        <barcodeScanner
          :texts="texts"
          :height="readerHeight"
          :width="readerWidth"
          :showModules="showModules"
          :cameraTimeOut="cameraTimeOut"
          :maxProductCount="maxProductCount"
          :iconsObject="iconsObject"
          :classesObject="classesObject"
        />
      </div>
      <div class="col-md-4 SM-holder" :class="classesObject.searchModule">
        <div class="searchModuleContainer">
          <div class="icon-container" v-if="iconsObject.showIcons">
            <img class="barcode-icon" :src="iconsObject.iconSourceSearch" alt="Search Barcode">
          </div>
          <div class="above-search-label" v-if="iconsObject.showIcons">
            {{$t('Barcode-Nummer eingeben')}}
          </div>
          <SearchModule
            v-on:some-event="redirect($event)"
            :endpoint="endpoint"
            :maxProductCount="maxProductCount"
            :texts="texts"
            :classesObject="classesObject"
          />
        </div>
      </div>
      <productsList
        v-if="list.length"
        :fullSize="true"
        :maxProductCount="maxProductCount"
      />
    </div>
    <div v-else class="row mobileRow">
      <barcodeScanner
        :texts="texts"
        :height="readerHeight"
        :width="readerWidth"
        :cameraTimeOut="cameraTimeOut"
        :tabletView="true"
        :list="list"
        :maxProductCount="maxProductCount"
        :classesObject="classesObject"
      />
    </div>
    <div class="row button-row">
      <div class="col-md-12 d-flex justify-content-center align-items-center" style="padding-top: 40px">
        <button-component
          v-if="pageToBack !== 'reviewProducts'"
          class="btn back-btn col-12 col-sm-4 col-md-4 col-lg-3"
          :class="[classesObject.backButton]"
          :disabled="false"
          @click.native="backToPage"
          :text="$t(texts.backButton)"
          :arrow="btnArrows.showArrows ? { prev: true, color: btnArrows.prev } : {}"
        />
        <button-component
          v-if="pageToBack !== 'reviewProducts'"
          class="btn next-btn col-12 col-sm-4 col-md-4 col-lg-3"
          :class="[{ disabled: nextButtonDisabled }, classesObject.nextButton]"
          :disabled="nextButtonDisabled"
          @click.native="sendProducts"
          :text="$t(texts.nextButton)"
          :arrow="btnArrows.showArrows ? { next: true, color: btnArrows.next } : {}"
        />
        <button
          v-if="pageToBack === 'reviewProducts'"
          type="button"
          class="btn w-100 next-btn"
          :class="[{ disabled: nextButtonDisabled }, classesObject.nextButton]"
          :disabled="nextButtonDisabled"
          @click="backToProductsOverview"
        >
          Zurück zur Produktübersicht
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BarcodeScanner from "./BarcodeScanner";
import SearchModule from "./SearchModule";
import ProductsList from "../ProductsList";
import BarcodeInformation from "../barcode-information";
import buttonComponent from "../button-component.vue";
export default {
  name: "barcode-scanner",
  components: {
    BarcodeScanner,
    SearchModule,
    ProductsList,
    BarcodeInformation,
    buttonComponent
  },
  computed: {
    ...mapState({
      list: (state) => {
        return state.scannedProducts;
      },
      nextButtonDisabled: (state) => {
        return !state.scannedProducts.length;
      },
      status: (state) => {
        return state.searchStatus;
      },
      barcode: (state) => {
        return state.productCode;
      }
    }),
    showDisplayFull () {
      return !this.isMobileOrTablet(screen.width);
    },
    pageToBack () {
      return this.$store.getters.pageToBack;
    }
  },
  props: {
    readerHeight: {
      type: Number
    },
    readerWidth: {
      type: Number
    },
    showModules: { // 0 = none, 1 = scanner, 2 = uploader, 3 = both
      type: Number,
      required: false,
      default: 3
    },
    iconsObject: {
      type: Object,
      required: false,
      default: () => {
        return {
          showIcons: false,
          iconSourceScanner: '',
          iconSourceUploader: '',
          iconSourceSearch: ''
        }
      }
    },
    texts: {
      required: false,
      type: Object,
      default: () => {
        return {
          description: `Please enter your product's barcode number, take a photo or drag and drop. Please ensure that the barcode information for promotional items is clearly visible.`,
          searchButton: "Add",
          nextButton: "Continue",
          barcodePlaceholder: "Barcode Number",
          productScanButton: "Scan",
          validMessage: "The barcode is valid.",
          invalidMessage: "Invalid barcode number.",
          receiptUpload: "Upload",
          receiptUploadDescription:
            "Upload barcode image by drag and drop or from your device folder",
          wrongFormat:
            "The file format is not supported. It will only .jpeg, .png and .pdf file accepted.",
          fileSizeLimit:
            "The file is too large. Only files up to 16 MB are supported.",
          notParticipating:
            "The barcode does not appear to belong to a P&G product. Do you think this is an error?",
          notParticipatingVideo:
            "Unfortunately, the product does not appear to be participating in the promotion. Please try to enter the barcode in the field above.",
          timedOut:
            "Leider kann kein gültiger Barcode erkannt werden. Bitte versuchen Sie, den Barcode in das obige Feld einzugeben.",
          cameraNotFound: "Bitte schalten Sie Ihre Kamera ein",
          invalidBarCode:
            "Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?",
          uploaderTopText: "",
          uploaderBottomText: "",
          scannerTopText: "",
          scannerBottomText: ""
        };
      }
    },
    endpoint: {
      type: String
    },
    successAction: {
      type: [Object, Array]
    },
    errorPageName: {
      type: String
    },
    cameraTimeOut: {
      type: Number
    },
    maxProductCount: Number,
    mainContainerClass: {
      type: String,
      required: false,
      default: ''
    },
    classesObject: {
      type: Object,
      required: false,
      default: () => {
        return {
          uploadContainer: "",
          videoContainer: "",
          mainContainer: "",
          searchModule: "",
          scanButton: "",
          fileUploader: "",
          nextButton: "",
          backButton: "",
          topText: "",
          bottomText: ""
        };
      }
    },
    backUrl: {
      type: String,
      required: false,
      default: '3EURcashback/survey',
    },
    btnArrows: {
      type: Object,
      default: () => {
        return {
          showArrows: true,
          prev: '#FFF',
          next: '#FFF'
        }
      }
    }
  },
  methods: {
    ...mapActions([
      "getBarCode",
      "searchProductByBarcode",
      "setErrorActivate",
      "updateSearchStatus"
    ]),
    backToPage (){
      let pageUrl = this.backUrl
      this.goToPage(pageUrl, this)
      // window.location.href= `/${this.locale}/PampersMoneyback22`
    },
    sendProducts () {
      const endpoint = "/submitProducts";
      const formData = {
        selectedProducts: this.list,
        campaignId: this.$store.state.campaignId
      };
      if (this.locale?.length == 2) {
        formData.lang = this.locale;
      }
      this.$store.dispatch("sendProducts", { formData, endpoint }).then((r) => {
        if (r.response) {
          //set campaign ID for new always activation
          //if (r.campaignId) this.$store.commit("setCampaignId", r.campaignId);

          const fn = this.successAction.fn;
          let params = this.successAction.params;
          this[fn](params, this);
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: true
          });
          return;
        }
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      });
    },
    backToProductsOverview () {
      this.$eventHub.$emit("backToPage-event");
    }
  },
  destroyed () {
    // Set the error message to false
    this.setErrorActivate(false);
    this.$store.dispatch("findProducts", []);
    this.getBarCode("");
    this.updateSearchStatus(null);
  },
  mounted () {
    // Check if the search status error is true
    if (this.status === "not_found") this.setErrorActivate(true);
    else this.setErrorActivate(false);
  }
};
</script>

<style scoped lang="scss">
.responsiveMargin {
  @media (max-width: 450px) {
    margin-bottom: 20px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.mobileRow {
  max-width: 100% !important;
  width: 100% !important;
}
.barcode-scanner-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .fullWidthRow {
    width: 100% !important;
    @media (max-width: 450px) {
      //flex-direction: column
    }
  }
}
.product_list-relative_container {
  position: relative;
  height: 100%;
}
.product_list-absolute_container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
@media (max-width: 768px) {
  .product_list-absolute_container {
    position: static;
  }
}
.scroll {
  overflow-y: scroll;
}
.next-btn {
  background-color: var(--brand-color);
  border-radius: 6px;
  // margin-bottom: 40px;
  color: white;
  // height: 40px;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}
.next-btn.disabled {
  background: #e3e3e3 !important;
  border-color: #e3e3e3 !important;
  color: #6f6f6f;
}
.confirm-msg {
  background: #ffffff;
  border: 1px solid #dddfe1;
  box-sizing: border-box;
  border-radius: 20px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
</style>
