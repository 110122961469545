<template>
  <div :class="classes">
    <div :style="cssString" class="desktop-only">
      <div class="grid-container">
        <template>
          <div
            v-for="(value, idx) in points"
            :key="idx"
            :class="`grid-item col-sm-${columnValues[idx]}`"
          >
            <p v-html="$t(value)"></p>
          </div>
        </template>
      </div>
    </div>
    <div v-if="showOnMobile" :style="cssString" class="mobile-only">
      <div class="grid-container">
        <template>
          <div
            v-for="(value, idx) in mobilePoints"
            :key="idx"
            :class="`grid-item grid-item-mobile col-sm-${columnValuesMobile[idx]}`"
          >
            <p v-html="$t(value)"></p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "CampaignSteps",
  props: {
    text: {
      type: String,
      default: "Empty paragraph"
    },
    classes: {
      type: String,
      default: ""
    },
    grid: {
      type: Object,
      default: () => { }
    },
    points: {
      type: Array,
      default: () => []
    },
    mobilePoints: {
      type: Array,
      default: () => []
    },
    columnValues: {
      type: Array,
      default: () => [3, 3, 3, 3]
    },
    columnValuesMobile: {
      type: Array,
      default: () => [12, 12, 12, 12]
    },
    styles: {
      type: Object,
      default: () => { }
    },
    showOnMobile: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      cssString: ""
    };
  },
  watch: {
    styles: {
      immediate: true,
      handler () {
        const keys = Object.keys(this.styles);
        const vals = Object.values(this.styles);
        keys.forEach((key, idx) => (this.cssString += key + ":" + vals[idx]));
      }
    }
  },
  methods: {
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000),
    triggerActions () {
      if (this.actions) {
        this.actions.forEach((action) => {
          this.$store.dispatch(action, { val: 123 });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>

p {
  outline: 0;
}

.wrapper {
  padding: 2rem;
}

.grid-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  background: #FFFFFF;
  border: 2px solid #393939;
  border-radius: 12px;
  padding: 20px 15px;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
  }
}

.grid-item {
  text-align: center;
  letter-spacing: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 6px solid #393939;
  padding: 0 15px;

  &:first-child {
    border-top: none;
  }

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    text-align: center;
  }

  &-mobile {
    letter-spacing: 0px;
    color: #1b1b1b;
    border-left: none;
    border-right: none;
    border-bottom: 6px solid rgba(#393939, 0.2);
    padding: 20px 0;
  }
}

.grid-item:first-child {
  border-right: none !important;
  border-bottom: none !important;
}

.grid-item:last-child {
  border-right: none !important;
  border-bottom: none !important;
}

@media (max-width: 767px) {
  .imgClass {
    background-color: #e1e1e1;
    width: 30px;
    padding: 7px;
  }
}

@media (max-width: 767px) {
  .desktop-only {
    display: none;
  }
}
.mobile-only {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}

:deep .campaign-dates {
  background: #f699b0;
  border-radius: 12px;
  color: #191919;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  padding: 10px;
  width: 100%;

  @media (max-width: 992px) {
    font-size: 12px;
    line-height: 20px;
    padding: 8px;
  }
}

:deep .campaign-points {
  border-radius: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #3d3d41;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 12px;
    line-height: 24px;
  }
}
</style>
