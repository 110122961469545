import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)
function loadPage (pagePath) {
  return () => {
    return import(`@/${pagePath}`)
  };
}


const goTo = (to, from, next) => {
  // console.log("ROUTE", this, from, next)
}
const routes = [
  {
    path: '/sitedown',
    name: 'Sitedown',
    component: loadPage('views/Maintenance.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: loadPage('views/Home.vue')
  },
  {
    path: '/geoLimit',
    name: 'geo',
    component: loadPage('views/GeoLimit.vue')
  },
  {
    path: '/*',
    name: 'anyRootPath',
    component: loadPage('views/Home.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes
})


let campaigns = {
  'wow-wochenende': 'wow-wochenende',
  '3EURcashback': '3EURcashback',
  'pink-raffle': 'pink-raffle'
}

const VUE_APP_SITE_DOMAIN = process.env.VUE_APP_SITE_DOMAIN
let toLoop = true;
router.beforeEach((to, from, next) => {
  // if the user is not authenticated, `next` is called twice
  if (to.name === "security" && toLoop) {
    toLoop = false;
    // router.push(".wellknown/security.txt")
    window.location.href = `${VUE_APP_SITE_DOMAIN}.wellknown/security.txt`;
  } else {
    // Redirect to swiffer promotions page
    // window.location.href = `https://www.for-me-online.de/swiffer-coupons`;
    const path = window.location.pathname;
    Object.keys(campaigns).forEach(x => {
      if (path.startsWith(`/${x}`)) {
        const campaign = require(`../store/campaigns/${campaigns[x]}`).default;
        campaign.pageProperties.testing = store.state.pageProperties.testing;
        store.replaceState(campaign);
      }
    })

    /**
     * Check the campaign ending date, and if its true,
     * redirect to 'kampanya-bitti' page
     */

    const endDate = new Date(store.state.lastUploadDate).toLocaleString('en-US', { timeZone: 'Europe/Istanbul' });
    const currentTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Istanbul' });
    const dayInSeconds = 86400; // If we need to include the ending day
    let camEnded = (new Date(endDate).getTime() / 1000 + dayInSeconds) < (new Date(currentTime).getTime() / 1000)
    let allowedPages = ['campaign-is-over', 'teilnahmebedingungen', 'iban-reupload', 'faq', 'receipt-reupload', 'product-reupload', 'expired-page']
    if (camEnded) {
      if (!allowedPages.find(x => path.includes(x))) {
        window.location.href = `${path}/campaign-is-over`
      }
    }
    next();
  }
});


export default router
