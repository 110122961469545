<template>
  <span></span>
</template>

<script>
export default {
  name: 'redirectToCampaignIsOverPage',
  mounted() {
    this.goToPage("3EURcashback/campaign-is-over", this);
  },
};
</script>
