<template>
  <div class="container">
    <div class="row">
      <div class="page-title">
        FAQ
      </div>
      <div class="page-title">
        Swiffer
      </div>
      <ul ref="list" class="list">
        <li class="list-item-wrapper">
          <div class="number">
            Welche Bedingungen muss ich erfüllen, um an der Swiffer Gratis Testen Aktion teilnehmen zu können?
          </div>
          <div class="list-item-details">
<ul>

<li>Mindestalter: Sie müssen mindestens 18 Jahre alt sein.</li><ul></ul>
<li>Wohnort: Ihr Wohnort muss sich in Deutschland befinden.</li><ul></ul>
<li>Kontoverbindung: Sie müssen eine der Euro-Zone angehörige IBAN-Nummer besitzen.</li><ul></ul>
<li>E-Mail-Adresse: Die Teilnahme kann nur mit Ihrer eigenen E-Mail-Adresse erfolgen.</li>
<li>Sie müssen im Besitz einer Mobilfunknummer mit der Landesvorwahl +49, +43 oder +41 und eines
SMS-fähigen Endgerätes sein.</li>
</ul>
<p style="margin-top: 5px;">Anmerkung: IBAN, Handynummer und E-Mail-Adresse können für den gesamten Aktionszeitraum nur einmalig kombiniert werden.</p>
          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Wie kann ich an der Swiffer Gratis Testen Aktion teilnehmen?

          </div>
          <div class="list-item-details">

<p>Sie können ausschließlich online teilnehmen - und zwar über die Aktionswebsite <a href="https://www.swiffer-gratis-testen.de" target="_blank" rel="noopener noreferrer">www.swiffer-gratis-testen.de</a>. Eine Teilnahme über den Postweg ist leider nicht möglich. Der Vorgang kann folgendermaßen beschrieben werden:</p>
<ul>
<li>
  einen Fragebogen ausfüllen,
</li>
<li>
  die Aktionsprodukte (mind. 1 Produkt) angeben,
</li>
<li>
  Kaufbeleg fotografieren und hochladen,
</li>
<li>
  Foto der Rückseite Ihrer Swiffer-Aktionsprodukte hochladen (Sie haben darauf zu achten, dass der Barcode gut sichtbar ist),
</li>
<li>
  Bankverbindung (IBAN), E-Mail-Adresse, Handy-Nummer, weitere persönliche Daten angeben,
</li>
<li>
  Teilnahmebedingungen + Datenschutzrichtlinie akzeptieren,
</li>
<li>
  Identität per SMS-Code bestätigen.
</li>
</ul>

<p>Anmerkung: Die Verwendung verschiedener E-Mail-Adressen (Nutzerkonten) pro Person ist nicht möglich.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
An wen muss ich mich wenden, wenn ich eine Frage zur Swiffer Gratis Testen Aktion habe?

          </div>
          <div class="list-item-details">

<p>Bitte kontaktieren Sie dazu den Swiffer Aktions-Kundenservice via <a href="mailto:kontakt@swiffer-gratis-testen.de">kontakt@swiffer-gratis-testen.de</a>.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">Welche Produkte muss ich kaufen, um an der Swiffer Gratis Testen Aktion teilnehmen zu können?

          </div>
          <div class="list-item-details">

<p>An der Swiffer Gratis Testen Aktion nehmen folgende Produkte teil:</p>
<br>
<table class="dcf-table dcf-table-responsive dcf-table-bordered dcf-table-striped dcf-w-100%">
                          <tbody>
                            <tr>
                              <td>Swiffer Staubmagnet Starterset (Griff + 3 Tücher)</td>
                              <td>5410076702923</td>
                            </tr>
                            <tr>
                              <td>Swiffer Staubmagnet Starterset (Griff + 7 Tücher)</td>
                              <td>8001090591661</td>
                            </tr>
                            <tr>
                              <td>Swiffer Staubmagnet Starterset (Griff + 3 Tücher) (Hängevorrichtung)</td>
                              <td>4084500980037</td>
                            </tr>
                          </tbody>
                        </table>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Wo kann ich an der Swiffer Gratis Testen Aktion kaufen?

          </div>
          <div class="list-item-details">

Sie können die Aktionsprodukte bei allen eingetragenen Handelsunternehmen (online und offline)
innerhalb Deutschlands kaufen.


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Welcher Betrag wird mir rückerstattet?

          </div>
          <div class="list-item-details">

Sie bekommen 100% des TATSÄCHLICH bezahlten Kaufpreises (abzüglich eventueller Rabatte) des
eingereichten Aktionsartikels zurückerstattet. Die maximale Rückerstattung beträgt 5,99 € pro
Aktionsprodukt.


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Wann kann ich an der Swiffer Gratis Testen Aktion teilnehmen?

          </div>
          <div class="list-item-details">

<p>Der Zeitraum für den Erwerb von Aktionsprodukten ist vom <strong>01.04.2022</strong> bis inklusive <strong>31.07.2022</strong> (relevant ist hierbei das Datum des Kaufbelegs). Einsendeschluss für das Online-Anmeldeformular
und den Kaufbeleg-Upload ist der <strong>15.08.2022</strong>.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Wie oft kann ich an der Swiffer Gratis Testen Aktion teilnehmen?

          </div>
          <div class="list-item-details">

<p>Jede Person (Nutzerkonto: Kombination aus IBAN) kann mit einem Kaufbeleg und maximal zwei Produkten an der Swiffer Gratis Testen Aktion teilnehmen.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Mit wie vielen Produkten kann ich an der Swiffer Gratis Testen Aktion teilnehmen?

          </div>
          <div class="list-item-details">
            Pro Person kann für zwei Aktionsprodukte eine Rückerstattung veranlasst werden.
          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
 Mein Partner bzw. meine Partnerin und ich haben ein gemeinsames IBAN Konto. Können wir beide getrennt mit diesem Konto an der Swiffer Gratis Testen Aktion teilnehmen?

          </div>
          <div class="list-item-details">

<p>Jede IBAN kann für den gesamten Aktionszeitraum nur von einem Nutzer verwendet werden (in fixer Kombination mit E-Mail-Adresse ). Eine gemeinsame Nutzung mit einem weiteren Teilnehmer ist ungeachtet jedmöglicher persönlicher Verbindung nicht möglich.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Wann kann ich mit meiner Rückerstattung rechnen?

          </div>
          <div class="list-item-details">

<p>Nach positiver Prüfung Ihrer Teilnahme wird der Rückerstattungsbetrag auf das von Ihnen angegebene Bankkonto (IBAN) innerhalb von maximal 6 Wochen überwiesen.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Kann ich mit dem gleichen Kaufbeleg auch an anderen Rückerstattungs-Aktionen teilnehmen?

          </div>
          <div class="list-item-details">

<p>Sollten Sie mit Ihrem Kaufbeleg bereits an einer parallellaufenden Aktion teilgenommen haben, und für Ihre Swiffer bereits eine Rückerstattung bestätigt bekommen haben, ist es nicht möglich mit diesem Produkt und diesem Kaufbeleg auch bei der Swiffer Gratis Testen Aktion teilzunehmen.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Mein Kaufbeleg enthält mehr als zwei . Können diesen Kaufbeleg auch zwei oder mehrere Personen nutzen?

          </div>
          <div class="list-item-details">

<p>Ein Kaufbeleg kann prinzipiell nur einmalig an unserer Swiffer Gratis Testen Aktion teilnehmen. Weder kann der Umfang der auf dem Kaufbeleg angeführten Aktionsprodukte berücksichtigt werden, noch kann ein Aktionsprodukt auf eine andere Person übertragen werden.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Ich habe nach Abschluss des Teilnahmevorgangs keine Bestätigungs-E-Mail erhalten, was nun?

          </div>
          <div class="list-item-details">

<p>Wenn Sie registriert sind, haben Sie per E-Mail eine Bestätigung mit einer 10-stelligen Referenznummer erhalten. Prüfen Sie bitte auch Ihren SPAM- oder JUNK-Ordner. Wenn Sie keine 10-stellige Referenznummer erhalten haben, nehmen Sie bitte über die Aktionswebsite <a href="https://www.swiffer-gratis-testen.de" target="_blank" rel="noopener noreferrer">www.swiffer-gratis-testen.de</a> noch einmal teil.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
             Beim Durchlaufen des Rückerstattungsvorgangs geht es ab einem bestimmten Punkt nicht mehr weiter. Was soll ich tun?

          </div>
          <div class="list-item-details">

<p>Sollten Sie während des Teilnahmeprozesses aus technischen Gründen nicht weiterkommen, empfiehlt es sich - nach Möglichkeit - ein anderes Gerät zu benutzen. Wechseln Sie z.B. vom Handy auf den PC. Sollte dies nichts nützen, könnten Sie probieren, einen anderen Browser zu benutzen. Sollten Ihnen diese kleinen Tipps nicht weiterhelfen, schicken Sie bitte eine E-Mail an unsere Support-Abteilung: <a href="mailto:kontakt@swiffer-gratis-testen.de">kontakt@swiffer-gratis-testen.de</a>. Bitte geben Sie in Ihrer E-Mail eine genaue Beschreibung des Problems an - perfekt wäre ein Screenshot von dem Punkt, an dem es nicht mehr weiter geht. Weitere hilfreiche Daten sind, welches Gerät, welches Betriebssystem und welchen Browser Sie benutzen. Unser Support-Team wird Ihnen dann zur Seite stehen.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Wie kann ich erkennen, ob ich den Upload-Prozess des Kaufbelegs vollständig und erfolgreich durchlaufen habe?

          </div>
          <div class="list-item-details">

<p>Sobald Sie Ihren Kaufbeleg hochgeladen, Ihre persönlichen Daten und Bankdaten eingegeben und Ihre Daten per SMS-Code bestätigt haben, erscheint am Bildschirm eine Nachricht, die den Erfolg Ihres Teilnahmeversuchs bestätigt und Ihre persönliche Referenznummer anzeigt. Außerdem erhalten Sie zeitnah eine Bestätigungs- oder Absage-E-Mail an die von Ihnen angegebene E-Mail-Adresse.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Meine Teilnahme wurde bestätigt, aber der Rückerstattungsbetrag ist nicht korrekt. Was kann ich tun?

          </div>
          <div class="list-item-details">

<p>In Ihrer Bestätigungs-E-Mail finden Sie einen Button, mit dem Sie die Re-Evaluierung Ihres Falles beantragen können, der allerdings nach 72 Stunden seine Gültigkeit verliert. In diesem Fall wenden Sie sich bitte an unser Support-Team via <a href="mailto:kontakt@swiffer-gratis-testen.de">kontakt@swiffer-gratis-testen.de</a> und beschreiben Sie Ihren Fall in einer E-Mail. Dort wird man sich um Ihr Anliegen kümmern.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Wie lange habe ich die Möglichkeit, eine Re-Evaluierung meines Falles zu beantragen?

          </div>
          <div class="list-item-details">

<p>Eine automatische Re-Evaluierung mittels zugesandten Links kann innerhalb von 72 Stunden nach Zusendung der Bestätigungs- oder Absage-E-Mail angefordert werden. Danach können Sie nur noch über unser Support-Team (<a href="mailto:kontakt@swiffer-gratis-testen.de">kontakt@swiffer-gratis-testen.de</a>) eine Neubearbeitung Ihres Falles beantragen. Bitte geben Sie immer Ihre Referenznummer an!</p>
<p>Bitte beachten Sie, dass auch diese Möglichkeit nur begrenzt zur Verfügung steht. Ihre Daten werden nach Ablauf der Rückerstattungsaktion gelöscht, eine Bearbeitung ist deshalb nur bis zu 7 Wochen nach Einsendeschluss möglich – also bis 10.07.2023. Danach wird die Supportabteilung geschlossen, Ihre Anfragen können nicht mehr behandelt werden.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Mein Kaufbeleg wurde abgelehnt, weil nicht alle Daten erkennbar sind. Welche Daten sind gemeint?

          </div>
          <div class="list-item-details">

<p>Auf dem Kaufbeleg müssen der Händler (Filiale), , , der Gesamtbetrag des Kaufbelegs, die Kaufbelegsnummer (Bon-Nummer - NICHT BNummer oder Belegnummer - bzw. Rechnungsnummer), die ausgewiesene Mehrwertsteuer, eventuell anfallende Rabatte sowie Datum und Uhrzeit erkennbar sein.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Warum wurde meine Teilnahme abgelehnt, obwohl ich die korrekten Produkte gekauft habe?

          </div>
          <div class="list-item-details">

<p>Es könnte sich z.B. um einen technischen Fehler handeln, oder es kann sein, dass Ihr Kaufbeleg nicht scharf genug oder überbelichtet ist.</p>
<p>In Ihrer Ablehnungs-E-Mail finden Sie im unteren Drittel einen Button zum erneuten Hochladen Ihres Kaufbelegs. Klicken Sie auf diesen Button und Sie werden automatisch zum erneuten Kaufbeleg-Upload weitergeleitet. Machen Sie bitte ein neues Foto von Ihrem Kaufbeleg und laden Sie dieses innerhalb von 72 Std. erneut hoch. Bitte beachten Sie, dass der Button nach 72 Std. seine Funktionsfähigkeit verliert.</p>
<p>Sollte es dennoch zu einer erneuten Ablehnung Ihrer Teilnahme kommen, kontaktieren Sie uns bitte über die Support-E-Mail-Adresse, die ebenfalls in Ihrer Ablehnungs-E-Mail zu finden ist (<a href="mailto:kontakt@swiffer-gratis-testen.de">kontakt@swiffer-gratis-testen.de</a>).</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Mein Kaufbeleg wurde nicht akzeptiert oder sogar als Fälschung bezeichnet. Wie kann das sein?

          </div>
          <div class="list-item-details">

<p>Sollte Ihr Kaufbeleg zu unscharf oder zu dunkel oder auch überbelichtet sein, sodass die Daten nicht gut erkennbar sind, besteht die Möglichkeit, dass Sie eine Absage-E-Mail bekommen, in der der Kaufbeleg als Fälschung bezeichnet wird oder Sie aufgefordert werden, ein besseres Bild von Ihrem Kaufbeleg hochzuladen. Helfen Sie uns dabei, Ihre Teilnahme rasch und ohne Komplikationen zu akzeptieren, indem Sie ein qualitativ gutes Foto (keinen Scan!) Ihres Kaufbelegs hochladen, aus dem ersichtlich ist, dass es sich um einen Originalbeleg handelt und auf dem alle notwendigen Daten gut erkennbar sind. Sie sparen dabei sowohl sich selbst als auch uns eine Menge Zeit und Ärger.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Kann ich meinen Kaufbeleg auch per E-Mail nachreichen?

          </div>
          <div class="list-item-details">

<p>Das Nachreichen eines Kaufbelegs kann ausschließlich über einen Re-Upload-Button erfolgen, den Sie in Ihrer Ablehnungs-E-Mail finden können.</p>
<p>Sollten Sie keine Absage-E-Mail oder Bestätigungs-E-Mail erhalten haben, ist das ein Indiz dafür, dass Ihre Teilnahme nicht erfolgreich war und sie den Rückerstattungsprozess erneut durchführen müssen.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Ich habe bei der Anmeldung eine falsche IBAN angegeben. Was passiert in diesem Fall?

          </div>
          <div class="list-item-details">

<p>Sollten Sie eine falsche IBAN angegeben haben, sodass die Überweisung nicht durchgeführt werden kann, haben Sie die einmalige Gelegenheit diese zu ändern. Sie können diesbezüglich die entsprechende Abteilung bis 10.07.2023 per E-Mail kontaktieren: <a href="mailto:3-eur-cb@swiffer-promotions.de">3-eur-cb@swiffer-promotions.de</a>. Sollte auch mit dieser IBAN keine erfolgreiche Überweisung durchgeführt werden können, erlischt der Rückerstattungsanspruch. Bitte achten Sie darauf, dass diese IBAN noch nicht für eine andere Rückerstattung im Rahmen unserer Swiffer Gratis Testen Aktion verwendet wurde.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Ich wurde gebeten, meine Teilnahme per Post einzusenden. Was hat das zu bedeuten?

          </div>
          <div class="list-item-details">

<p>Bitte haben Sie Verständnis, dass wir verpflichtet sind alle Kaufbelege auf ihre Authentizität gründlich zu überprüfen. Selbstverständlich besteht die Möglichkeit, dass ein Kaufbeleg fälschlicherweise abgelehnt wurde.</p>
<p>In diesem Fall bitten wir Sie, Ihren originalen Kaufbeleg per Post an die zuständige Agentur zu senden. Die entsprechende Adresse wird Ihnen per E-Mail mitgeteilt. Sollte Ihre Teilnahme schlussendlich als gültig bewertet werden, überweisen wir Ihnen selbstverständlich sowohl den Rückerstattungsbetrag als auch die Portokosten. Aus o.g. und anderen Gründen ist es deshalb notwendig, dass Sie Ihren Kaufbeleg aufbewahren, bis Ihre Rückerstattung auf Ihrem Konto eingegangen ist.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Was passiert mit meinen Daten, die ich auf der Aktionswebsite eingegeben habe?

          </div>
          <div class="list-item-details">

<p>Die Verarbeitung Ihrer personenbezogenen Daten erfolgt durch P&amp;G und Justsnap als gemeinsam Verantwortliche im Sinne von Artikel 26 DSGVO. P&amp;G und Justsnap haben den in diesem Fall gesetzlich geforderten Vertrag abgeschlossen, der die datenschutzrechtlichen Verantwortlichkeiten und Aufgaben im Innenverhältnis regelt.</p>
<p>Die Aktionsdaten werden zur operativen Abwicklung der Aktion für einen Zeitraum von drei Monaten nach dem Einsendeschluss gespeichert. Nach diesem Zeitpunkt werden die Aktionsdaten zur Erfüllung rechtlicher Aufbewahrungspflichten (auch unter Berücksichtigung der Verjährungsfristen für Ansprüche im Zusammenhang mit der Aktion) gespeichert. Unabhängig davon werden Informationen (einschließlich personenbeziehbarer Daten) zu dokumentierten Missbrauchs-/Betrugsversuchen für einen Zeitraum von 3 Jahren nach dem letzten dokumentierten Missbrauchs-/Betrugsversuch gespeichert. Soweit Sie eine Einwilligung erteilt haben, werden die von der Einwilligung umfassten Daten in der Regel so lange gespeichert, bis Sie Ihre Einwilligung widerrufen. Im Übrigen entnehmen Sie die Speicherfristen bezüglich der Verarbeitung Ihrer personenbezogenen Daten zu Marketingzwecken durch P&amp;G bitte der P&amp;G Datenschutzerklärung unter <a href="https://www.pg.com/privacy/german/privacy_statement.shtml">https://www.pg.com/privacy/german/privacy_statement.shtml</a>.</p>


          </div>
        </li>
        <li class="list-item-wrapper">
          <div class="number">
Ich habe eine allgemeine Frage zu P&amp;G. An wen muss ich mich wenden?

          </div>
          <div class="list-item-details">

<p>Bitte kontaktieren Sie dazu den P&amp;G Kundenservice für Deutschland unter 0800 58 89 198 (Montag – Freitag, 9.00 - 17.00 Uhr)</p>
<p><a href="https://de.pg.com/kontaktieren-sie-uns/" target="_blank" rel="noopener noreferrer">https://de.pg.com/kontaktieren-sie-uns/</a></p>

          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "faq"
};
</script>

<style lang="scss" scoped>
.container {
  margin: 10px 5px;
}
.page-title {
  width: 100%;
  text-align: center;
  color: var(--brand-color-1);
  font-size: 36px;
  line-height: 40px;
  font-weight: bold;
  margin: 30px;
  @media (max-width: 575px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.box-notice {
  background-color: #DFDFDF;
  border: 1px solid black;
  padding: 15px;
  margin: 5px;
  color: #3D3D41;
}
.list {
  list-style-type: decimal;
  word-break: break-word;
  margin: 10px 4px;
  li::marker {
    font-size: 26px;
    font-weight: bold;
    color: var(--brand-color-1);
  }
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding-left: 20px;
  padding-right: 20px;
}
.number {
  color: var(--brand-color-1);
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  display: inline;
  margin-right: 5px;
  p {
      display: inline;
    }
}
p {
  margin-bottom: 5px;
}
.list-item-wrapper {
  margin-top: 24px;
  margin-right: 5px;
  white-space: initial;
  color: var(--brand-color-1);
  .list-item {
    font-weight: normal;
    white-space: initial;
    font-size: 16px;
    line-height: 24px;
  }
  .list-item-details {
    margin-top: 10px;
    text-justify: auto;
    a {
      text-decoration: underline;
    }
  }
}
</style>
