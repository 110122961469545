export default {
  isEditMode: state => state.isEditMode,
  getPageProperties: state => state.pageProperties,
  getPages: state => Object.keys(state.pageProperties),
  selectedPagebuilderItem: state => state.pageBuilder.selectedField,
  customerToken: state => state.customerToken,
  receiptToken: state => state.receiptToken,
  campaignId: state => state.campaignId,
  receiptImage: state => state.receiptImage,
  productImage: state => state.productImage,
  referenceNumber: state => state.referenceNumber,
  consumerId: state => state.consumerId,
  optIn: state => state.optIn,
  locale: state => state.locale,
  convertedImagePointsData: state => state.convertedImagePointsData,
  frontAndBack: state => state.frontAndBack,
  countryCode: state => state.countryCode,
  tel: state => state.tel,
  currentPage: state => {
    return () => {
      if (state.pageStatus) {
        return state.pageStatus.current.pageName
      }
      return undefined
    }
  },
  hasUnresolvedDependencies (state) {
    return fields => {
      if (!fields || !fields.length) {
        return false
      }
      const resolvedFields = fields
        .map(field => {
          return state.componentStatus[field]
        })
        .filter(field => {
          return field
        })

      return resolvedFields.length !== fields.length
    };
  },
  jwtToken: state => state.jwtToken,
  campaignKey: state => state.campaignKey,
  remainingCount: state => state.remainingCount
}
