
export default {
  locale: 'de',
  isEditMode: true,
  pageProperties: {
    testing: true,
    "pages": [
      { // Home
        "pageName": "homepage",
        "pageId": 1,
        "sections": [
          {
            "sectionId": "a0us1n",
            "grid": {
              "columns": 10,
              "alignItems": "center",
              "justifyContent": "center"
            },
            fields: [
              {
                "fieldType": "campaign-grid",
                "fieldId": "x1p9hx",
                "properties": {
                  "grid": {
                    "columns": "10",
                    "alignItems": "center",
                    "justifyContent": "center"
                  }
                }
              }
            ]
          }
        ]
      },
      { // 404
        "pageName": "error-page",
        locale: 'de',
        pageId: 114,
        isPublic: true,
        "sections": [
          { // Heading
            "grid": {
              "alignItems": "center",
              "justifyContent": "center",
              "columns": 12
            },
            "fields": [
              { // Container
                "fieldId": "secondaryErrorBucket",
                "fieldType": "empty-bucket",
                "properties": {
                  "errorImages": true,
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginTop": "25vh",
                    "marginBottom": "60px",
                    maxWidth: "75vw",
                    padding: "4vw 2vw"
                  },
                  classes: "form-top-wrapper maxWidthOverride transparentBg errorPageMarginTop",
                  fields: [
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "oopsErrorPage",
                      "properties": {
                        "text": `OOPS...`,
                        classes: "heading text-center paragraphBg overrideMarginBot ",
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "8",
                          "marginTop": "50px",
                          "marginBottom": "40px"
                        }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "error404text",
                      "properties": {
                        "text": `404`,
                        "classes": 'text-center paragraphBg',
                        styles: {
                          "font-size": "100px",
                          "line-height": "120px",
                          "color": "var(--color-1)",
                          "font-weight": "700"
                        },
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "8",
                          "marginTop": "0px",
                          "marginBottom": "10px"
                        }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "errorSubHeading",
                      "properties": {
                        "text": `Oops, something went wrong. Please try again.`,
                        "classes": 'text text-center',
                        "grid": {
                          "alignItems": "center",
                          "justifyContent": "center",
                          "columns": "8",
                          "marginTop": "10px",
                          "marginBottom": "50px"
                        }
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Back to swiffer website",
                        "grid": {
                          "columns": "6",
                          "alignItems": "center",
                          "justifyContent": "center"
                        },
                        classes: "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.for-me-online.de/marken/swiffer"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "grid": {
          "columns": 12
        },
        styles: {
          "box-shadow": "0px 0px 20px 0px #4E75934D",
          "background": "#FFFFFF",
          "border-radius": "10px",
          "color": "var(--color-3)",
          "max-width": "75vw",
          "margin": "38px auto 0px auto",
          "position": "absolute",
          "top": "0",
          "width": "100%",
          "left": "0",
          "right": "0",
          "height": "76px"
        },
        font: {
          name: 'Gotham Rounded',
          url: '/assets/fonts/stylesheet.css'
        },
        "logo": {
          "imageSource": "/assets/images/logo-new.png",
          "width": 120,
          "alt": "Swiffer",
          styles: {
            "position": "absolute"
          }
        },
        "favicon": {
          "imageSource": "/assets/images/logo-new.png"
        },
        "meta": [
          { "name": "title", "content": "Swiffer Promotions" },
          {
            "name": "description",
            "content": `Einkaufen gehen und bei einem Händler Swiffer erwerben, um Geld zurück zu bekommen. Das beste Gratis Testen. Wählen Sie Ihr Produkt aus, laden Sie Ihren Kaufbeleg hoch, füllen Sie das Teilnahmeformular aus und erhalten Sie Ihr Geld zurück.`
          },
          {
            "name": "keywords",
            "content": `Swiffer, Swiffer Gratis Testen, Gratis Testen, Swiffer Staubmagnet Starterset (Griff + 3 Tücher), Swiffer Staubmagnet Starterset (Griff + 7 Tücher)`
          }
        ]
      }
    },
    "footer": {
      "fieldType": "footer-component",
      "fieldId": "4tfbzz",
      "properties": {
        "grid": {
          "alignItems": "center",
          "justifyContent": "center",
          "columns": "12"
        },
        styles: {
          "background": "#348721",
          "color": "#FFF",
          "max-width": "75vw",
          "margin": "auto",
          "position": "relative"
        },
        logo: {
          src: "/assets/images/logo-new.png",
          url: "/",
          alt: "Swiffer",
          styles: {
            "width": "100%",
            "background-color": "#81E969",
            "min-height": "20px"
          }
        },
        fields: [
          {
            "fieldType": "empty-bucket",
            "fieldId": "foot100",
            properties: {
              grid: {
                columns: 12,
                justifyContent: "flex-end"
              },
              class: 'mx-0',
              fields: [
                {
                  "fieldType": "empty-bucket",
                  "fieldId": "foot100",
                  properties: {
                    grid: {
                      columns: 9
                    },
                    class: 'mx-auto',
                    fields: [
                      {
                        "fieldType": "unordered-list",
                        "fieldId": "foot100",
                        properties: {
                          grid: {
                            columns: 6
                          },
                          fields: [
                            {
                              "fieldId": "ikz8vc",
                              "fieldType": "anchor",
                              "properties": {
                                grid: {
                                  columns: 12
                                },
                                "text": `Impressum`,
                                "url": "https://de.pg.com/impressum/",
                                "target": "_blank",
                                "cssClass": "nav-link",
                                styles: {
                                  padding: "0",
                                  "min-height": "45px"
                                }
                              }
                            },
                            {
                              "fieldId": "ikz8vc",
                              "fieldType": "anchor",
                              "properties": {
                                grid: {
                                  columns: 12
                                },
                                "text": `Datenschutz`,
                                "url": "https://privacypolicy.pg.com/de-de/",
                                "target": "_blank",
                                "cssClass": "nav-link",
                                styles: {
                                  padding: "0",
                                  "min-height": "45px"
                                }
                              }
                            },
                            {
                              "fieldId": "ikz8vc",
                              "fieldType": "anchor",
                              "properties": {
                                grid: {
                                  columns: 12
                                },
                                "text": `for me`,
                                "url": "https://www.for-me-online.de/",
                                "target": "_blank",
                                "cssClass": "nav-link",
                                styles: {
                                  padding: "0",
                                  "min-height": "45px"
                                }
                              }
                            }
                          ]
                        }
                      },
                      {
                        "fieldType": "unordered-list",
                        "fieldId": "foot100",
                        properties: {
                          grid: {
                            columns: 6
                          },
                          fields: [
                            {
                              "fieldId": "ikz8vc",
                              "fieldType": "anchor",
                              "properties": {
                                grid: {
                                  columns: 12
                                },
                                "text": `Victoria`,
                                "url": "https://www.for-me-online.de/victoria",
                                "target": "_blank",
                                "cssClass": "nav-link",
                                styles: {
                                  "padding": "0",
                                  "min-height": "45px"
                                }
                              }
                            },
                            {
                              "fieldId": "ikz8vc",
                              "fieldType": "anchor",
                              "properties": {
                                grid: {
                                  columns: 12
                                },
                                "text": `P&G-Seite`,
                                "url": "https://de.pg.com/de-DE",
                                "target": "_blank",
                                "cssClass": "nav-link",
                                styles: {
                                  padding: "0",
                                  "min-height": "45px"
                                }
                              }
                            },
                            {
                              "fieldId": "ikz8vc",
                              "fieldType": "anchor",
                              "properties": {
                                grid: {
                                  columns: 12
                                },
                                "text": `Geschäftsbedingungen`,
                                "url": "https://termsandconditions.pg.com/de-de/",
                                "target": "_blank",
                                "cssClass": "nav-link",
                                styles: {
                                  padding: "0",
                                  "min-height": "45px"
                                }
                              }
                            },
                            {
                              "fieldId": "ikz8vc",
                              "fieldType": "anchor",
                              "properties": {
                                grid: {
                                  columns: 12
                                },
                                "text": `Meine Daten`,
                                "url": "https://preferencecenter.pg.com/de-de/",
                                "target": "_blank",
                                "cssClass": "nav-link",
                                styles: {
                                  padding: "0",
                                  "min-height": "45px"
                                }
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  "fieldId": "ikz8vc",
                  "fieldType": "paragraph",
                  "properties": {
                    grid: {
                      columns: 12
                    },
                    "text": `© 2024 Procter & Gamble`,
                    styles: {
                      padding: "0",
                      "min-height": "45px",
                      "color": "white !important",
                      "font-family": "Gotham Rounded",
                      textAlign: 'center'
                    }
                  }
                }
              ]
            }
          }
        ]
      }
    },
    "products": [
      {
        "id": 3,
        "ean": 8001841672335,
        "title": "Ariel All-in-1 PODS +Extra Faserpflege 14 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/april_14WL.jpeg",
        "selectable": true
      },
      {
        "id": 4,
        "ean": 8001841672571,
        "title": "Ariel All-in-1 PODS +Extra Faserpflege 20 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/april_20WL.jpeg",
        "selectable": true
      },
      {
        "id": 1,
        "ean": 8001841672243,
        "title": "Ariel All-in-1 PODS +Extra Geruchsabwehr 14 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/april_G_14WL.jpeg",
        "selectable": true
      },
      {
        "id": 2,
        "ean": 8001841672519,
        "title": "Ariel All-in-1 PODS +Extra Geruchsabwehr 20 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/april_G_20WL.jpeg",
        "selectable": true
      },
      {
        "id": 5,
        "ean": 8001841672090,
        "title": "Ariel All-in-1 PODS Universal 12 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/PODS_Universal_12.jpeg",
        "selectable": true
      },
      {
        "id": 6,
        "ean": 8001841672038,
        "title": "Ariel All-in-1 PODS Color 12 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/PODS_Color_12_WL.jpeg",
        "selectable": true
      },
      {
        "id": 7,
        "ean": 8001841700793,
        "title": "Lenor All-in-1 PODS Aprilfrisch 12 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/Aprilfrisch_12_WL.jpeg",
        "selectable": true
      },
      {
        "id": 8,
        "ean": 8001841700755,
        "title": "Lenor All-in-1 PODS Amethyst Blütentraum 12 WL",
        "backgroundImage": "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/Amethyst_Blutentraum_12_WL.jpeg",
        "selectable": true
      }
    ],
    dictionary: {
      de: {
        messages: {
          "required": (field) => `${field} ist ein Pflichtfeld`,
          "mandatory": "Das ist ein Pflichtfeld",
          "namesWithHyphensAndSpaces": (field) => `${field} darf nur alphabetische Zeichen enthalten`,
          "email": "Geben Sie bitte eine gültige E-Mail-Adresse an",
          "password": "Das Passwort muss mindestens 8 Zeichen lang sein, mindestens einen Großbuchstaben (A-Z), einen Kleinbuchstaben (a-z) und eine Zahl (0-9) enthalten.",
          "confirmPass": "Passwörter stimmen nicht überein",
          "postal": "PLZ ist ungültig",
          "iban": "Bitte geben Sie eine gültige IBAN an",
          "bic": "Bitte geben Sie einen gültigen BIC ein",
          "phoneNumber": "Das Handynummer muss eine ganze Zahl sein",
          "hausnr": "Hausnummer ist nicht gültig",
          "sepa": "Bitte verwenden Sie eine gültige IBAN der teilnehmenden Länder"
        },
        names: {
          iban: 'IBAN',
          gender: 'Geschlecht',
          bic: 'BIC',
          firstname: 'Vorname',
          firstName: 'Vorname',
          lastname: 'Nachname',
          lastName: 'Nachname',
          email: 'E-mail',
          birthdate: 'Geburtsdatum',
          password: 'Passwort',
          newPassword: 'Passwort',
          confirmPassword: 'Bestätigen des Passwort',
          newPasswordConfirm: 'Bestätigen des Passwort',
          strasse: 'Straße',
          hausnr: 'Nr',
          plz: 'PLZ',
          ort: 'Ort',
          land: 'Land',
          phoneNumber: 'Handynummer',
          code: 'Bestatigungscode'
        }
      }
    },
    "errors": {
      "de": {
        default: "Hoppla, da ist was schief gelaufen!",
        noEmail: "Es ist kein Konto mit dieser E-Mail-Adresse vorhanden.",
        emailUsed: "Diese E-Mail-Adresse wird bereits verwendet",
        incorrect: "Unzulässiger Benutzername oder falsches Passwort. Bitte versuchen Sie es erneut!",
        invalidCode: "Der Link, den Sie verwenden möchten, ist bereits abgelaufen. Bitte fordern Sie einen neuen Link zum Zurücksetzen des Passworts an.",
        manyTries: 'Ein Fehler ist aufgetreten, weil der Beleg zu oft hochgeladen wurde.',
        generateCode: 'Der Bestätigungscode konnte nicht gesendet wenden, weil es sich um eine nicht zugelassene Telefonnummer handelt. Bitte überprüfen Sie die eingegebene Nummer und versuchen Sie es erneut.',
        wrongCode: 'Der SMS-Code ist falsch',
        network: 'Hoppla. Auf dem Server ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
      }
    },
    "campaignId": "swiffer-main",
    // TODO: this need to be changed
    "url": "swiffer.com",
    "name": "swiffer-main"
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'Oops. Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.',
    isActive: false
  },
  customerToken: '',
  receiptToken: '',
  receiptImage: '',
  campaignId: 'swiffer-main',
  formData: new FormData(),
  campaignEndDate: '2023-09-15',
  scannerActive: false,
  file: {},
  barcode: {
    code: '',
    format: ''
  },
  productCode: '',
  searchStatus: null,
  imgList: [],
  errorMessage: "Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?",
  errorImageList: ["", ""],
  errorActivate: false,
  scannedProducts: [],
  types: ['ean_reader', 'code_128_reader', 'code_39_reader', 'ean_8_reader', 'upc_reader', 'upc_e_reader', 'i2of5_reader', '2of5_reader', 'code_93_reader', 'codabar_reader'],
  pageToBack: '',
  receiptBarcode: ''
}
