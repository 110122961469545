<template>
  <header :style="cssVars" :class="{ 'scrolled-nav': scrolledNav }">
    <nav
      class="navbar navbar-expand-lg"
    >
      <a
        class="navbar-brand logo"
        href="/"
        target="_blank"
      >
        <img
          :src="logo.imageSource"
          :alt="logo.alternateText"
          :width="logo.width"
        />
      </a>
      <div v-if="logo.title" class="justify-content-center">
        <h1
          class="m-0 p-0"
        >
          {{ logo.title }}
        </h1>
      </div>
      <ul v-show="!mobile" class="navigation">
        <li
          class="link"
          v-for="field in fields"
          :key="field.fieldId"
        >
          <componentRenderer v-bind:key="field.id" :field="field" />
        </li>
      </ul>
      <button
        v-show="mobile"
        @click="toggleMobileNav"
        :class="{ 'icon-active': mobileNav }"
        class="navbar-toggler"
        type="button"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <transition name="mobile-nav">
        <ul v-show="mobileNav" class="dropdown-nav">
          <li class="w-100 d-flex flex-row justify-content-between">
            <a
              class="navbar-brand logo"
              href="/"
              target="_blank"
            >
              <img
                :src="logo.imageSource"
                :alt="logo.alternateText"
                width="150px"
              />
            </a>
            <button
              @click="toggleMobileNav"
              :class="{ 'icon-active': mobileNav }"
              class="navbar-toggler"
              type="button"
            >
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 6L1 11M6 6L1 1L6 6ZM6 6L11 11L6 6ZM6 6L11 1L6 6Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </li>
          <li
            class="link"
            v-for="field in fields"
            :key="field.fieldId"
          >
            <componentRenderer v-bind:key="field.id" :field="field" />
          </li>
        </ul>
      </transition>
    </nav>
  </header>
</template>

<script>
import componentRenderer from "@/components/component-renderer";

export default {
  name: "side-navbar",
  components: {
    componentRenderer
  },
  data () {
    return {
      scrolledNav: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null
    }
  },
  props: {
    backgroundColor: {
      required: false,
      type: String,
      default: "#fff"
    },
    title: {
      required: false,
      type: String,
      default: "Hello world"
    },
    logo: {
      required: true,
      type: Object
    },
    fields: {
      required: false,
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: "#000"
    },
    favicon: {
      type: Object,
      default: () => {
        return {
          imageSource: "/favico.ico"
        }
      }
    }
  },
  created () {
    window.addEventListener('resize', this.checkScreen)
    this.checkScreen()
  },
  mounted () {
    window.addEventListener('scroll', this.updateScroll)
  },
  computed: {
    cssVars () {
      return {
        '--color': this.color,
        '--bg-color': this.backgroundColor
      }
    }
  },
  methods: {
    checkScreen () {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 767) {
        this.mobile = true;
        return
      }
      this.mobile = false;
      this.mobileNav = false
      return
    },
    updateScroll () {
      const scrollPosition = window.scrollY
      if (scrollPosition > 50) {
        this.scrolledNav = true;
        return
      }
      this.scrolledNav = false
      return
    },
    toggleMobileNav () {
      this.mobileNav = !this.mobileNav
    }
  }
};
</script>

<style lang="scss" scoped>
header {
  background: var(--bg-color);
  z-index: 99;
  width: 100%;
  height: 100%;
  max-height: 80px;
  transition: .5s ease all;
  padding: 0 !important;
  color: #fff;

  nav {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0;
    transition: .5s ease all;
    width: 90%;
    margin: 0 auto;
    @media (min-width: 1140px) {
      max-width: 1140px;
    }

    ul,
    .link {
      font-weight: 500;
      color: var(--color);
      list-style: none;
      text-decoration: none;
    }

    li {
      text-transform: uppercase;
      padding: 16p;
      margin-left: 16px;
    }

    .link {
      font-size: 14px;
      transition: .5s ease all;
      padding-bottom: 4px;
      border-bottom: 1px solid transparent;
      a {
        color: var(--color);
      }
    }


    .branding {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        transition: .5s ease all;
      }
    }

    .navigation {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
      margin-bottom: 0;
    }

    .icon {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 24px;
      height: 100%;

      i {
        cursor: pointer;
        font-size: 24px;
        transition: .8s ease all;
      }
    }

    .icon-active {
      transform: rotate(180deg);
    }

    .dropdown-nav {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: fixed;
      z-index: 99;
      padding: 20px;
      width: 100%;
      max-width: 60vw;
      height: 100%;
      background: var(--bg-color);
      top: 0;
      right: 0;

      @media (max-width: 350px) {
        max-width: 250px;
      }

      li {
        // margin-left: 0;
        margin: 20px auto 20px 0;
        .link {
          a {
            color: var(--color);
          }
        }
      }
    }

    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
      transition: 1s ease all;
    }

    .mobile-nav-enter,
    .mobile-nav-leave-to {
      transform: translateX(100vw);
    }
  }
}

.scrolled-nav {
  background-color: #000;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);

  nav {
    padding: 8px 0;

    .branding {
      img {
        width: 40px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
      }
    }
  }
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='var(--color);' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

</style>
