<template>
  <ul
    :class="[classes, `row col-lg-${grid.columns ? grid.columns : ''}`]"
    :style="{ ...styles }"
  >
    <li
      v-for="field in fields"
      :key="field.id"
    >
      <componentRenderer
        :class="`col-lg-${field.properties.grid.columns} col-sm-${field.properties.grid.columns}`"
        :field="field"
        @component-updated="updated"
      />
    </li>
  </ul>
</template>
<script>
import componentRenderer from "@/components/component-renderer";

export default {
  name: "unordered-list",
  components: { componentRenderer },
  props: {
    fieldId: String,
    fields: {
      type: Array,
      required: true
    },
    grid: {
      type: Object
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    classes: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
