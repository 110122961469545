<template>
  <div :style="{ '--color': color, '--borderColor': borderColor }" class="textInputContainer">
    <ValidationProvider
      v-if="validation"
      :rules="validation"
      v-slot="{ errors }"
      :name="name || label"
    >
      <div
        class="textInputWrapper form-group"
        :style="`${customWrapperStyle};`"
      >
        <label :for="name" class="sr-only visually-hidden">{{ label }}</label>
        <input
          :autocomplete="autocomplete"
          :style="styles"
          :type="inputType"
          class="form-control textInput"
          :class="{ 'is-invalid': errors.length }"
          :value="text == '' ? value : text"
          @input="updateValue($event.target.value)"
          ref="input"
          :placeholder="placeholder"
          :name="name"
          :id="name"
          :maxlength="maxlength"
          @paste="pasteHandler"
        />
        <!-- <img
          v-if="errors.length"
          class="error_min inputError"
          src="../../assets/icons/risk-icon.svg"
          height="18px"
          width="18px"
          alt
        /> -->
        <span class="floating-label" :class="{ slideUp: slideUp }">{{
          label
        }}</span>
      </div>
      <div class="validation-error" v-if="errors.length" :class="errors[0].length > 68 ? 'text-to-long': ''">{{ errors[0] }}</div>
    </ValidationProvider>
    <div class="m-0 p-0" v-else>
      <div class="textInputWrapper form-group">
        <label :for="name" class="sr-only visually-hidden">{{ label }}</label>
        <input
          :autocomplete="autocomplete"
          type="text"
          :style="styles"
          class="form-control textInput"
          :value="value"
          @input="updateValue($event.target.value)"
          ref="input"
          :name="name"
          :id="name"
        />
        <span class="floating-label" :class="{ slideUp: slideUp }">{{
          label
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "text-input",
  props: {
    name: {
      type: String,
      required: true
    },
    inputType: {
      type: String,
      default: "text"
    },
    autocomplete: {
      type: String
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    text: {
      type: String,
      default: ""
    },
    validation: {
      type: String,
      required: false
    },
    value: {
      required: false
    },
    maxlength: {
      type: Number,
      required: false
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    },
    customWrapperStyle: {
      type: Object,
      required: false,
      default: () => {}
    },
    slideUp: Boolean,
    disablePaste: {
      type: Boolean,
      default: () => false
    },
    color: {
      type: String,
      default: "#0F2967"
    },
    borderColor: {
      type: String,
      default: "#a3b8ea"
    }
  },
  methods: {
    updateValue (val) {
      this.$emit("component-updated", {
        text: val,
        slideUp: !!val
      });
      this.$emit("input", val);
      this.$store.dispatch("setComponentStatus", {
        component: this.name,
        status: !!val
      });
    },
    pasteHandler (event) {
      if (this.disablePaste) {
        event.preventDefault();
      }
    }
  }
};
</script>

<style scoped lang="scss">
$medium: 800px;
#iban{
  text-transform: uppercase !important;
}
.validation-error {
  position: relative;
  color: #dc3545;
  font-size: 13px;
  margin-left: 0px;
  margin-top: 0px;
  top: 0px;
}

.textInputWrapper {
  position: relative;
  margin: 0px;

  .error_min {
    width: 16px;
  }
  .inputError {
    position: absolute;
    top: 11px;
    right: 10px;
  }
}
.textInputWrapper .textInput {
  outline: 0;
  box-shadow: none;
  transition: border-color 0.15s;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box !important;
  padding-left: 15px !important;
  height: 44px !important;
  width: 100%;
  font-size: 16px;
  color: var(--color);
  font-weight: 500;
  &.is-invalid {
    border-color: #dc3545;
    background-color: #fff0f1;
  }
  @media (max-width:575px) {
    font-size: 14px;
  }
}
.textInputWrapper .floating-label {
  position: absolute;
  top: -17px;
  left: 0;
  padding-right: 7px;
  transition: transform 0.1s;
  pointer-events: none;
  transform-origin: 0 100%;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 20px;
  color: #1D222A;
  font-weight: 700;
  @media (max-width:  575px){
    font-size: 14px;
  }
}
.text-to-long {
  bottom: -35%;
}
.textInputWrapper input:focus ~ .floating-label,
.textInputWrapper input:not(:focus):valid ~ .floating-label {
  // transform: translateY(-135%) scale(0.8);
}
.textInputWrapper input:not(:valid) ~ .floating-label {
  color: #808080;
}
// .slideUp {
//   visibility: hidden;
// }

.textInputWrapper input:disabled {
  background-color: #c1c1c1;
}
</style>
