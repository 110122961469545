<template>
  <div class="wrapper_file_upload" :style="[styles, cssVars]"
        :class="{ highlight: highlight }"
        @dragover.prevent
        @drop.stop.prevent="handleDrop"
        @dragenter.stop.prevent="onDragEnter"
        @dragleave.stop.prevent="onDragLeave"
  >
    <label v-show="!selectedFile" class="file_upload" tabindex="0" for="file">
      <input
        @change="onFilePicked"
        accept="image/*"
        class="inputfile"
        type="file"
        name="file"
        ref="file"
        id="file"
      />
      <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M64.725 37.9667C63.2319 26.2065 53.163 17.084 40.9997 17.084C31.5833 17.084 23.4038 22.5848 19.6694 31.2597C12.3304 33.4567 6.83301 40.3857 6.83301 47.834C6.83301 56.9121 13.9704 64.2852 22.9187 64.8182V64.9173H61.4655V64.9071L61.4997 64.9173C69.0368 64.9173 75.1663 58.7878 75.1663 51.2506C75.1623 48.1877 74.1315 45.2145 72.2387 42.8063C70.3458 40.3982 67.7003 38.6942 64.725 37.9667ZM27.333 41.0007H37.583V30.7507H44.4163V41.0007H54.6663L40.9997 58.084L27.333 41.0007Z" fill="#828282"/>
      </svg>
      <div class="text1" v-html="$t(this.texts.text1)"></div>
      <div class="text2" v-html="$t(this.texts.text2)"></div>
    </label>
    <div v-show="selectedFile" class="row row-dop justify-content-center mt-4">
      <label class="wrapper_square_img">
        <img
          class="square-img img-fluid"
          ref="preview"
          src="../../assets/icons/placeholder.svg"
          alt
        />
        <div @click="removeImage" class="delete_block">
          <img class="delete_img" src="../../assets/icons/delete.svg" alt="delete" />
        </div>
      </label>
    </div>
    <button @click.prevent="selectAnother" @keypress.enter="selectAnother" tabindex="0" class="btn btn_abs btn-light">{{ selectedFile ? $t(this.texts.choose) : $t(this.texts.upload) }}</button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "product-upload",
  props: {
    fieldId: String,
    endpoint: String,
    successAction: {
      type: Object,
      required: true
    },
    value: {
      required: false
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B"
    },
    tokenName: {
      required: false,
      type: String,
      default: "token"
    },
    reUpload: {
      required: false,
      type: Boolean
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    styles: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      selectedFile: null,
      uploadSectionHover: false,
      highlight: false
    };
  },
  methods: {
    clear () {
      this.selectedFile = null;
      this.setValue(null);
    },
    removeImage () {
      this.selectedFile = null;
      this.setValue(null);
      this.$refs.file.value = null;
    },
    onFilePicked (event) {
      const files = event.target.files || event.dataTransfer.files;
      this.handleFiles(files)
    },
    onDragEnter () {
      this.highlight = true
    },
    onDragLeave () {
      this.highlight = false
    },
    handleDrop (e) {
      let dt = e.dataTransfer
      let files = dt.files
      this.handleFiles(files)
    },
    handleFiles (files) {
      const maxfilesize = 1024 * 1024 * 16; // 1 Mb

      if (files && files[0]) {
        let filename = files[0].name;

        if (filename && filename.lastIndexOf(".") <= 0) {
          return;
        }
        if (files[0].size > maxfilesize) {
          this.showModal(this.$t(this.texts.fileSizeLimit));
          this.$refs.image.type = '';
          this.$refs.image.type = 'file';
          return;
        }
        const extension = files[0].name.split(".").pop();
        const found = ["png", "jpg", "jpe", "jpeg"].filter(
          ext => ext === extension.toLowerCase()
        );
        if (!found.length) {
          this.showModal(this.$t(this.texts.wrongFormat));
          this.$refs.image.type = '';
          this.$refs.image.type = 'file';
          return;
        }
        this.selectedFile = files[0];
        this.setPreview(this.selectedFile);
        this.setValue(this.selectedFile);
      }
    },
    setValue (value) {
      this.$emit("component-updated", {
        value
      });
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: !!value
      });
    },
    setPreview (file) {
      const reader = new FileReader();

      this.selectedFile = file;
      reader.onload = e => {
        this.$refs.preview.src = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    sendProductPhoto (formId, triggers) {
      const formData = {
        image: this.selectedFile,
        endpoint: this.endpoint,
        receiptToken: this.receiptToken
      };

      if (this.reUpload) {
        formData["receiptToken"] = this.receiptToken;
        formData["customerToken"] = 1;
      }

      this.$store.dispatch("uploadProductPhotos", formData).then(r => {
        // console.log(this.successAction, this.response);
        if (r.response && this.successAction) {
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: true
          });
        }
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      }).catch(err => {
        console.log("Error", err)
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      });
    },
    selectAnother () {
      this.$refs.file.click();
    }
  },
  mounted () {
    if (this.value) {
      this.setPreview(this.value);
    }
    this.$eventHub.$on("sendProductPhotos-event", this.sendProductPhoto);
  },
  computed: {
    ...mapGetters(['receiptToken']),
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  }
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.wrapper_square_img {
  position: relative;
  display: inline-block;
  width: 89%;
}
.delete_block {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition: transform .5s;
  }
}
.delete_block .delete_img {
  width: 16px;
}
.inputfile {
  display: none;
}
.wrapper_file_upload {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media(max-width: 450px){
    max-width: 95vw !important;
  }
  .file_upload {
    width: 100%;
    text-align: center;
    cursor: pointer;
    padding-top: 40px;
    padding-bottom: 20px;
    .text {
      &1 {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0px;
        color: var(--color-1);
        opacity: 1;
        padding-bottom: 15px;
      }

      &2 {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0px;
        color: var(--color-4);
        opacity: 1;
      }
    }
  }
}
.file_upload input[type="file"] {
  display: none;
}
.btn {
  position: absolute;
  margin: 0 auto;
  bottom: 25px;
  padding: 10px 30px;
  z-index: 1000;
  background-color: #000;
  color: #FFF;
  border-radius: 0;
  border: none;
}
</style>
