var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"page-title"},[_vm._v(" FAQ ")]),_c('div',{staticClass:"page-title"},[_vm._v(" Swiffer ")]),_c('ul',{ref:"list",staticClass:"list"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Welche Bedingungen muss ich erfüllen, um an der Swiffer Gratis Testen Aktion teilnehmen zu können? ")]),_c('div',{staticClass:"list-item-details"},[_c('ul',[_c('li',[_vm._v("Mindestalter: Sie müssen mindestens 18 Jahre alt sein.")]),_c('ul'),_c('li',[_vm._v("Wohnort: Ihr Wohnort muss sich in Deutschland befinden.")]),_c('ul'),_c('li',[_vm._v("Kontoverbindung: Sie müssen eine der Euro-Zone angehörige IBAN-Nummer besitzen.")]),_c('ul'),_c('li',[_vm._v("E-Mail-Adresse: Die Teilnahme kann nur mit Ihrer eigenen E-Mail-Adresse erfolgen.")]),_c('li',[_vm._v("Sie müssen im Besitz einer Mobilfunknummer mit der Landesvorwahl +49, +43 oder +41 und eines SMS-fähigen Endgerätes sein.")])]),_c('p',{staticStyle:{"margin-top":"5px"}},[_vm._v("Anmerkung: IBAN, Handynummer und E-Mail-Adresse können für den gesamten Aktionszeitraum nur einmalig kombiniert werden.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Wie kann ich an der Swiffer Gratis Testen Aktion teilnehmen? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Sie können ausschließlich online teilnehmen - und zwar über die Aktionswebsite "),_c('a',{attrs:{"href":"https://www.swiffer-gratis-testen.de","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("www.swiffer-gratis-testen.de")]),_vm._v(". Eine Teilnahme über den Postweg ist leider nicht möglich. Der Vorgang kann folgendermaßen beschrieben werden:")]),_c('ul',[_c('li',[_vm._v(" einen Fragebogen ausfüllen, ")]),_c('li',[_vm._v(" die Aktionsprodukte (mind. 1 Produkt) angeben, ")]),_c('li',[_vm._v(" Kaufbeleg fotografieren und hochladen, ")]),_c('li',[_vm._v(" Foto der Rückseite Ihrer Swiffer-Aktionsprodukte hochladen (Sie haben darauf zu achten, dass der Barcode gut sichtbar ist), ")]),_c('li',[_vm._v(" Bankverbindung (IBAN), E-Mail-Adresse, Handy-Nummer, weitere persönliche Daten angeben, ")]),_c('li',[_vm._v(" Teilnahmebedingungen + Datenschutzrichtlinie akzeptieren, ")]),_c('li',[_vm._v(" Identität per SMS-Code bestätigen. ")])]),_c('p',[_vm._v("Anmerkung: Die Verwendung verschiedener E-Mail-Adressen (Nutzerkonten) pro Person ist nicht möglich.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" An wen muss ich mich wenden, wenn ich eine Frage zur Swiffer Gratis Testen Aktion habe? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Bitte kontaktieren Sie dazu den Swiffer Aktions-Kundenservice via "),_c('a',{attrs:{"href":"mailto:kontakt@swiffer-gratis-testen.de"}},[_vm._v("kontakt@swiffer-gratis-testen.de")]),_vm._v(".")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v("Welche Produkte muss ich kaufen, um an der Swiffer Gratis Testen Aktion teilnehmen zu können? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("An der Swiffer Gratis Testen Aktion nehmen folgende Produkte teil:")]),_c('br'),_c('table',{staticClass:"dcf-table dcf-table-responsive dcf-table-bordered dcf-table-striped dcf-w-100%"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Swiffer Staubmagnet Starterset (Griff + 3 Tücher)")]),_c('td',[_vm._v("5410076702923")])]),_c('tr',[_c('td',[_vm._v("Swiffer Staubmagnet Starterset (Griff + 7 Tücher)")]),_c('td',[_vm._v("8001090591661")])]),_c('tr',[_c('td',[_vm._v("Swiffer Staubmagnet Starterset (Griff + 3 Tücher) (Hängevorrichtung)")]),_c('td',[_vm._v("4084500980037")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Wo kann ich an der Swiffer Gratis Testen Aktion kaufen? ")]),_c('div',{staticClass:"list-item-details"},[_vm._v(" Sie können die Aktionsprodukte bei allen eingetragenen Handelsunternehmen (online und offline) innerhalb Deutschlands kaufen. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Welcher Betrag wird mir rückerstattet? ")]),_c('div',{staticClass:"list-item-details"},[_vm._v(" Sie bekommen 100% des TATSÄCHLICH bezahlten Kaufpreises (abzüglich eventueller Rabatte) des eingereichten Aktionsartikels zurückerstattet. Die maximale Rückerstattung beträgt 5,99 € pro Aktionsprodukt. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Wann kann ich an der Swiffer Gratis Testen Aktion teilnehmen? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Der Zeitraum für den Erwerb von Aktionsprodukten ist vom "),_c('strong',[_vm._v("01.04.2022")]),_vm._v(" bis inklusive "),_c('strong',[_vm._v("31.07.2022")]),_vm._v(" (relevant ist hierbei das Datum des Kaufbelegs). Einsendeschluss für das Online-Anmeldeformular und den Kaufbeleg-Upload ist der "),_c('strong',[_vm._v("15.08.2022")]),_vm._v(".")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Wie oft kann ich an der Swiffer Gratis Testen Aktion teilnehmen? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Jede Person (Nutzerkonto: Kombination aus IBAN) kann mit einem Kaufbeleg und maximal zwei Produkten an der Swiffer Gratis Testen Aktion teilnehmen.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Mit wie vielen Produkten kann ich an der Swiffer Gratis Testen Aktion teilnehmen? ")]),_c('div',{staticClass:"list-item-details"},[_vm._v(" Pro Person kann für zwei Aktionsprodukte eine Rückerstattung veranlasst werden. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Mein Partner bzw. meine Partnerin und ich haben ein gemeinsames IBAN Konto. Können wir beide getrennt mit diesem Konto an der Swiffer Gratis Testen Aktion teilnehmen? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Jede IBAN kann für den gesamten Aktionszeitraum nur von einem Nutzer verwendet werden (in fixer Kombination mit E-Mail-Adresse ). Eine gemeinsame Nutzung mit einem weiteren Teilnehmer ist ungeachtet jedmöglicher persönlicher Verbindung nicht möglich.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Wann kann ich mit meiner Rückerstattung rechnen? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Nach positiver Prüfung Ihrer Teilnahme wird der Rückerstattungsbetrag auf das von Ihnen angegebene Bankkonto (IBAN) innerhalb von maximal 6 Wochen überwiesen.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Kann ich mit dem gleichen Kaufbeleg auch an anderen Rückerstattungs-Aktionen teilnehmen? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Sollten Sie mit Ihrem Kaufbeleg bereits an einer parallellaufenden Aktion teilgenommen haben, und für Ihre Swiffer bereits eine Rückerstattung bestätigt bekommen haben, ist es nicht möglich mit diesem Produkt und diesem Kaufbeleg auch bei der Swiffer Gratis Testen Aktion teilzunehmen.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Mein Kaufbeleg enthält mehr als zwei . Können diesen Kaufbeleg auch zwei oder mehrere Personen nutzen? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Ein Kaufbeleg kann prinzipiell nur einmalig an unserer Swiffer Gratis Testen Aktion teilnehmen. Weder kann der Umfang der auf dem Kaufbeleg angeführten Aktionsprodukte berücksichtigt werden, noch kann ein Aktionsprodukt auf eine andere Person übertragen werden.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Ich habe nach Abschluss des Teilnahmevorgangs keine Bestätigungs-E-Mail erhalten, was nun? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Wenn Sie registriert sind, haben Sie per E-Mail eine Bestätigung mit einer 10-stelligen Referenznummer erhalten. Prüfen Sie bitte auch Ihren SPAM- oder JUNK-Ordner. Wenn Sie keine 10-stellige Referenznummer erhalten haben, nehmen Sie bitte über die Aktionswebsite "),_c('a',{attrs:{"href":"https://www.swiffer-gratis-testen.de","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("www.swiffer-gratis-testen.de")]),_vm._v(" noch einmal teil.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Beim Durchlaufen des Rückerstattungsvorgangs geht es ab einem bestimmten Punkt nicht mehr weiter. Was soll ich tun? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Sollten Sie während des Teilnahmeprozesses aus technischen Gründen nicht weiterkommen, empfiehlt es sich - nach Möglichkeit - ein anderes Gerät zu benutzen. Wechseln Sie z.B. vom Handy auf den PC. Sollte dies nichts nützen, könnten Sie probieren, einen anderen Browser zu benutzen. Sollten Ihnen diese kleinen Tipps nicht weiterhelfen, schicken Sie bitte eine E-Mail an unsere Support-Abteilung: "),_c('a',{attrs:{"href":"mailto:kontakt@swiffer-gratis-testen.de"}},[_vm._v("kontakt@swiffer-gratis-testen.de")]),_vm._v(". Bitte geben Sie in Ihrer E-Mail eine genaue Beschreibung des Problems an - perfekt wäre ein Screenshot von dem Punkt, an dem es nicht mehr weiter geht. Weitere hilfreiche Daten sind, welches Gerät, welches Betriebssystem und welchen Browser Sie benutzen. Unser Support-Team wird Ihnen dann zur Seite stehen.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Wie kann ich erkennen, ob ich den Upload-Prozess des Kaufbelegs vollständig und erfolgreich durchlaufen habe? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Sobald Sie Ihren Kaufbeleg hochgeladen, Ihre persönlichen Daten und Bankdaten eingegeben und Ihre Daten per SMS-Code bestätigt haben, erscheint am Bildschirm eine Nachricht, die den Erfolg Ihres Teilnahmeversuchs bestätigt und Ihre persönliche Referenznummer anzeigt. Außerdem erhalten Sie zeitnah eine Bestätigungs- oder Absage-E-Mail an die von Ihnen angegebene E-Mail-Adresse.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Meine Teilnahme wurde bestätigt, aber der Rückerstattungsbetrag ist nicht korrekt. Was kann ich tun? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("In Ihrer Bestätigungs-E-Mail finden Sie einen Button, mit dem Sie die Re-Evaluierung Ihres Falles beantragen können, der allerdings nach 72 Stunden seine Gültigkeit verliert. In diesem Fall wenden Sie sich bitte an unser Support-Team via "),_c('a',{attrs:{"href":"mailto:kontakt@swiffer-gratis-testen.de"}},[_vm._v("kontakt@swiffer-gratis-testen.de")]),_vm._v(" und beschreiben Sie Ihren Fall in einer E-Mail. Dort wird man sich um Ihr Anliegen kümmern.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Wie lange habe ich die Möglichkeit, eine Re-Evaluierung meines Falles zu beantragen? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Eine automatische Re-Evaluierung mittels zugesandten Links kann innerhalb von 72 Stunden nach Zusendung der Bestätigungs- oder Absage-E-Mail angefordert werden. Danach können Sie nur noch über unser Support-Team ("),_c('a',{attrs:{"href":"mailto:kontakt@swiffer-gratis-testen.de"}},[_vm._v("kontakt@swiffer-gratis-testen.de")]),_vm._v(") eine Neubearbeitung Ihres Falles beantragen. Bitte geben Sie immer Ihre Referenznummer an!")]),_c('p',[_vm._v("Bitte beachten Sie, dass auch diese Möglichkeit nur begrenzt zur Verfügung steht. Ihre Daten werden nach Ablauf der Rückerstattungsaktion gelöscht, eine Bearbeitung ist deshalb nur bis zu 7 Wochen nach Einsendeschluss möglich – also bis 10.07.2023. Danach wird die Supportabteilung geschlossen, Ihre Anfragen können nicht mehr behandelt werden.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Mein Kaufbeleg wurde abgelehnt, weil nicht alle Daten erkennbar sind. Welche Daten sind gemeint? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Auf dem Kaufbeleg müssen der Händler (Filiale), , , der Gesamtbetrag des Kaufbelegs, die Kaufbelegsnummer (Bon-Nummer - NICHT BNummer oder Belegnummer - bzw. Rechnungsnummer), die ausgewiesene Mehrwertsteuer, eventuell anfallende Rabatte sowie Datum und Uhrzeit erkennbar sein.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Warum wurde meine Teilnahme abgelehnt, obwohl ich die korrekten Produkte gekauft habe? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Es könnte sich z.B. um einen technischen Fehler handeln, oder es kann sein, dass Ihr Kaufbeleg nicht scharf genug oder überbelichtet ist.")]),_c('p',[_vm._v("In Ihrer Ablehnungs-E-Mail finden Sie im unteren Drittel einen Button zum erneuten Hochladen Ihres Kaufbelegs. Klicken Sie auf diesen Button und Sie werden automatisch zum erneuten Kaufbeleg-Upload weitergeleitet. Machen Sie bitte ein neues Foto von Ihrem Kaufbeleg und laden Sie dieses innerhalb von 72 Std. erneut hoch. Bitte beachten Sie, dass der Button nach 72 Std. seine Funktionsfähigkeit verliert.")]),_c('p',[_vm._v("Sollte es dennoch zu einer erneuten Ablehnung Ihrer Teilnahme kommen, kontaktieren Sie uns bitte über die Support-E-Mail-Adresse, die ebenfalls in Ihrer Ablehnungs-E-Mail zu finden ist ("),_c('a',{attrs:{"href":"mailto:kontakt@swiffer-gratis-testen.de"}},[_vm._v("kontakt@swiffer-gratis-testen.de")]),_vm._v(").")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Mein Kaufbeleg wurde nicht akzeptiert oder sogar als Fälschung bezeichnet. Wie kann das sein? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Sollte Ihr Kaufbeleg zu unscharf oder zu dunkel oder auch überbelichtet sein, sodass die Daten nicht gut erkennbar sind, besteht die Möglichkeit, dass Sie eine Absage-E-Mail bekommen, in der der Kaufbeleg als Fälschung bezeichnet wird oder Sie aufgefordert werden, ein besseres Bild von Ihrem Kaufbeleg hochzuladen. Helfen Sie uns dabei, Ihre Teilnahme rasch und ohne Komplikationen zu akzeptieren, indem Sie ein qualitativ gutes Foto (keinen Scan!) Ihres Kaufbelegs hochladen, aus dem ersichtlich ist, dass es sich um einen Originalbeleg handelt und auf dem alle notwendigen Daten gut erkennbar sind. Sie sparen dabei sowohl sich selbst als auch uns eine Menge Zeit und Ärger.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Kann ich meinen Kaufbeleg auch per E-Mail nachreichen? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Das Nachreichen eines Kaufbelegs kann ausschließlich über einen Re-Upload-Button erfolgen, den Sie in Ihrer Ablehnungs-E-Mail finden können.")]),_c('p',[_vm._v("Sollten Sie keine Absage-E-Mail oder Bestätigungs-E-Mail erhalten haben, ist das ein Indiz dafür, dass Ihre Teilnahme nicht erfolgreich war und sie den Rückerstattungsprozess erneut durchführen müssen.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Ich habe bei der Anmeldung eine falsche IBAN angegeben. Was passiert in diesem Fall? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Sollten Sie eine falsche IBAN angegeben haben, sodass die Überweisung nicht durchgeführt werden kann, haben Sie die einmalige Gelegenheit diese zu ändern. Sie können diesbezüglich die entsprechende Abteilung bis 10.07.2023 per E-Mail kontaktieren: "),_c('a',{attrs:{"href":"mailto:3-eur-cb@swiffer-promotions.de"}},[_vm._v("3-eur-cb@swiffer-promotions.de")]),_vm._v(". Sollte auch mit dieser IBAN keine erfolgreiche Überweisung durchgeführt werden können, erlischt der Rückerstattungsanspruch. Bitte achten Sie darauf, dass diese IBAN noch nicht für eine andere Rückerstattung im Rahmen unserer Swiffer Gratis Testen Aktion verwendet wurde.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Ich wurde gebeten, meine Teilnahme per Post einzusenden. Was hat das zu bedeuten? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Bitte haben Sie Verständnis, dass wir verpflichtet sind alle Kaufbelege auf ihre Authentizität gründlich zu überprüfen. Selbstverständlich besteht die Möglichkeit, dass ein Kaufbeleg fälschlicherweise abgelehnt wurde.")]),_c('p',[_vm._v("In diesem Fall bitten wir Sie, Ihren originalen Kaufbeleg per Post an die zuständige Agentur zu senden. Die entsprechende Adresse wird Ihnen per E-Mail mitgeteilt. Sollte Ihre Teilnahme schlussendlich als gültig bewertet werden, überweisen wir Ihnen selbstverständlich sowohl den Rückerstattungsbetrag als auch die Portokosten. Aus o.g. und anderen Gründen ist es deshalb notwendig, dass Sie Ihren Kaufbeleg aufbewahren, bis Ihre Rückerstattung auf Ihrem Konto eingegangen ist.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Was passiert mit meinen Daten, die ich auf der Aktionswebsite eingegeben habe? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Die Verarbeitung Ihrer personenbezogenen Daten erfolgt durch P&G und Justsnap als gemeinsam Verantwortliche im Sinne von Artikel 26 DSGVO. P&G und Justsnap haben den in diesem Fall gesetzlich geforderten Vertrag abgeschlossen, der die datenschutzrechtlichen Verantwortlichkeiten und Aufgaben im Innenverhältnis regelt.")]),_c('p',[_vm._v("Die Aktionsdaten werden zur operativen Abwicklung der Aktion für einen Zeitraum von drei Monaten nach dem Einsendeschluss gespeichert. Nach diesem Zeitpunkt werden die Aktionsdaten zur Erfüllung rechtlicher Aufbewahrungspflichten (auch unter Berücksichtigung der Verjährungsfristen für Ansprüche im Zusammenhang mit der Aktion) gespeichert. Unabhängig davon werden Informationen (einschließlich personenbeziehbarer Daten) zu dokumentierten Missbrauchs-/Betrugsversuchen für einen Zeitraum von 3 Jahren nach dem letzten dokumentierten Missbrauchs-/Betrugsversuch gespeichert. Soweit Sie eine Einwilligung erteilt haben, werden die von der Einwilligung umfassten Daten in der Regel so lange gespeichert, bis Sie Ihre Einwilligung widerrufen. Im Übrigen entnehmen Sie die Speicherfristen bezüglich der Verarbeitung Ihrer personenbezogenen Daten zu Marketingzwecken durch P&G bitte der P&G Datenschutzerklärung unter "),_c('a',{attrs:{"href":"https://www.pg.com/privacy/german/privacy_statement.shtml"}},[_vm._v("https://www.pg.com/privacy/german/privacy_statement.shtml")]),_vm._v(".")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"number"},[_vm._v(" Ich habe eine allgemeine Frage zu P&G. An wen muss ich mich wenden? ")]),_c('div',{staticClass:"list-item-details"},[_c('p',[_vm._v("Bitte kontaktieren Sie dazu den P&G Kundenservice für Deutschland unter 0800 58 89 198 (Montag – Freitag, 9.00 - 17.00 Uhr)")]),_c('p',[_c('a',{attrs:{"href":"https://de.pg.com/kontaktieren-sie-uns/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("https://de.pg.com/kontaktieren-sie-uns/")])])])])
}]

export { render, staticRenderFns }