<template>
  <div class="gray_block" :class="[(!shouldDisplayFull && !pullDown) ? 'drop_down_wrapper' : '', mainWrapperClass]">
    <div :style="{'cursor': !shouldDisplayFull ? 'pointer' : ''}"  @click="pull" @keypress.enter="pull" class="mainContent" tabindex="0">
      <div class="inner_gray_block" :style="{'margin-bottom': !shouldDisplayFull ? '30px' : ''}" >
        <div class="text" v-html="$t(descriptionText)"></div>
        <div class="subText" v-html="$t(descriptionSubText)"></div>
      </div>
      <div class="row align-items-center flex-wrap flex-sm-nowrap mt-2 justify-content-center">
        <div class="col-lg-4 col-sm-6 col-6 px-0">
          <img class="img-fluid" src="/assets/images/receipt-product-example.png" alt="receipt example">
        </div>
        <div class="col-lg-8 col-sm-6 col-6 px-0 mt-2">
          <ul class="wraper_punkt">
            <li class="media" :key="step.label" v-for="step in steps">
              <div class="item">{{ $t(step.label) }}</div>
              <div class="media-body item_text">{{ $t(step.text) }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div
        v-if="!hideExamples"
        class="wrapper_check_block"
      >
        <div class="checkMark">
          <div class="image-wrapper">
            <img
              class="img-fluid"
              src="../../assets/images/png/rcp-prod-ex1.png"
              alt="Correct"
            >
            <div class="d-flex mt-2">
              <img
                class="checkMark_img checkMark_img_1"
                src="../../assets/images/svg/checkMark.svg"
                alt="Correct"
              >
              <div class="text text-dark">
                {{ $t(this.texts.correct) }}
              </div>
            </div>
          </div>
        </div>
        <div class="checkMark">
          <div class="image-wrapper">
            <img
              class="img-fluid"
              src="../../assets/images/png/rcp-prod-ex2.png"
              alt="Incorrect"
            >
            <div class="d-flex mt-2">
              <img
                class="cross_red_img cross_red_img_2"
                src="../../assets/images/svg/cross_red.svg"
                alt="Incorrect"
              >
              <div class="text text-dark">
                {{ $t(this.texts.notReadabe) }}
              </div>
            </div>
          </div>
        </div>
        <div class="checkMark">
          <div class="image-wrapper">
            <img
              class="img-fluid"
              src="../../assets/images/png/rcp-prod-ex3.png"
              alt="Incorrect"
            >
            <div class="d-flex mt-2">
              <img
                class="cross_red_img cross_red_img_3"
                src="../../assets/images/svg/cross_red.svg"
                alt="Incorrect"
              >
              <div class="text text-dark">
                {{ $t(this.texts.folded) }}
              </div>
            </div>
          </div>
        </div>
        <div class="checkMark">
          <div class="image-wrapper">
            <img
              class="img-fluid"
              src="../../assets/images/png/rcp-prod-ex4.png"
              alt="Incorrect"
            >
            <div class="d-flex mt-2">
              <img
                class="cross_red_img cross_red_img_4"
                src="../../assets/images/svg/cross_red.svg"
                alt="Incorrect"
              >
              <div class="text text-dark">
                {{ $t(this.texts.cropped) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "receipt-product-information",
  props: {
    descriptionText: {
      required: false,
      type: String,
      default: ""
    },
    descriptionSubText: {
      required: false,
      type: String,
      default: ""
    },
    steps: {
      required: false,
      type: [Array, Object],
      default: () => []
    },
    hideExamples: {
      type: Boolean,
      default: false
    },
    mainWrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    pullDown: false
  }),
  computed: {
    shouldDisplayFull () {
      return screen.width > 992 ? true : false
    }
  },
  methods: {
    pull () {
      this.pullDown = !this.shouldDisplayFull ? !this.pullDown : false
    }
  }
};
</script>
<style lang="scss" scoped>
  .gray_block {
    background-color: transparent;
    padding: 10px 30px;
    border-radius: 4px;
  }
  .wraper_punkt {
    margin-bottom: 15px;
    li {
      margin-bottom: 20px;
      font-size: 16px;
      align-items: center;
      display: flex;
      @media (max-width: 991px) {
        margin-bottom: 15px;
      }
      .item {
        min-width: 25px;
        height: 25px;
        display: inline-block;
        color: var(--color-1);
        border-radius: 100px;
        text-transform: uppercase;
        margin-right: 10px;
        text-align: center;
        font-weight: bold;
        line-height: 26px;
      }
      .item_text {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.16px;
        text-align: left;
        color: var(--text-color-4);
        @media(max-width: 1024px){
          font-size: 12px;
        }
        @media (max-width: 668px) {
          font-size: 8px;
          line-height: 20px;
        }
      }
    }
  }
  .checkMark {
    position: relative;
    float: left;
    margin-left: 0px;
    max-width: 130px;
    min-width: 105px;
    height: 100%;
    .image-wrapper {
      width: 100%;
      height: 100%;
      border: 2px solid #333;
      border-radius: 8px;
      padding: 10px;
      background-color: #FFF;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .checkMark_img {
      width: 18px;
      margin-right: 4px;
      @media (max-width: 767px) {
        max-width: 30px;
      }
    }
    .cross_red_img {
      width: 18px;
      margin-right: 4px;
      @media (max-width: 767px) {
        max-width: 24px;
      }
    }
    .text {
      font-weight: 600;
      font-size: 8px;
      text-align: center;
      min-width: max-content;
      margin-bottom: 0;

      @media (max-width: 990px) {
        font-size: 10px !important;
      }
    }
  }
  .wrapper_check_block {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    gap: 15px;
    @media (max-width: 991px) {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: 1fr 1fr;
      justify-items: center;
    }
    @media (max-width: 767px) {
      height: unset;
      flex-wrap: wrap;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(62, 62, 64, 0.8);
  text-align: left;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    margin: 10px 0;
    font-size: 16px;
    line-height: 26px;
  }
}
.subText {
  font-size: 16px;
  line-height: 20px;
  color: var(--text-color-4);
  text-align: left;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 15px;
  }
}

.drop_down {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    cursor: pointer;


    p {
        margin: 0px;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
    }

    img {
        width: 20px;
        height: 20px;
    }
}

.drop_down_wrapper {
    padding: 10px !important;
    border-radius: 100px;
    margin-bottom: 20px !important;
}

@media all and (orientation: landscape ) {

    .drop_down {
        display: flex;
        justify-content: space-evenly;
        align-content: center;
        align-items: center;
        cursor: pointer;

        p {
            margin: 0px;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }

    .drop_down_wrapper {
        padding: 10px !important;
        border-radius: 100px;
        margin-bottom: 20px !important;
    }
    @media screen and (max-width: 425px) {
      .stepCardWrapper {
        padding: 15px !important;
        margin-bottom: 200px !important;
        border-radius: 30px;
        height: 450px;
        background-color: white !important;
      }
      .drop_down {
        display: none;
      }
    }
    @media screen and (max-width: 375px) {
      .stepCardWrapper {
        font-size: 12px !important;
      }
    }
}

.border {
  border: 8px solid #E5E8EE !important;
}

.mainContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.mobile_version_hide {
  @media (max-width: 900px) {
    display: none;
  }
}
</style>
