<template>
  <div class="m-0 checkboxWrapper" :style="cssVars">
    <ValidationProvider :rules="validation" v-slot="{ errors }" :name="name.toLowerCase()">
      <div class="wrapper" :style="styles">
        <label class="box-container" :for="`checkbox_${fieldId}`">
          <span v-html="$t(label)"></span>
          <input
            v-model="localChecked"
            :name="name"
            :checked="checked"
            type="checkbox"
            :id="`checkbox_${fieldId}`"
            required
          />
          <span class="checkmark"></span>
        </label>
        <div
          v-if="expandable"
          role="button"
          id="down-arrow-btn"
          :aria-expanded="expand"
          aria-controls="expandedDiv"
        >
          <img
            class="down-arrow"
            @click="checkBoxClicked"
            src="/assets/images/down-arrow.svg"
            alt
          />
        </div>
      </div>
      <div v-if="expandable" class="expandedDiv" ref='expandedDiv' id="expandedDiv">
        <div class="card card-body card-body-dop" v-html="$t(expandableText)"></div>
      </div>
      <div class="validation-error" style="margin-left: 5px;" v-if="errors.length">
        <img class="error_min" src="https://jolt-assets.s3.eu-central-1.amazonaws.com/template/islos/error.svg" alt="Error" >
        {{errors[0]}}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import store from "../../store";

export default {
  name: "checkbox",
  data () {
    return {
      localChecked: this.checked,
      expand: false
    };
  },
  props: {
    label: {
      type: String,
      default: "Empty Link Text"
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    expandable: {
      required: false,
      type: Boolean,
      default: false
    },
    expandableText: {
      required: false,
      type: String,
      default: ""
    },
    checked: {
      required: false,
      type: Boolean,
      default: false
    },
    fieldId: {
      type: String,
      required: true
    },
    validation: {
      type: [String, Object],
      required: false,
      default: ""
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    },
    colorSchema: {
      type: String,
      default: ""
    }
  },
  mounted () {
    this.$emit("component-updated", {
      checked: !!this.checked
    });
    this.$emit("input", !!this.checked);
    if (document.querySelector('.modalOpener')) {
      document.querySelectorAll('.modalOpener').forEach(x => {
        x.addEventListener('click', function () {
          store.commit('setModalOptions', {
            type: 'custom',
            message: this.dataset.text,
            isActive: true,
            closeButton: true
          })
        })
      })
    }
  },
  methods: {
    checkBoxClicked () {
      this.expand = !this.expand;
      const expandedDiv = this.$refs['expandedDiv'];
      expandedDiv.style.maxHeight = expandedDiv.style.maxHeight ? null : expandedDiv.scrollHeight + "px";
    }
  },
  updated () {
    if (document.querySelector('.modalOpener')) {
      document.querySelectorAll('.modalOpener').forEach(x => {
        x.addEventListener('click', function () {
          store.commit('setModalOptions', {
            type: 'custom',
            message: this.dataset.text,
            isActive: true,
            closeButton: true
          })
        })
      })
    }
  },
  watch: {
    localChecked (value) {
      // this.$eventHub.$emit('checkbox-event', { id: this.fieldId, checked: value ? '1' : '0' });
      this.$emit("component-updated", {
        checked: value
      });
      this.$emit("input", value);
    }
  },
  computed: {
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
}

/* The container */
.box-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  line-height: 27px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.box-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.box-container .checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid var(--color);
}

/* On mouse-over, add a grey background color */
.box-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.box-container input:checked ~ .checkmark {
  background-color: var(--color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.box-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.box-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.box-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.down-arrow {
  width: 26px;
  transition: all 0.3s;
  margin-left: 10px;
  margin-top: -8px;
}
[aria-expanded="true"] {
  .down-arrow {
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.card-body-dop {
  border: none;
  padding-left: 35px;
  background-color: transparent;
}

.expandedDiv {

  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
  -moz-transition: height 0.4s ease-out;; /* Firefox 4 */
  -webkit-transition: height 0.4s ease-out;;  /* Safari and Chrome */
  -o-transition: height 0.4s ease-out;;  /* Opera */

  &Image {
    -webkit-transform: rotate(180deg); /* Safari and Chrome */
    -moz-transform: rotate(180deg);   /* Firefox */
    -ms-transform: rotate(180deg);   /* IE 9 */
    -o-transform: rotate(180deg);   /* Opera */
    transform: rotate(180deg);
  }
}
</style>
