<template>
  <div class="container justify-content-center">
    <h2 class="pageTitle w-100 mt-5 mb-5" v-html="title"></h2>
    <ul style="list-style-type: none; width: 100%" v-if="ordered">
      <template v-for="(item, idx) in computedText">
        <li :key="idx">
          <div class="list-item-wrapper d-flex flex-row align-items-start">
            <div class="number">
              <div
                class="numberBackground"
                :style="`background-color: ${colorSchema}`"
              ></div>
              <span :style="`color: ${colorSchema}`">{{ idx + 1 }}</span>
            </div>
            <span class="list-item" v-html="item"></span>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
export default {
  name: "text-list",
  props: {
    items: {
      type: Array,
      default: () => {
        return ["item 1", "item 2", "item 3"]
      }
    },
    title: String,
    ordered: {
      type: Boolean,
      default: true
    },
    grid: {
      type: Object,
      default: () => {
        return {
          backgroundColor: "#fff"
        }
      }
    },
    actions: {
      type: Array
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B"
    }
  },
  data () {
    return {};
  },
  computed: {
    computedText () {
      return this.items.map(element => {
        return element.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
      })
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  outline: 0;
}
ul {
  padding: 0;
  margin: 0;
  .number {
    .numberBackground {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: black;
      opacity: 0.1;
      position: absolute;
      z-index: -1;
    }
    color: black;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .list-item-wrapper {
    margin-top: 24px;
    white-space: initial;
    .list-item {
      margin-left: 30px;
      font-weight: normal;
      white-space: initial;
      font-size: 16px;
      line-height: 26px;
      max-width: calc(100% - 30px);
      word-wrap: break-word;
    }
  }
}

@media (max-width: 425.98px) {
  h2 {
    font-size: 23px !important;
  }
}
</style>