<template>
  <div class="m-0 p-0 dateInputContainer">
    <ValidationProvider
      v-if="validation"
      :rules="validation"
      v-slot="{ errors }"
      :name="label"
    >
      <div class="dateInputWrapper form-group">
        <span
          :class="{ floatLabel: floatLabel || value }"
          class="floating-label"
        >{{ label }}</span>
        <datepicker
          :value="value"
          :style="styles"
          :class="{ 'is-invalid': errors.length }"
          @selected="updateValue"
          @opened="floatLabel = true"
          @closed="floatLabel = false"
          ref="input"
          :name="label"
          :id="name"
          v-bind="datepickerParams"
          required
        ></datepicker>
        <img
          v-if="errors.length"
          class="error_min inputError"
          src="../../assets/icons/risk-icon.svg"
          height="18px"
          width="18px"
          alt=""
        >
      </div>
      <div
        class="validation-error"
        v-if="errors.length"
      >
        {{ errors[0] }}
      </div>
    </ValidationProvider>
    <div
      class="m-0 p-0"
      v-else
    >
      <div class="dateInputWrapper form-group">
        <span
          :class="{ floatLabel: floatLabel || value }"
          class="floating-label"
        >{{ label }}</span>
        <datepicker
          :value="value"
          :style="styles"
          @selected="updateValue"
          @opened="floatLabel = true"
          @closed="floatLabel = false"
          ref="input"
          :name="label"
          :id="name"
          v-bind="datepickerParams"
          required
        >
        </datepicker>
      </div>
    </div>
  </div>
</template>

<script>

import Datepicker from "@hokify/vuejs-datepicker";
import "@hokify/vuejs-datepicker/dist/vuejs-datepicker.css"

export default {
  name: "DateInput",
  components: {
    Datepicker
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    value: {
      type: Date
    },
    validation: {
      type: String,
      required: false
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    params: {
      type: Object,
      required: false
    }
  },
  computed: {
    datepickerParams() {
      const params = { ...this.params }
      if (params.disabledDates) {
        if (params.disabledDates.to) {
          params.disabledDates.to = this.convertToDate(params.disabledDates.to)
        }
        if (params.disabledDates.from) {
          params.disabledDates.from = this.convertToDate(params.disabledDates.from)
          params.openDate = params.disabledDates.from
        }
      }
      return params
    }
  },
  data() {
    return {
      floatLabel: false
    }
  },
  methods: {
    updateValue(value) {
      this.$emit("component-updated", {
        value
      });
    },
    convertToDate(date) {
      if (date === "today") {
        return new Date()
      } else if (date === "18years") {
        const date = new Date()
        date.setFullYear(date.getFullYear() - 18)
        return date
      } else {
        return new Date(date)
      }
    }
  }
};
</script>

<style scoped lang="scss">
.inputError {
  position: absolute;
  right: 20px;
  top: 15px;
}

.dateInputWrapper {
  position: relative;
  margin: 5px;

  ::v-deep input {
    background-color: #FFFFFF;
    outline: 0;
    box-shadow: none;
    transition: border-color .15s;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box !important;
    padding-top: 12px !important;
    padding-left: 15px !important;
    height: 44px !important;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    color: #000;
  }

  ::v-deep .vdp-datepicker.is-invalid {
    ::v-deep div>input {
      border-color: #dc3545;
      background-color: #fff0f1;
    }
  }

  .floating-label {
    position: absolute;
    top: -17px;
    left: 10px;
    padding-right: 7px;
    transition: transform 0.1s;
    pointer-events: none;
    transform-origin: 0 100%;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: 20px;
    color: #1D222A;
    font-weight: 700;

  }
}

.validation-error {
  font-size: 13px;
  position: absolute;
  bottom: 3%;
  left: 2%;
}
</style>
