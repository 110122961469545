<template>
  <div class="campaign-wrapper">
    <b-container>
      <div>
        <div class="visual_banner" v-if="activeCampaigns.length">
          <!-- <campaign-carousel :campaigns="activeCampaigns" /> -->
          <img src="/assets/banner/desktop-banner.jpg" alt="desktop" class="desktop">
          <img src="/assets/banner/mobile-banner.jpg" alt="mobile" class="mobile">
        </div>
        <paragraph v-if="activeCampaigns.length" class="heading text-center brand-color" text="ACTIVITIES CAMPAIGNS">
        </paragraph>
        <!-- <ul class="categoryList">
          <li class="categoryListElement">
            <span
              :class="{ active: selectedCategory === 0 }"
              @click="selectCategory(0)"
            >
              Aktif Fırsatlar
            </span>
          </li>
          <li class="categoryListElement">
            <span
              :class="{ active: selectedCategory === 1 }"
              @click="selectCategory(1)"
            >
              Biten Fırsatlar
            </span>
          </li>
        </ul> -->
        <b-row class="campaigns">
          <b-col
            cols="12"
            md="6"
            :key="idx"
            v-for="(campaign, idx) in filteredCampaigns"
          >
            <div :class="campaign.class ? campaign.class : ''" class="campaign">
              <div class="visualContainer">
                <img
                  class="visual"
                  :src="campaign.squareImage"
                  :alt="campaign.name"
                  :style="
                    checkEnded(campaign.lastUploadDate)
                      ? 'mix-blend-mode: luminosity; cursor: auto'
                      : ''
                  "
                />
                <div class="overlay squareOverlay">
                  <!-- <img src="/assets/images/open.svg" alt="" /> -->
                  <div>
                    <p class="period-text">
                      {{ $t("Campaign Period")}}
                    </p>
                    <div
                      class="period-date"
                    >
                      {{ campaign.startDate }} - {{ campaign.endDate }}
                    </div>
                  </div>
                  <div class="next-button-container">
                    <button-component
                      name="start-external"
                      class="start"
                      @click.native="goToCampaign(campaign)"
                      v-html="checkEnded(campaign.endDate) ? 'DETAILS' : campaign.buttonName + `<img class='nexticon' style='height: 45px; margin-left:5px;' src='/assets/images/nexticon.svg' alt='nexticon'>`"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <paragraph v-if="!activeCampaigns.length" class="h3 mb-5 text-center brand-color" text="we are happy to provide them with new campaigns here in the future"></paragraph>
        <paragraph v-if="endedCampaigns.length" class="heading text-center brand-color" text="ENDED CAMPAIGNS">
        </paragraph>
        <b-row class="campaigns">
          <b-col
            cols="12"
            md="6"
            :key="idx"
            v-for="(campaign, idx) in endedCampaigns"
          >
            <div :class="campaign.class ? campaign.class : ''" class="campaign">
              <div class="visualContainer">
                <img
                  class="visual"
                  :src="campaign.squareImage"
                  :alt="campaign.name"
                  :style="
                    checkEnded(campaign.lastUploadDate)
                      ? 'mix-blend-mode: luminosity; cursor: auto'
                      : ''
                  "
                />
                <div class="overlay squareOverlay">
                  <!-- <img src="/assets/images/open.svg" alt="" /> -->
                  <div>
                    <p class="period-text">
                      {{ $t("Campaign Period")}}
                    </p>
                    <div
                      class="period-date"
                    >
                      {{ campaign.startDate }} - {{ campaign.endDate }}
                    </div>
                  </div>
                  <div class="next-button-container">
                    <button-component
                      name="start-external"
                      class="start"
                      @click.native="goToTerms(campaign)"
                      v-html="checkEnded(campaign.endDate) ? 'TEILNAHMEBEDINGUNGEN' + `<img class='nexticon' style='height: 45px; margin-left:5px;' src='/assets/images/nexticon.svg' alt='nexticon'>` : campaign.buttonName + `<img class='nexticon' style='height: 45px; margin-left:5px;' src='/assets/images/nexticon.svg' alt='nexticon'>`"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import paragraph from "./paragraph.vue";

import buttonComponent from "./button-component.vue"
import moment from 'moment'
import { localize } from 'vee-validate'

export default {
  name: "campaign-grid",
  components: {
    paragraph,
    buttonComponent
    // campaignCarousel
  },
  data: () => {
    return {
      campaigns: [
        { // Pink Raffle
          name: "pink-raffle",
          shortName: 'pink-raffle',
          url: 'pink-raffle',
          visual: "/assets/campaigns/pink-raffle/banner/desktop-banner.jpg",
          visualMobile: "/assets/campaigns/pink-raffle/banner/mobile-banner.jpg",
          startDate: "01.04.2024",
          endDate: "17.08.2024",
          lastUploadDate: "31.08.2024",
          squareImage: "/assets/campaigns/pink-raffle/banner/desktop-banner.jpg",
          buttonName:'Zum Gewinnspiel'
        },
        { // WOW raffle
          name: "wow-wochenende",
          shortName: 'wow-wochenende',
          url: 'wow-wochenende',
          visual:
            "/assets/campaigns/wow-wochenende/desktop-banner.jpg",
          visualMobile:
            "/assets/campaigns/wow-wochenende/mobile-banner.jpg",
          endDate: "01.07.2023",
          lastUploadDate: "15.07.2023",
          startDate: "01.04.2023",
          squareImage:
            "/assets/campaigns/wow-wochenende/square.jpg",
          buttonName:'Zum Gewinnspiel'
        },
        { // 3EUR cashback
          name: "3EUR cashback",
          shortName: '3EURcashback',
          url: '3EURcashback',
          visual:
            "/assets/campaigns/3EURcashback/banner/desktop-banner.jpg",
          visualMobile:
            "/assets/campaigns/3EURcashback/mobile-banner.jpg",
          endDate: "30.04.2023",
          lastUploadDate: "10.07.2023",
          startDate: "01.03.2023",
          squareImage:
            "/assets/campaigns/3EURcashback/square.jpg",
          buttonName:'Zum Gewinnspiel'
        }
      ],
      selectedCategory: 0,
      isTestVersion: false
    };
  },
  props: {
    requires: {
      type: Array
    },
    grid: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  created () {
    this.isTestVersion = process.env.NODE_ENV == 'development' || this.$route.query.version == 'test'
  },
  computed: {
    activeCampaigns () {
      return this.campaigns.filter((x) => !this.checkEnded(x.lastUploadDate));
    },
    filteredCampaigns () {
      if (this.selectedCategory === 0) {
        return this.activeCampaigns
      } else {
        return this.endedCampaigns
      }
    },
    endedCampaigns () {
      return this.campaigns.filter((x) => this.checkEnded(x.lastUploadDate));
    }
  },
  methods: {
    async goToCampaign (cam) {
      // Import the campaign file dynamically
      const campaign = await require(`../../store/campaigns/${cam.shortName}`).default;
      campaign.pageProperties.testing =
        this.$store.state.pageProperties.testing;
      this.$store.replaceState(campaign);
      this.initState(this.$store.state.pageProperties)

      setTimeout(() => {
        let ended = this.checkEnded(cam.lastUploadDate)
        if (ended) {
          this.goToPage(`${cam.url}/capmaign-is-over`, this)
        } else {
          this.goToPage(cam.url, this)
        }
      }, 10);
    },
    goToExternalCampaign(campaign){
      window.open(campaign.url)
    },
    goToTerms(campaign){
      window.open(campaign.url + '/teilnahmebedingungen')
    },
    checkEnded (date) {
      if (moment(date, 'DD.MM.YYYY').creationData().format == 'DD.MM.YYYY') {
        date = moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
      }
      const currentTime = new Date().toLocaleString("en-US", {
        timeZone: "Europe/Berlin"
      });
      const dayInSeconds = 86400; // If we need to include the ending day
      if ((new Date(date).getTime() / 1000 + dayInSeconds < new Date(currentTime).getTime() / 1000)) {
        return true
      } else {
        return false
      }
    },
    checkStarted (date) {
      if (moment(date, 'DD.MM.YYYY').creationData().format == 'DD.MM.YYYY') {
        date = moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
      }
      const currentTime = new Date().toLocaleString("en-US", {
        timeZone: "Europe/Istanbul"
      });
      if ((new Date(date).getTime() / 1000 < new Date(currentTime).getTime() / 1000) || this.isTestVersion) {
        return true
      } else {
        return false
      }
    },
    selectCategory (categoryId) {
      this.selectedCategory = categoryId;
    },
    initState (pageProperties) {
      if (pageProperties.dictionary) {
      // merges with built-in phrases
        localize(pageProperties.dictionary);
      }
      if (
        pageProperties.header &&
      pageProperties.header.properties.facebookPixel
      ) {
        const facebookConfig =
        pageProperties.header.properties.facebookPixel;
        this.addFacebookPixel(facebookConfig);
      }

      if (
        pageProperties.header &&
      pageProperties.header.properties.chat
      ) {
        const chat = pageProperties.header.properties.chat;
        if (chat.control) {
          this.addHTML(chat.control);
        }
        if (chat.js) {
          this.addJsFile(chat.js);
        }
        if (chat.css) {
          this.addCssFile(chat.css);
        }
      }

      if (
        pageProperties.header &&
      pageProperties.header.properties.meta
      ) {
        const meta = pageProperties.header.properties.meta;
        if (meta) {
          this.meta = meta;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.categoryList {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.categoryListElement {
  display: inline;
  margin: 0 10px;
  text-align: center;
  &:hover {
    color: var(--brand-light-color);
    cursor: pointer;
  }
}
.active {
  color: var(--brand-light-color);
  font-weight: 700;
}
.clock {
  margin: 0 10px;
}
.campaignDescription {
  > p {
    margin: 0;
  }
  padding: 20px;
  font-size: 20px;
}
.campaignEndDate {
  display: flex;
  margin-bottom: 20px;
  font-size: 18px;
}
@media (max-width: 991px) {
  .campaignEndDate {
    font-size: 14px;
    margin: 0;
  }
  .campaignDescription {
    font-size: 14px;
    padding: 0;
  }
}
@media (max-width: 500px) {
  .campaignEndDate {
    font-size: 13px;
    margin: 0;
  }
  .campaignDescription {
    font-size: 13px;
    padding: 0;
  }
}
.brand-color {
  color: var(--brand-light-color);
}
.campaign-wrapper {
  padding: 0 0 60px 0;

  @media (max-width: 767px) {
    padding: 0px 0 0;
    margin-bottom: 40px;
  }
}

.campaigns {
  margin-top: 40px;
  display: flex;
  justify-content: center;

  & > div {
    margin-bottom: 30px;
  }

  .visual {
    width: 100%;
    // height: 100%;
    object-fit: cover;
  }

  .visualContainer {
    position: relative;
    flex: 1;
    cursor: pointer;
  }

  .descriptionContainer {
    flex: 1;
    padding: 10px;
  }

  .campaign {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    transition: box-shadow 0.5s;
    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      > .visualContainer > .overlay {
        opacity: 1;
      }
    }
  }

  .squareOverlay {
    // border-top-right-radius: 0 !important;
    // border-bottom-right-radius: 0 !important;
    width: 100% !important;
  }

  .overlay {
    // border-radius: 10px;
    overflow: hidden;
    width: 100%;
    // position: absolute;
    display: flex;
    // flex-direction: column;
    // top: 0;
    // opacity: 0;
    // height: 100%;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    background-color: #4F4F4F;
    transition: 0.3s;
    padding: 25px 15px;
    @media(max-width: 768px){
      padding: 20px 10px !important;
    }

    img {
      width: 55px;
      height: 55px;

      @media (max-width: 992px) {
        width: 55px;
        height: 55px;
      }
    }
    .period-text{
      font: normal normal 400 15px/16px Montserrat;
      color: white;
      // @media(max-width: 450px){
      //   font-size: 13px !important;
      // }
    }
    .period-date{
      font: normal normal 400 15px/16px Montserrat;
      color: white;
      @media(max-width: 450px){
        font-size: 13px !important;
      }
    }
  }
}
.swiffer-3eurcashback-card{
  @media(min-width: 1024px){
    height: 347px !important;
    min-height: 347px !important;
    .visualContainer{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #52ae32;
    }
  }
}
.next-button-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background: white !important;
    border-radius: 50px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    .start {
      // width: 150px;
      padding: 0px !important;
      padding-left: 5px !important;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border-radius: 50px;
      color: #348721;
      font: normal normal 600 14px/18px Montserrat;
      @media (max-width: 992px) {
        // width: 150px;
        height: 45px;
        font: normal normal 600 12px/16px Montserrat;
      }
    }
  .nexticon{
    height: 100% !important;
  }
}

.visual_banner {
  margin: 15px;
  min-width: 100%;
  @media(max-width: 450px){
    margin: 0px !important;
    margin-bottom: 30px !important;
  }
  img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
    cursor: pointer;
    &.desktop {
      // @media (min-width: 767px) {
      //   display: block;
      // }
      @media (max-width: 766px) {
        display: none;
      }
    }
    &.mobile {
      display: none;
      @media (max-width: 766px) {
        display: block;
      }
    }
  }
}

</style>
