import heading from "@/components/renderers/heading";
import paragraph from "@/components/renderers/paragraph";
import squareImage from "@/components/renderers/square-image";
import alert from "@/components/renderers/alert";
import bucket from "@/components/renderers/bucket";
import container from "@/components/renderers/container";
import flexContainer from "@/components/renderers/flex-container";
import card from "@/components/renderers/card";
import keyVisual from "@/components/renderers/key-visual";
import navbar from "@/components/renderers/navbar";
import sideNavbar from "@/components/renderers/side-navbar";
import anchor from "@/components/renderers/anchor";
import receiptProcessor from "@/components/renderers/receipt-processor"
import productUpload from "@/components/renderers/product-upload"
import formComponent from "@/components/renderers/form-component"
import textInput from "@/components/renderers/text-input"
import stepCard from "@/components/renderers/step-card"
import stepsComponent from "@/components/renderers/steps-component"
import stepsHorizontal from "@/components/renderers/steps-horizontal"
import selectInput from "@/components/renderers/select-input"
import radioInput from "@/components/renderers/radio-input"
import dateInput from "@/components/renderers/date-input"
import buttonComponent from "@/components/renderers/button-component"
import productSelection from "@/components/renderers/ProductSelection/index.vue"
import emptyBucket from "@/components/renderers/empty-bucket"
import receiptInformation from "@/components/renderers/receipt-information"
import receiptExamples from "@/components/renderers/receipt-examples"
import smsVerification from "@/components/renderers/SmsVerification/sms-verification"
import smsReceiving from "@/components/renderers/SmsVerification/sms-receiving"
import checkbox from "@/components/renderers/checkbox"
import gtinComponent from "@/components/renderers/gtin-component"
import stepCounter from "@/components/renderers/step-counter"
import footerComponent from "@/components/renderers/footer-component"
import question from "@/components/renderers/question"
import textList from "@/components/renderers/text-list"
import reclaimBucket from "@/components/renderers/reclaim-bucket"
import injectTag from "@/components/renderers/inject-tag"
import dataLayerEvent from "@/components/renderers/datalayer-event"
import refreshToken from "@/components/renderers/refresh-token"
import queryStringToState from "@/components/renderers/query-string-to-state"
import barcodeScanner from "@/components/renderers/BarcodeScanner"
import retailer from "@/components/renderers/retailer"
import ibanReupload from "@/components/renderers/iban-reupload"
import checkReceiptToken from "@/components/renderers/checkReceiptToken"
import participation from "@/components/renderers/participation"
import doi from "@/components/renderers/doi"
import progressBar from "@/components/renderers/progress-bar"
import cardBucket from "@/components/renderers/card-bucket"
import clipboard from "@/components/renderers/clipboard"
import survey from "@/components/renderers/survey"
import faq from "@/components/renderers/faq"
import embeddedDoc from "@/components/renderers/embedded-doc"
import campaignGrid from"@/components/renderers/campaign-grid"
import campaignCarousel from"@/components/renderers/campaign-carousel"
import redirectToCampaignIsOverPage from "@/components/renderers/redirectToCampaignIsOverPage"
import campaignSteps from "@/components/renderers/campaign-steps"
import checkReceiptTokenSM from '@/components/renderers/SessionManager/check-receipt-token-SM.vue'
import doiSM from '@/components/renderers/SessionManager/doi-SM.vue'
import ibanReuploadSM from '@/components/renderers/SessionManager/iban-reupload-SM.vue'
import participationSM from '@/components/renderers/SessionManager/participation-SM.vue'
import productUploadSM from '@/components/renderers/SessionManager/product-upload-SM.vue'
import receiptProcessorSM from '@/components/renderers/SessionManager/receipt-processor-SM.vue'
import receiptProductInformation from "@/components/renderers/receipt-product-information"
import unorderedList from "@/components/renderers/unordered-list"
import pdfViewer from "@/components/renderers/pdf-viewer.vue"

export default [
  barcodeScanner,
  embeddedDoc,
  refreshToken,
  dataLayerEvent,
  injectTag,
  reclaimBucket,
  ibanReupload,
  textList,
  question,
  stepCounter,
  receiptInformation,
  receiptExamples,
  smsVerification,
  smsReceiving,
  heading,
  paragraph,
  squareImage,
  alert,
  bucket,
  emptyBucket,
  container,
  flexContainer,
  card,
  keyVisual,
  navbar,
  sideNavbar,
  anchor,
  receiptProcessor,
  productUpload,
  formComponent,
  textInput,
  stepCard,
  stepsComponent,
  selectInput,
  radioInput,
  dateInput,
  buttonComponent,
  productSelection,
  checkbox,
  gtinComponent,
  footerComponent,
  queryStringToState,
  retailer,
  checkReceiptToken,
  participation,
  doi,
  progressBar,
  cardBucket,
  clipboard,
  stepsHorizontal,
  survey,
  faq,
  campaignGrid,
  campaignCarousel,
  redirectToCampaignIsOverPage,
  campaignSteps,
  checkReceiptTokenSM,
  doiSM,
  ibanReuploadSM,
  participationSM,
  productUploadSM,
  receiptProcessorSM,
  receiptProductInformation,
  unorderedList,
  pdfViewer
];
