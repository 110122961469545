export default {
  'ACTIVITIES CAMPAIGNS':'AKTUELLE AKTIONEN',
  'ENDED CAMPAIGNS' : 'BEENDETE AKTIONEN',
  'we are happy to provide them with new campaigns here in the future': 'Aktuell gibt es leider keine Aktionen, aber wir freuen uns demnächst wieder Aktionen für Sie online zu stellen.',
  'Campaign Period':'Kampagnenzeitraum',
  'JOIN HERE':'ZUM GEWINNSPIEL',
  'Start cashback': ' RÜCKERSTATTUNG STARTEN',
  'Start raffle': 'Am Gewinnspiel teilnehmen',
  'Choose products': 'Produkt<br>kaufen',
  'Upload receipt': 'Kaufbeleg<br>hochladen',
  'Receive money back': "Geld zurück<br>erhalten",
  'Take part in raffle': 'Am Gewinnspiel<br>teilnehmen',
  "We're glad you tried our Always overnight pads. As we continue to improve our products, we'd like to hear your honest opinion:": 'Wir freuen uns, dass Sie unsere Always Binden für die Nacht ausprobiert haben. Da wir unsere Produkte weiterhin verbessern wollen, würden wir gerne Ihre ehrliche Meinung erfahren:',
  'Select participating product': 'Teilnehmendes Produkt auswählen',
  'Select the participating product that you would like to be refunded and that is included on the receipt.': 'Wählen Sie das teilnehmende Produkt aus, das Sie erstattet bekommen möchten und das auf dem Kassenbeleg enthalten ist.',
  'Next': 'Weiter',
  "Next-2": "Fertig",
  'Back': 'Zurück',
  'Upload a receipt': 'Kaufbeleg hochladen',
  'In this step, please upload the proof of purchase of your Swiffer products!': 'Bitte laden Sie in diesem Schritt den Kaufbeleg Ihrer Swiffer Produkte hoch!',
  'Zoom In': 'Hineinzoomen',
  'Zoom Out': 'Herauszoomen',
  'Drag': 'Ziehen',
  'Erase': 'Ausradieren',
  'Crop': 'Zuschneiden',
  'Please erase your personal data with the eraser tool.': 'Sie können private Informationen mit dem Radierer-Tool löschen.',
  'Please frame the receipt / invoice in the gray rectangle.': 'Bitte passen Sie das graue Rechteck an den Kassenbeleg an.',
  'Choose a file': 'Datei Auswählen',
  'Click here to upload the receipt photo': 'Klicken Sie hier, um das Kaufbelegsfoto hochzuladen',
  'Only JPEG or PNG. Maximum 16MB': 'Nur JPEG oder PNG<br>Maximale Dateigröße: 16 MB',
  'Only JPEG, PNG or PDF. Maximum 16MB': 'Nur JPEG, PNG oder PDF<br>Maximale Dateigröße: 16 MB',
  'Wrong format': 'Dieses Dateiformat wird nicht unterstützt. Es werden nur .jpeg und .png Dateien akzeptiert.',
  'Wrong format receipt': 'Dieses Dateiformat wird nicht unterstützt. Es werden nur .jpeg, .png und .pdf Dateien akzeptiert.',
  'File too big': 'Diese Datei ist zu groß. Es können nur Dateien bis 16 MB hochgeladen werden.',
  'Please photograph your purchase receipt. Please make sure that the following information is clearly visible:': 'Bitte machen Sie ein Foto vom Orginal-Kaufbeleg Ihres Swiffer Produkts bzw. Ihrer Swiffer Produkte! Stellen Sie dabei bitte sicher, dass die folgenden Informationen gut erkennbar sind:',
  'Retailer / Shop': 'Händler / Markt',
  'Date and time of purchase': 'Kaufdatum & Kaufzeit',
  'Campaign product and its price': 'Aktionsprodukt & Kaufpreis',
  'Total sum of the receipt / invoice and applied VAT': 'Gesamtsumme des Belegs & ausgewiesene Mehrwertsteuer',
  'Receipt / Invoice ID (not the bank transaction ID)': 'Bonnummer (nicht Belegnummer)',
  'Upload a product photo': 'Produktfoto hochladen',
  'Please upload a photo of the front of the Swiffer product with the yellow banner.': 'Bitte laden Sie ein Foto von der Vorderseite Ihres Swiffer-Produktes bzw. Ihrer Swiffer-Produkte.',
  'Please upload a photo of the product backs of your promotional products, whereby the EAN barcodes must be clearly visible.': 'Bitte laden Sie ein Foto Ihres Aktionsproduktes bzw. Ihrer Aktionsprodukte hoch, auf dem der EAN-Strichcode (Barcode) gut sichtbar ist.',
  'First name of the account holder*': 'Vorname Kontoinhaber*',
  'Last name of the account holder*': 'Nachname Kontoinhaber*',
  'This action is recommended by Swiffer. Please create an account, log in or continue as a guest to participate in the promotion.': 'Diese Aktion wird von Swiffer empfohlen. Bitte erstellen Sie einen Account, loggen Sie sich ein oder fahren Sie als Gast fort, um an der Aktion teilzunehmen.',
  'This action is recommended by Swiffer.': 'Diese Aktion wird von Swiffer empfohlen.',
  'Registration form': 'Anmeldeformular',
  'Registration form forme': 'Für ein For Me Konto registrieren',
  "For Me is a marketing program from Procter & Gamble, makers of Swiffer, Ariel, Lenor, Gillette, Oral-B and many more brands. For Me regularly informs you about further “free trials” campaigns and also offers exclusive free product trials weekly. Register now and don't miss anything anymore.": '<div class="w-100 mb-4 text-center"><img src="/assets/images/forme.png"></div>For Me ist ein Marketingprogramm von Procter & Gamble, dem Hersteller von Swiffer, Ariel, Lenor, Gillette, Oral-B und vielen weiteren Marken.<br><br>For Me informiert Sie regelmäßig über weitere Aktionen und bietet darüber hinaus wöchentlich exklusiv kostenlose Produkttests an. Jetzt registrieren und nichts mehr verpassen.',
  "wow register text": '<div class="w-100 mb-4 text-center"><img src="/assets/images/forme.png"></div>For Me ist ein Marketingprogramm von Procter & Gamble, dem Hersteller von Swiffer, Ariel, Lenor, Gillette, Oral-B und vielen weiteren Marken.<br><br>For Me informiert Sie regelmäßig über weitere Aktionen und bietet darüber hinaus wöchentlich exklusiv kostenlose Produkttests an. Jetzt registrieren und nichts mehr verpassen.',
  'Ms / Mrs': 'Frau',
  'Mr': 'Herr',
  'First name': 'Vorname*',
  'Last name': 'Nachname*',
  'Date of birth': 'Geburtsdatum*',
  'dob-non-mandatory': "Geburtsdatum",
  'Email address': 'E-Mail-Adresse*',
  'Password': 'Passwort*',
  'Street': 'Straße*',
  'Nr.': 'Nr.',
  'Postal code': 'PLZ*',
  'City': 'Ort*',
  'Country': 'Land*',
  'Forgot password?': 'Passwort vergessen?',
  'Please verify your identity': 'Bitte bestätigen Sie Ihre Identität',
  'Your mobile phone number is required to verify your participation. The mobile phone number you provide will only be used for a one-time, advertising-free verification and will be deleted by us after the campaign.': 'Die Angabe der Mobilrufnummer ist zur Verifizierung Ihrer Teilnahme erforderlich. Die von Ihnen angegebene Mobilrufnummer wird ausschließlich zur Verifizierung verwendet und wird nach der Aktion von uns gelöscht.',
  'We have sent you a code via SMS to the phone number you provided. Please enter this in the field below. If you did not receive a code, please change your phone number and try again': 'Wir haben Ihnen an die von Ihnen angegebene Telefonnummer per SMS einen Code geschickt. Geben Sie diesen bitte in das untenstehende Feld ein. Sollten Sie keinen Code erhalten haben, ändern Sie bitte Ihre Telefonnummer und versuchen Sie es erneut',
  'Change the mobile phone number': 'Handynummer ändern?',
  'Mobile phone number': 'Handynummer *',
  'Verification code': 'Bestatigungscode*',
  'Your address': 'Ihre Adresse',
  'Back to swiffer website': 'Zurück zur Swiffer-Website',
  'Thank you for your participation': 'Danke für die Teilnahme',
  'Your receipt  / invoice and your data have been successfully sent to us and are being checked at the moment.': 'Vielen Dank, dass du an unserer Cashback-Aktion teilnimmst! Wir haben Kaufbeleg und Daten erhalten und überprüfen jetzt alles.',
  'Reference Number:': 'Ihre Referenznummer: <b style="color: var(--color-5);">{0}</b>',
  'As soon as we have checked your data, you will either receive a confirmation e-mail or an e-mail with questions if your data was incomplete or your receipt could not be recorded. This can take up to 36 hours. We ask for your patience. After successfully checking the proof of purchase and all other data, we will transfer the reimbursement amount to the account you specified within 4 to 6 weeks. If anything is unclear, we will contact you again separately by email.': 'Ist alles vollständig, bekommst du von uns eine Bestätigungsemail. Falls noch etwas fehlt oder wir noch Fragen haben, bekommst du von uns ebenfalls eine Mail. Es kann bis zu 36 Stunden dauern, bis wir uns bei dir melden – wir bitten um deine Geduld! Wenn wir alle Informationen beisammen und geprüft haben, überweisen wir dir den Erstattungsbetrag binnen sechs Wochen auf das von dir angegebene Konto.',
  'Enter bank details': 'Bankdaten Angeben',
  'Please enter the first name and last name of the account holder exactly as they would be given at the bank.': 'Bitte geben Sie den Vornamen und Nachnamen des Kontoinhabers exakt so an, wie diese auch bei der Bank angegeben würden.',
  '*Avoid changing the umlauts: Müller or Mueller. Avoid adding or omitting hyphens: Marie Luise or Marie-Luise. Only enter the middle name if the account is kept in this way. If the data does not match the account data, in most cases the bank will charge back and make it difficult for an uncomplicated and quick refund to be made.': '*Vermeiden Sie eine Änderung bei den Umlauten: Müller bzw. Mueller. Vermeiden Sie ein Hinzufügen oder Weglassen von Bindestrichen: Marie Luise bzw. Marie-Luise. Geben Sie den zweiten Vornamen nur dann an, wenn auch das Konto so geführt wird. Sollten die Daten nicht mit den Kontodaten übereinstimmen, kommt es in den meisten Fällen zu Rückbuchungen seitens der Bank und erschwert den Ablauf einer unkomplizierten und raschen Rückerstattung.',
  'Confirm password': 'Bestätigen des Passwort*',
  'Please upload your receipt again here': 'Bitte laden Sie hier Ihren Kaufbelen erneut hoch',
  'Please make sure that the information described below is clearly visible:': 'Stellen Sie dabei bitte sicher, das die unten beschriebenen Informationen gut erkennbar sind:',
  'Please do not try to enter the same bank details that the first attempt at transfer failed with. If you want to try again with the same bank details or just want to correct your BIC, please contact iban-3-eur-cb@swiffer-promotions.de': 'Bitte versuchen Sie nicht, dieselbe Bankverbindung anzugeben, bei der schon der erste Überweisungsversuch nicht geklappt hat. Sollten Sie erneut einen Versuch mit derselben Bankverbindung wünschen oder nur Ihren BIC korrigieren wollen, kontaktieren Sie bitte <a style="color: var(--color-5) !important; font-weight: 500;" href="mailto:iban-3-eur-cb@swiffer-promotions.de">iban-3-eur-cb@swiffer-promotions.de</a>',
  'Avoid changing the umlauts: Müller or Mueller. Avoid adding or omitting hyphens: Marie Luise or Marie-Luise. Only enter the middle name if the account is kept in this way. If the data does not match the account data, in most cases the bank will charge back and make it difficult for an uncomplicated and quick refund to be made.': 'Vermeiden Sie eine Änderung bei den Umlauten: Müller bzw. Mueller. Vermieden Sie ein Hinzufügen oder Weglassen von Bindestrichen: Marie Luise bzw. Marie-Luise. Geben Sie den zweiten Vornamen nur dann an, wenn auch das Konto so geführt wird. Sollten die Daten nicht mit den Kontodaten übereinstimmen, kommt es in den meisten Fällen zu Rückbuchungen seitens der Bank und erschwert den Ablauf einer unkomplizierten und raschen Rückerstattung.',
  'Thank you for resubmitting your bank details.<br>So there should now be a successful transfer. If you have any questions related to your bank details, please contact iban-3-eur-cb@swiffer-promotions.de. For other concerns you can contact our support service at contact@swiffer-gratis-testen.de': `Vielen Dank, dass Sie Ihre Bankdaten erneut eingereicht haben. Einer erfolgreichen Uberweisung sollte also nichts mehr im Wege stehen. Sollten Sie Fragen haben, die mit Ihren Bankdaten zusammenhangen, wenden Sie sich bitte an <a style="color: var(--color-5) !important;" href='mailto:iban-3-eur-cb@swiffer-promotions.de'>iban-3-eur-cb@swiffer-promotions.de</a>. Fur sonstige Anliegen konnen Sie unseren  Support Service unter <a style="color: var(--color-5) !important;" href='mailto:3-eur-cb@swiffer-promotions.de'>3-eur-cb@swiffer-promotions.de</a> kontaktieren.`,
  'Upload product photo again': 'Produktfoto erneut hochladen',
  'Thank you': 'Danke',
  'optInYes': '<b>Wie schön, dass du ForMe-Produkttester:in werden möchtest!</b><br/><br/> Damit du mitmachen kannst, bitten wir dich, deine Mailadresse zu bestätigen. Du hast schon Post von ForMe  mit dem Bestätigungslink in deinem Postfach.<br/> ForMe ist unser Marketingprogramm von Procter & Gamble – dem Hersteller von Swiffer, Ariel, Braun Gillette, Oral-B und vielen anderen Marken. Wir verschicken regelmäßig P&G Markenprodukte zum gratis testen. Als registrierte:r ForMe-Produkttester:in erfährst du sofort, wenn wieder neue Produkttests oder Verlosungen anstehen.',
  'optInNo': '<b>Du möchtest gratis tolle Produkte testen und uns dazu deine Meinung sagen? Dann mach jetzt mit und werde ForMe-Produkttester:in!</b><br/><br/> Um deinen Account zu aktivieren, klick einfach <a href="https://www.for-me-online.de/anmelden" target="_blank" style="text-decoration: underline;">hier</a>, schreib deine Mailadresse in das Anmeldefeld und richte dir über „Passwort vergessen“ ein Passwort ein.<br/> ForMe ist unser Marketingprogramm bei Procter & Gamble. Wir sind die Hersteller von Swiffer, Ariel, Braun Gillette, Oral-B und vielen anderen Marken. Wir verschicken regelmäßig P&G Markenprodukte zum gratis testen. Als registrierte:r ForMe-Produkttester:in erfährst du sofort per Mail, wenn wieder neue Produkttests oder Verlosungen anstehen.Wenn du mitmachen möchtest, dann klick auf den Link in der Bestätigungmail, die du gleich von uns bekommst! ',
  'Thanks for subscribing': 'Danke für die Anmeldung',
  'You have successfully registered for our newsletter.': 'Sie haben sich erfolgreich für unseren Newsletter angemeldet.',
  'From now on you will be one of the first to know when there are free product tests, competitions and inspirational articles.': 'Ab sofort erfahren Sie als eine/r der Ersten, wenn es kostenlose Produkttests, Gewinnspiele und inspirierende Artikel gibt.',
  'The promotion period is over': 'Der Aktionszeitraum ist vorbei',
  'Sign up to stay up to date. Receive special offers and updates.': 'Registrieren Sie sich, um auf dem Laufenden zu bleiben. Erhalten Sie Sonderangebote und Updates.',
  'Finished': 'Fertig',
  'Thank you for your notification.': 'Vielen Dank für Ihre Benachrichtigung.',
  'We will check the refund amount again and then inform you. The review may take some time, we ask for your patience.': 'Wir werden den Erstattungsbetrag nochmals überprüfen und Sie anschließend informieren. Die Überprüfung kann einige Zeit in Anspruch nehmen, wir bitten um Ihre Geduld.',
  'Login with forme account': 'Einloggen mit For Me konto',
  'LOG IN': 'Einloggen mit For Me konto',
  'Register as guest': 'Als gast ohne registrierung weitermachen',
  'This session has expired': 'Diese Sitzung ist abgelaufen',
  'Oops, something went wrong. Please try again.': 'Hoppla, da ist etwas schief gelaufen.<br>Bitte versuchen Sie es erneut.',
  'Unfortunately the link is no longer valid! Please participate again or contact our support team: kontakt@swiffer- gratis-testen.de': `Leider ist der Link nicht mehr gültig! Bitte nehmen Sie erneut teil oder kontaktieren Sie unser<br> Support Team: <a href='mailto:pink-raffle@swiffer-promotions.de' style="color: #000 !important; text-decoration: underline; font-weight: bold;">pink-raffle@swiffer-promotions.de</a>`,
  'Continue as a guest and get your money refunded': 'Als Gast weitermachen und Geld erstattet bekommen',
  "wow-guest-heading": "ALS GAST WEITERMACHEN UND AM GEWINNSPIEL TEILNEHMEN",
  'Your password has been reset. You can return to the login page and log in with the new password.': 'Ihr Passwort wurde zurückgesetzt. Sie können zur Login-Seite zurückkehren und sich mit dem neuen Passwort einloggen.',
  'A password reset link has been sent. Please check your email': 'Ein Link zum Zurücksetzen Ihres Passwortes wurde an Ihre E-Mail-Adresse geschickt. Bitte überprüfen Sie Ihren Posteingang.',
  //DOI texts:
  'doi-first-paragraph': `Großartig, dass du bei unserer <b>MARKE</b>-Aktion mitmachst!<br/><br/> Wir heißen dich auch herzlich als <b>ForMe</b>-Produkttester:in willkommen. Ein Klick noch und du bist dabei!`,
  'doi-second-paragraph': `Um an unseren kostenlosen Produkttests teilnehmen zu könn en, musst du nur noch oben klicken und deine Mailadresse bestätigen. Wir freuen uns auf deine Meinung! Durch unseren Newsletter erfährst du dann regelmäßig, wenn wieder eine neue Aktion startet.`,
  'doi-third-paragraph': `Zur Erinnerung: ForMe ist unser Marketingprogramm bei Procter & Gamble. Wir sind die Hersteller von Swiffer, Ariel, Braun Gillette, Oral-B und vielen anderen Marken. Wir verschicken regelmäßig P&G Markenprodukte zum gratis Testen. Als registrierte:r ForMe-Produkttester:in erfährst du sofort, wenn wieder neue Produkttests oder Verlosungen anstehen. Wenn du diese Mail irrtümlich erhalten hast und nicht mitmachen möchtest, tu jetzt einfach nichts. Es wäre aber schade! `,
  'doi-fourth-paragraph': `Mit freundlichen Grüßen <br/>dein Marke und ForMe-Team`,
  validation: {
    customRequired: {
    }
  }
}
