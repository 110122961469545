<template>
  <nav class="navbar navbar-wrapper navbar-expand-lg" ref="navbarWrapper" :style="[cssVars, styles]">
    <a class="navbar-brand p-0" :href="logo.url || '/'" aria-label="Swiffer Website">
      <img
        :src="logo.imageSource"
        :alt="logo.alt"/>
    </a>
    <div class=" justify-content-center d-none d-lg-flex d-xl-flex">
        <h1 v-if="title" class="m-0 p-0"></h1>
    </div>

    <button
      class="navbar-toggler"
      type="button"
      @click="checkBoxClicked"
      v-if="fields.length"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse expandedDiv" ref='expandedDiv'>
      <ul class="navbar-nav">
        <li class="navbarLinks" v-for="field in fields" :key="field.fieldId">
          <componentRenderer v-bind:key="field.id" :field="field" />
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import componentRenderer from "@/components/component-renderer";

export default {
  name: "navbar",
  components: {
    componentRenderer
  },
  props: {
    styles: {
      type: Object,
      default: () => {
        return {
          "background": "#FFF",
          "color": "#000"
        }
      }
    },
    title: {
      type: String
    },
    logo: {
      required: true,
      type: Object
    },
    fields: {
      required: false,
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    cssVars () {
      return {
        '--color': this.styles.color,
        '--bg-color': this.styles.background
      }
    }
  },
  methods: {
    checkBoxClicked () {
      const expandedDiv = this.$refs['expandedDiv'];
      const navbarWrapper = this.$refs['navbarWrapper']
      expandedDiv.style.maxHeight = expandedDiv.style.maxHeight ? null : expandedDiv.scrollHeight + 10 + "px";
      // navbarWrapper.style.borderRadius = navbarWrapper.style.borderRadius === '10px' ? '30px' : "10px"
      navbarWrapper.style.height = navbarWrapper.style.height === '150px' ? null : "150px"
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar-wrapper {
  justify-content: space-between;
  z-index: 9999;
  @media(max-width: 450px){
    max-width: 100vw !important;
    display: flex;
    align-items: center;
    margin: 0px !important;
  }
  .navbar-brand {
    img {
      position: absolute;
      top: 2px;
      max-width: 200px;
      margin: 0 20px;
      @media(max-width: 576px){
        top: 12px;
        max-width: 90px;
        position: static !important;
        margin: 0px !important;
      }
    }
  }
}
.navbar-collapse{
  flex-grow:initial;
}
.navbar-toggler-icon{
  background-image: url('@/assets/images/navbar-toggler.svg') !important;
  width: 24px !important;
  height: 24px !important;
}
.expandedDiv {
  @media (max-width: 991px) {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
    -moz-transition: height 0.4s ease-out;; /* Firefox 4 */
    -webkit-transition: height 0.4s ease-out;;  /* Safari and Chrome */
    -o-transition: height 0.4s ease-out;;  /* Opera */
  }
}
</style>
