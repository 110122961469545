<template>
  <div
    class="counterWrapper"
    :style="styles"
  >
    <div class="counter">
      <div
        class="digits"
        v-html="htmlContent"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'participation-SM',
  props: {
    text: {
      type: String,
      default: 'Empty paragraph'
    },
    variables: Object,
    styles: {
      type: Object,
      default: () => {
        return {}
      }
    },
    classes: {
      type: Array,
      default: () => {
        return []
      }
    },
    endpoint: {
      type: String,
      default: '/participationCount'
    },
    displayCounter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      totalParticipations: 'nan',
      cssString: '',
      classString: ''
    }
  },
  mounted() {
    this.checkParticipationCount({
      api: 'https://service-manager.jsnp.io/api/getRemaining'
    }).then((data) => {
      this.totalParticipations = data.remaining > 0 ? data.remaining : 0
    })
  },
  computed: {
    html() {
      let result = this.text
      if (this.variables) {
        for (const variable in this.variables) {
          let value = this.$store.state.formData[variable]
          if (variable === 'referenceNumber') {
            value = this.$store.getters.getReferenceNumber
          }
          result = result.replace(new RegExp(`{${variable}}`, 'g'), value)
        }
      }
      return result
    },
    htmlContent() {
      let content = `${this.html} <b class="digitWrapper">${this.participationNumber}</b>`
      return content
    },
    participationNumber() {
      let digits = this.totalParticipations.toString().split('')
      let result = ''
      digits.forEach((digit) => {
        result = result + `<span class='digit'>${digit}</span>`
      })
      return result
    },
    camEnded() {
      const campaignEndDate = new Date(
        this.$store.state.campaignEndDate
      ).toLocaleString('en-US', { timeZone: 'Europe/Berlin' })
      const currentTime = new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Berlin'
      })
      const dayInSeconds = 86400 // If we need to include the ending day
      return (
        new Date(campaignEndDate).getTime() / 1000 + dayInSeconds <
        new Date(currentTime).getTime() / 1000
      )
    }
  },
  watch: {
    styles: {
      immediate: true,
      handler() {
        const keys = Object.keys(this.styles)
        const vals = Object.values(this.styles)
        keys.forEach((key, idx) => (this.cssString += key + ':' + vals[idx]))
      }
    },
    classes: {
      immediate: true,
      handler() {
        this.classes.forEach((cls) => {
          this.classString += cls + ' '
        })
      }
    }
  },
  methods: {
    ...mapActions(['checkParticipationCount'])
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_application.scss';

.counterWrapper {
  max-width: calc(75vw - 10%);
  height: 100px;
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid var(--color-1);
  border-radius: 15px;

  @media (max-width: 840px) {
    max-width: 90vw;
    flex-direction: column;
    height: auto;
    padding: 10px;
    justify-content: space-between;
    gap: 20px;
  }

  .logoWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1024px) {
      scale: 0.7;
    }
  }

  .counter {
    flex: 4;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: var(--color-5);
    font-size: 24px;
    font-weight: bold;
    height: 70%;
    border-radius: 15px;

    span:not(.digit) {
      flex: 3;
      text-align: center;
    }

    ::v-deep .digits {
      flex: 2;
      display: flex;
      justify-content: space-evenly;
      height: 120%;
      align-items: center;

      @media (max-width: 1210px) {
        flex-flow: column;
        align-items: center;
        text-align: center;
      }

      .digitWrapper {
        display: flex;
        column-gap: 5px;
      }

      .digit {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 30px;
        line-height: 48px;
        width: 48px;
        height: 48px;
        background-color: #fff9ef;
        color: #303030;
        border-radius: 50%;
        text-align: center;
        line-height: 48px;

        @media (max-width: 1400px) {
          width: 40px;
          height: 40px;
        }

        @media (max-width: 776px) {
          width: 25px;
          height: 25px;
          font-size: 14px;
        }
      }
    }

    @media (max-width: 667px) {
      flex-direction: column;
      gap: 10px;
      font-size: 18px;
      padding: 15px 5px;
    }
  }
}

.digits {
  @media (max-width: 1648px) {
    font-size: 18px;
  }

  @media (max-width: 1300px) {
    font-size: 16px;
  }

  @media (max-width: 667px) {
    font-size: 14px;
  }
}

p {
  outline: 0;
  margin-bottom: 0;
}

.heading {
  margin: 0px 20px;
  color: white;
  text-align: center;
  font: normal normal 500 22px/25px;
  letter-spacing: 0px;
  opacity: 1;
}

@media (max-width: 500px) {
  .heading {
    font-size: 15px;
    line-height: 15px;
    margin: 0px;
  }
}

.digits {
  color: #fff;
}

.AvenirLight {
  font-family: avenir_lt_35_lightregular;
}

.AvenirMedium {
  font-family: avenir_lt_65_mediumregular;
}

.headingNightPad {
  margin: 5px 10px 0 0;
  font-size: 20px;
}
</style>
