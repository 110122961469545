import axios from 'axios'
import { localize } from 'vee-validate'
import { i18n } from '../translate'

const testApi = process.env.VUE_APP_API_URL
const APIUrl = process.env.NODE_ENV !== "development" ? process.env.VUE_APP_API_URL : testApi
const APIUrlv3 = process.env.VUE_APP_API_URL_v3

export default {
  isPublic ({ state }, pageName) {
    if (pageName.toLowerCase() === 'home') return true
    const page = state.pageProperties.pages.find(page => {
      return page.pageName === pageName
    })
    return page && page.isPublic;
  },
  handleError: ({ commit, state }, err = { message: '', code: '99' }) => {
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
      err.code = err.response.status
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(err.request);
    } else {
      // Something happened in setting up the request and triggered an err
      console.log('Unexpected Error', err.message);
    }
    const page = state.pageProperties;
    const errors = page.errors[i18n.locale] || []
    let defaultMsg = errors['default']
    console.error("Error:", err)
    if (err.cause) {
      if (errors[err.cause]) {
        return commit('setModalOptions', { type: err.isInfo ? 'info' : err.goHomeOnClose ? 'goHomeOnCloseError' : 'error', isActive: true, message: errors[err.cause] })
      } else {
        return commit('setModalOptions', { type: err.isInfo ? 'info' : err.goHomeOnClose ? 'goHomeOnCloseError' : 'error', isActive: true, message: defaultMsg })
      }
    } else if (err.customMsg) {
      let message = err.customMsg + "<br>Code:: " + err.code
      return commit('setModalOptions', { type: err.isInfo ? 'info' : err.goHomeOnClose ? 'goHomeOnCloseError' : 'error', isActive: true, message: message })
    } else {
      if (err.code == '404') {
        let msg = defaultMsg + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: err.isInfo ? 'info' : err.goHomeOnClose ? 'goHomeOnCloseError' : 'error', isActive: true, message: msg })
      } else if (err.code == '503') {
        let msg = errors['network'] + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: err.isInfo ? 'info' : err.goHomeOnClose ? 'goHomeOnCloseError' : 'error', isActive: true, message: msg })
      } else if (err.code == '502') {
        let msg = errors['network'] + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: err.isInfo ? 'info' : err.goHomeOnClose ? 'goHomeOnCloseError' : 'error', isActive: true, message: msg })
      } else if (err.message == 'Network Error') {
        let msg = errors['network'] + `<br>Code:: 50X`
        return commit('setModalOptions', { type: err.isInfo ? 'info' : err.goHomeOnClose ? 'goHomeOnCloseError' : 'error', isActive: true, message: msg })
      } else {
        let msg = defaultMsg + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: err.isInfo ? 'info' : err.goHomeOnClose ? 'goHomeOnCloseError' : 'error', isActive: true, message: msg })
      }
    }
  },

  setPageProperties: ({
    commit
  }, properties) => {
    commit('setPageProperties', properties);
  },

  onReceiptUploadSuccess ({ commit }, data) {
    commit('setReceiptImage', data)
  },
  refreshTempId: ({ state, dispatch, commit, getters }) => {
    return axios.post(`${APIUrl}/tempId`, { campaignId: getters.campaignId, jstc: 1 })
      .then(resp => {
        if (resp.data.response) {
          const data = {
            field: 'customerToken',
            value: resp.data.customerToken
          }
          if (!location.pathname.includes("/iban-reupload")) commit('setField', data)
        }
      })
      .catch(err => {
        if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver', goHomeOnClose: true })
        else dispatch('handleError', err)
      })
  },
  checkEligibility: async ({ state, dispatch, commit }, isTesting) => {
    let data = { campaignKey: state.campaignKey };
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("utm_source")) data["utmSource"] = urlParams.get("utm_source");
    else if (urlParams.has("utmSource")) data["utmSource"] = urlParams.get("utmSource");

    if (urlParams.has("utm_medium")) data["utmMedium"] = urlParams.get("utm_medium");
    else if (urlParams.has("utmMedium")) data["utmMedium"] = urlParams.get("utmMedium");

    if (urlParams.has("utm_term")) data["utmTerm"] = urlParams.get("utm_term");
    else if (urlParams.has("utmTerm")) data["utmTerm"] = urlParams.get("utmTerm");

    if (urlParams.has("utm_campaign")) data["utmCampaign"] = urlParams.get("utm_campaign");
    else if (urlParams.has("utmCampaign")) data["utmCampaign"] = urlParams.get("utmCampaign");

    if (urlParams.has("isTest") && isTesting) data["isTest"] = 1 // TODO: Enable this line before go live
    
    try {
      const resp = await axios.post('https://service-manager.jsnp.io/api/checkEligable', data)
      if (resp.data.response) {
        const customerData = {
          field: 'customerToken',
          value: resp.data.customerToken
        }
        if (!location.pathname.includes("/iban-reupload")) commit('setField', customerData)
        const jwtData = {
          field: 'jwtToken',
          value: resp.data.token
        }
        commit('setField', jwtData)
        return true
      }
    } catch(err) {
      if (err.response?.data?.message === 'Campaign is not active or not found') dispatch('handleError', { cause: 'notActive', isInfo: false })
      else if (err.response?.data?.message === 'Bad Request: campaignKey is required') dispatch('handleError', { cause: 'badRequest', isInfo: false })
      else if (err.response?.data?.message === 'Customer has an active session already. Please retry in 15 minutes') dispatch('handleError', { cause: 'alreadyActive', isInfo: false })
      else if (err.response?.data?.message?.includes('Campaign limit reached')) dispatch('handleError', { cause: 'dailyParticipationLimit', isInfo: true })
      else if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver', goHomeOnClose: true })
      else dispatch('handleError', err)
      return false
    }
  },
  setComponentStatus: ({ commit }, { component, status }) => {
    commit('setComponentStatus', { component, status });
  },
  sendSurveyData: ({ state, dispatch }, data) => {
    // console.log("DATA BEFORE SENDING", JSON.parse(JSON.stringify(data)))
    return new Promise((resolve, reject) => {
      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) {
            resolve(res.data)
          } else {
            dispatch('handleError')
            reject(res.data)
          }
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver', goHomeOnClose: true })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },
  sendFormData: ({ state, dispatch }, data) => {
    return new Promise((resolve, reject) => {

      if (window.nsId) {
        data.formData.nsId = window.nsId;
      }
      const API = data.requiresJwtToken ? APIUrl : APIUrlv3
      axios.post(API + data.endpoint, data.formData)
        .then(res => {
          if (data.triggers && data.triggers.neuStarTrigger) {
            // console.log("3. will send::: neutrigger", data.triggers)
            let a = window._agknEchoTag;
            if (a && data.n) {
              a.setBpId('firstp');
              a.addEchoKeyValue('event', 'submit');
              a.generateTag();
            }
          }
          if (res.data.response) {
            return resolve(res.data)
          } else {
            if (res.data.invalid_fields) {
              //janrain error
              if (res.data.invalid_fields[0] == 'Email address is already in use.') {
                dispatch('handleError', { cause: 'emailUsed' })
              } else if (res.data.invalid_fields[0] == 'Incorrect username or password. Please try again.') {
                dispatch('handleError', { cause: 'incorrect' })
              } else if (res.data.invalid_fields[0] == 'No account with that email address exists.') {
                dispatch('handleError', { cause: 'noEmail' })
              } else if (res.data.message && res.data.message == 'authorization_code is not valid') {
                dispatch('handleError', { cause: 'invalidCode' })
              } else {
                dispatch('handleError', { customMsg: res.data.invalid_fields })
              }
            } else if (res.data.message) {
              dispatch('handleError', { customMsg: res.data.message })
            } else {
              dispatch('handleError', { code: res.data })
            }
            return reject(res.data)
          }
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver', goHomeOnClose: true })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },

  uploadProductPhotos ({ state, getters, dispatch }, data) {
    // console.log('data', data)
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('productPhoto', data.image, 'productPhoto.png')
      formData.append('customerToken', data.customerToken ? data.customerToken : getters.customerToken)
      formData.append('campaignId', getters.campaignId ? getters.campaignId : data.campaignId)
      formData.append('receiptToken', data.receiptToken)

      if (formData.get('customerToken') == 1) {
        formData.append('token', data.token || '')
      }

      axios.post(APIUrl + data.endpoint, formData)
        .then(res => {
          return resolve(res.data)
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver', goHomeOnClose: true })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },

  reuploadProductPhotosSM ({ state, getters, dispatch }, data) {
    // console.log('data', data)
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('productPhoto', data.image, 'productPhoto.png')
      formData.append('customerToken', data.customerToken ? data.customerToken : getters.customerToken)
      formData.append('campaignId', getters.campaignId ? getters.campaignId : data.campaignId)
      formData.append('receiptToken', data.receiptToken)

      if (formData.get('customerToken') == 1) {
        formData.append('token', data.token || '')
      }

      axios.post(APIUrlv3 + data.endpoint, formData)
        .then(res => {
          return resolve(res.data)
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver', goHomeOnClose: true })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },

  generateCode ({ dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      data.formData.receiptToken = state.receiptToken
      if (state.jwtToken) data.formData.token = state.jwtToken
      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) {
            return resolve(res.data)
          } else {
            dispatch('handleError', { cause: 'generateCode' })
            return reject(res.data)
          }
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver', goHomeOnClose: true })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },

  verifyCode ({ dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      data.formData.receiptToken = state.receiptToken
      if (state.jwtToken) data.formData.token = state.jwtToken

      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) {
            return resolve(res.data)
          } else {
            dispatch('handleError', { cause: 'wrongCode' })
            return reject(res.data)
          }
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver', goHomeOnClose: true })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },

  sendProducts ({ commit, dispatch, state, getters }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = getters.campaignId
      if (state.jwtToken) data.formData.token = state.jwtToken
      axios.post(APIUrl + data.endpoint, data.formData)
        .then(res => {
          if (res.data.response) {
            resolve(res.data)
          } else {
            dispatch('handleError', { customMsg: res.data.message })
            reject(res.data)
          }
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver', goHomeOnClose: true })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },

  uploadReceipt ({ getters, commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      if (data.receiptToken) {
        formData.append('receiptToken', data.receiptToken)

      }
      formData.append('userToken', data.customerToken)
      formData.append('receipt', data.image, 'receipt.png')
      if (!getters.jwtToken) formData.append('subCampaignId', getters.campaignId)
      formData.append('campaignId', data.campaignId)

      if (data.token) {
        formData.append('token', data.token)
      }
      return axios.post(`${data.apiUrl}`, formData)
        .then(resp => {
          if (resp.data.response !== 'ERR') {
            // If reference number is returned, set reference number
            if (resp.data.referenceNumber) {
              const data = {
                field: 'formData',
                value: { 'referenceNumber': resp.data.referenceNumber }
              }
              commit('setField', data)
            }

            commit('setReceiptToken', resp.data.receiptToken)
            resolve(resp.data.receiptToken);
          } else {
            dispatch('handleError', resp)
          }
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver', goHomeOnClose: true })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },

  getBarCode ({ commit }, code) {
    commit('setBarCode', code)
  },
  handleProductCode ({ commit }, value) {
    commit('setProductCode', value)
  },
  updateSearchStatus ({ commit }, status) {
    commit('setSearchStatus', status)
  },
  updateScanner ({ commit }, status) {
    commit('setScannerStatus', status)
  },
  setItems ({ commit }, array) {
    commit('setItems', array);
  },
  deleteItem ({ commit }, itemId) {
    commit('deleteItem', itemId);
  },
  findProducts ({ commit }, array) {
    commit('setProducts', array)
  },
  removeProduct ({ commit }, productId) {
    commit('deleteProduct', productId)
  },
  editQuantityProduct ({ commit }, { product, action, maxProductCount }) {
    commit('setQuantityProduct', { product, action, maxProductCount })
  },
  editInputQuantityProduct ({ state, commit }, { productId, count, maxProductCount }) {
    if (count === '0') {
      commit('deleteProduct', productId)
    } else {
      if (maxProductCount && count > maxProductCount) {
        // commit('setModalOptions', { type: 'error', isActive: true, message: `Sie können nicht mehr als ${maxProductCount} Produkte auswählen.` });
        commit('setModalOptions', {
          type: 'error', isActive: true, message: state.locale == 'de' ?
            `Sie können nicht mehr als ein Produkt auswählen.` :
            `Vous ne pouvez pas choisir plus d'un produit.`
        });
        commit('setInputQuantityProduct', { productId, count: maxProductCount });
        return
      }
      commit('setInputQuantityProduct', { productId, count })
    }
  },
  searchProductByBarcode ({ state, dispatch, commit }, { barcode, maxProductCount }) {
    if (state.scannedProducts && state.scannedProducts.find(x => {
      return x.ean === barcode
    })) {
      commit('setModalOptions', { type: 'error', isActive: true, message: state.locale == 'de' ? `Sie haben dieses Produkt bereits hinzugefügt.` : 'Vous avez déjà ajouté ce produit.' });
      return false;
    }
    state.currentBarcode = barcode;
    let apiUrl = 'https://template-v2.justsnap.eu/api/searchBarcode';
    let totalPrice = 0;
    for(const product of state.scannedProducts){
      totalPrice += product.price;
    }
    const isAllowed = maxProductCount ? (maxProductCount > state.scannedProducts.length) : true;
    if (/^[0-9]+$/.test(barcode) && isAllowed) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}`, {
            campaignId: state.campaignId,
            barcode: barcode,
            token: state.jwtToken
          })
          .then(res => {
            let status = res.data.result.length > 0 ? 'success' : 'not_found';
            dispatch("updateSearchStatus", status);
            dispatch("findProducts", res.data.result);

            if (status === 'not_found') {
              // The barcode is not P&G
              dispatch('updateScannerErrorMessage', 'Der Barcode scheint nicht zu einem P&G Produkt zu gehören. Glauben Sie, dass es sich um einen Fehler handelt?')
              dispatch('setErrorActivate', true)
            } else {
              dispatch('setErrorActivate', false)
            }
            resolve(res.data);
          })
          .catch(err => {
            if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver', goHomeOnClose: true })
            else dispatch('handleError', err)
            reject(err)
          })
      });
    } else {
      if (!isAllowed) {
        // commit('setModalOptions', { type: 'error', isActive: true, message: `Sie können nicht mehr als ${maxProductCount} Produkte auswählen.` });
        commit('setModalOptions', {
          type: 'error', isActive: true, message: maxProductCount == 1 ? state.locale == 'de' ?
            `Sie können nicht mehr als ein Produkt auswählen.` :
            `Vous ne pouvez pas choisir plus d'un produit.` : state.locale == 'de' ?
            `Sie können nicht mehr als 2 Produkte auswählen.` :
            `Vous ne pouvez pas choisir plus de 2 produits.`
        });
        dispatch("updateSearchStatus", null);
      } else {
        dispatch("updateSearchStatus", 'not_found');
        // The barcode is invalid
        dispatch('updateScannerErrorMessage', 'Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?')
        dispatch('setErrorActivate', true)
      }
    }
  },
  updateScannerErrorMessage ({ commit }, error) {
    commit('setScannerError', error);
  },
  setErrorImages ({ commit }, data) {
    commit('setErrorImages', data)
  },
  setErrorActivate ({ commit }, data) {
    commit('setErrorActivate', data)
  },
  setReceiptBarcode ({ commit }, data) {
    commit('setReceiptBarcode', data)
  },
  setConvertedImagePointsData ({ commit }, data) {
    commit('setConvertedImagePointsData', data)
  },
  isUpdateIban: ({ dispatch, getters }, data) => {
    return new Promise((resolve, reject) => {
      const campaignId = data.campaignId ? data.campaignId : getters.campaignId
      axios.get(`${APIUrl}/ibanUpdateCheck/${data.customerToken}?campaignId=${campaignId}`)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver', goHomeOnClose: true })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },
  isUpdateIbanSM: ({ dispatch, getters }, data) => {
    return new Promise((resolve, reject) => {
      const campaignId = data.campaignId ? data.campaignId : getters.campaignId
      axios.get(`${APIUrlv3}/ibanUpdateCheck/${data.customerToken}?campaignId=${campaignId}`)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          if (err.response?.data?.e === 'TokenExpiredError: jwt expired') dispatch('handleError', { cause: '15minsOver', goHomeOnClose: true })
          else dispatch('handleError', err)
          reject(err)
        })
    })
  },
  setCustomerToken: ({ commit }, data) => {
    commit('setCustomerToken', data)
  },
  checkParticipationCount ({ state, commit, getters }, data) {
    let formData = {};
    if(state.campaignKey){
      formData = {
        campaignKey: state.campaignKey
      }
      return new Promise((resolve, reject) => {
        axios.post('https://service-manager.jsnp.io/api/getRemaining', formData)
          .then(resp => {
            const remaining = {
              field: 'remainingCount',
              value: resp.data.remaining
            }
            commit('setField', remaining)
            resolve(resp.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    }else{
      formData = {
        campaignId: getters.campaignId
      }
      return new Promise((resolve, reject) => {
        axios.post(APIUrl + data.endpoint, formData)
          .then(resp => {
            resolve(resp.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  },
  check72Hours ({ getters }, data) {
    const formData = {}
    formData.receiptToken = data
    formData.campaignId = getters.campaignId
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/check72Hours`, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  check72HoursSM ({ getters }, data) {
    const formData = {}
    formData.receiptToken = data
    formData.campaignId = getters.campaignId
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrlv3}/check72Hours`, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendReclaimToken ({ getters }, data) {
    const formData = {
      campaignId: getters.campaignId,
      receiptToken: data.receiptToken
    }
    return new Promise((resolve, reject) => {
      axios.post(APIUrl + data.endpoint, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  checkDoi ({ getters }, data) {
    const formData = {
      verification_code: data.verification_code,
      campaignId: getters.campaignId
    }
    return new Promise((resolve, reject) => {
      axios.post(APIUrl + data.endpoint, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  uploadError ({ commit, getters }) {
    commit('setModalOptions', { type: 'error', isActive: true, cause: 'manyTries' })
  }
}
