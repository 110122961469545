import { render, staticRenderFns } from "./side-navbar.vue?vue&type=template&id=29b62606&scoped=true"
import script from "./side-navbar.vue?vue&type=script&lang=js"
export * from "./side-navbar.vue?vue&type=script&lang=js"
import style0 from "./side-navbar.vue?vue&type=style&index=0&id=29b62606&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29b62606",
  null
  
)

export default component.exports