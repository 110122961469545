export default {
  isEditMode: true,
  pageProperties: {
    testing: true,
    pages: [
      { // Home Page
        pageName: 'pink-raffle',
        isPublic: true,
        pageId: 100,
        sections: [
          {
            sectionId: 'orbg8s',
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              marginTop: '-5px'
            },
            fields: [
              { // DataLayer
                fieldId: 'dte-003',
                fieldType: 'dataLayer-event',
                properties: {
                  eventName: 'PageView',
                  fbEvent: 'PageView'
                }
              },
              {
                fieldType: 'key-visual',
                fieldId: 'key100',
                properties: {
                  styles: {
                    padding: '0 0 0 0'
                  },
                  noContainer: true,
                  desktopImageUrl: '/assets/campaigns/pink-raffle/banner/desktop-banner.jpg',
                  tabletImageUrl: '/assets/campaigns/pink-raffle/banner/desktop-banner.jpg',
                  mobileImageUrl: '/assets/campaigns/pink-raffle/banner/mobile-banner.jpg'
                }
              },
              { // Steps Card
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    marginTop: '0px'
                  },
                  classes: 'form-top-wrapper form-top-wrapper-2',
                  styles: {
                    background: 'linear-gradient(179.2deg, #FFFFFF 40.65%, #FFEEF2 59.63%, #F5B4C8 76.05%, #F5B4C8 91.96%)'
                  },
                  fields: [
                    { // Campaign steps
                      fieldType: "empty-bucket",
                      fieldId: "dte-001",
                      properties: {
                        grid: {
                          columns: 12,
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: '20px',
                          marginBottom: '20px'
                        },
                        fields: [
                          {
                            fieldType: "campaign-steps",
                            fieldId: "x1p9hx",
                            properties: {
                              grid: {
                                columns: 10,
                                alignItems: "center",
                                justifyContent: "center"
                              },
                              points: [
                                '<div class="campaign-dates">Aktionszeitraum<br><b>01.04.2024<br>-<br>17.08.2024</b></div>',
                                '<p class="campaign-points">Einsendeschluss für das Onlineformular ist der 31.08.2024</p>',
                                '<p class="campaign-points">Die Teilnahme an der Aktion ist beliebig oft möglich.<br>Jeder Kaufbeleg = 1 Gewinnchance!</p>'
                              ],
                              mobilePoints: [
                                '<div class="campaign-dates">Aktionszeitraum<br><b>01.04.2024 - 17.08.2024</b></div>',
                                '<p class="campaign-points">Einsendeschluss für das Onlineformular ist der 31.08.2024</p>',
                                '<p class="campaign-points">Die Teilnahme an der Aktion ist beliebig oft möglich.<br>Jeder Kaufbeleg = 1 Gewinnchance!</p>'
                              ],
                              columnValues: [3, 4, 4],
                              columnValuesMobile: [12, 12, 12]
                            }
                          }
                        ]
                      }
                    },
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'q432vg65',
                      properties: {
                        grid: {
                          columns: '12',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '30px'
                        },
                        classes: 'home-heading text-center',
                        text: `Schritte zur Teilnahme an der Aktion`
                      }
                    },
                    {
                      fieldId: 'fu0923n',
                      fieldType: 'steps-horizontal',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        styles: {
                          width: '220px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                          alignItems: 'center'
                        },
                        mainWrapperClass: 'home-steps',
                        steps: [
                          {
                            icon: {
                              bgStyles: {
                                background: 'transparent',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              },
                              src: '/assets/steps/pink-raffle/basket.png',
                              alt: 'Cart'
                            },
                            arrow: {
                              src: '/assets/steps/arrow-black.png',
                              alt: 'Arrow',
                              styles: {
                                width: '2.4vw',
                                height: '2vw'
                              }
                            },
                            stepText: 'Choose products'
                          },
                          {
                            icon: {
                              bgStyles: {
                                background: 'transparent',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              },
                              src: '/assets/steps/pink-raffle/receipt.png',
                              alt: 'Receipt'
                            },
                            arrow: {
                              src: '/assets/steps/arrow-black.png',
                              alt: 'Arrow',
                              styles: {
                                width: '2.4vw',
                                height: '2vw'
                              }
                            },
                            stepText: 'Upload receipt'
                          },
                          {
                            icon: {
                              bgStyles: {
                                background: 'transparent',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              },
                              src: '/assets/steps/pink-raffle/wheel.png',
                              alt: 'Cashback'
                            },
                            arrow: {
                              src: '/assets/steps/arrow.png',
                              alt: 'Arrow',
                              styles: {
                                width: '2.4vw',
                                height: '2vw'
                              }
                            },
                            stepText: 'Take part in raffle'
                          }
                        ]
                      }
                    },
                    { // Button
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 12,
                          marginTop: '50px',
                          marginBottom: '50px',
                          justifyContent: 'center'
                        },
                        fields: [
                          {
                            fieldType: 'button-component',
                            fieldId: 'x1p9hx',
                            properties: {
                              grid: {
                                columns: '6'
                              },
                              classes: 'btn-primary',
                              text: 'Start raffle',
                              disabled: false,
                              action: [
                                {
                                  fn: 'goToPage',
                                  params: 'pink-raffle/produkt-auswählen'
                                }
                              ],
                              startCampaignButton: true,
                              // isTesting: true // TODO: remove this line before go live
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Product Selection
        pageName: 'pink-raffle/produkt-auswählen',
        locale: 'de',
        pageId: 102,
        sections: [
          { // Container
            sectionId: '98j3bew9jm',
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center'
            },
            fields: [
              { // Step counter
                fieldType: 'empty-bucket',
                fieldId: 'emp1099',
                properties: {
                  grid: {
                    columns: 12,
                    justifyContent: 'center'
                  },
                  fields: [
                    { // Progress Bar
                      fieldType: "step-counter",
                      fieldId: "step100",
                      properties: {
                        grid: {
                          columns: 8
                        },
                        activeIndex: 1,
                        texts: ['Produkt\nkaufen', 'Kaufbeleg\nhochladen', 'Am Gewinnspiel\nteilnehmen']
                      }
                    }
                  ]
                }
              },
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '60px'
                  },
                  classes: 'form-top-wrapper transparentBg maxWidthOverride',
                  fields: [
                    {
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px'
                        },
                        text: 'Scannen Sie das Produkt',
                        classes: 'page-heading text-uppercase overrideMarginBot'
                      }
                    },
                    {
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '50px'
                        },
                        text: 'Bitte geben Sie die Barcodenummer Ihres Produktes ein oder scannen Sie den Barcode. Bitte achten Sie darauf, dass die Barcode-Informationen des Aktionsprodukts gut erkennbar sind.',
                        classes: 'text addPadding page-subheading'
                      }
                    },
                    { // Barcode Scanner
                      fieldType: 'barcode-scanner',
                      fieldId: '56hythe',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        successAction: {
                          fn: 'goToPage',
                          params: 'pink-raffle/kaufbeleg-hochladen'
                        },
                        mainContainerClass: 'pink-raffle-barcode-scanner',
                        backUrl: 'pink-raffle',
                        showModules: 1,
                        iconsObject: {
                          showIcons: true,
                          iconSourceScanner: '/assets/images/scan-icon.svg',
                          iconSourceUploader: '',
                          iconSourceSearch: '/assets/images/write-icon.svg'
                        },
                        classesObject: {
                          nextButton: 'btn-primary col-3',
                          backButton: 'btn-primary col-3',
                          scanButton: 'btn-secondary',
                          searchButton: 'btn-secondary'
                        },
                        readerHeight: 315,
                        readerWidth: 415,
                        texts: {
                          description: 'Bitte geben Sie die Barcode-Nummer Ihres Produkts ein, machen Sie ein Foto oder geben Sie es per Drag & Drop ein. Bitte stellen Sie sicher, dass die Barcode-Informationen für Werbeartikel gut sichtbar sind.',
                          searchButton: 'Hinzufügen',
                          nextButton: 'Weiter',
                          backButton: 'ZURÜCK',
                          barcodePlaceholder: '00000000000000',
                          productScanButton: 'Einscannen',
                          validMessage: 'Der Barcode ist gültig.',
                          invalidMessage: 'Ungültige Barcode-Nummer.',
                          receiptUpload: 'Hochladen',
                          receiptUploadDescription: 'Barcode-Bild per Drag & Drop oder aus Ihrem Geräteordner hochladen',
                          wrongFormat: 'The file format is not supported. It will only .jpeg, .png and .pdf file accepted.',
                          fileSizeLimit: 'The file is too large. Only files up to 16 MB are supported.',
                          notParticipating: 'Der Barcode scheint nicht zu einem P&G Produkt zu gehören. Glauben Sie, dass es sich um einen Fehler handelt?',
                          notParticipatingVideo: 'Leider scheint das Produkt nicht an der Aktion teilzunehmen. Bitte versuchen Sie, den Barcode in das obige Feld einzugeben.',
                          timedOut: 'Leider kann kein gültiger Barcode erkannt werden. Bitte versuchen Sie, den Barcode in das obige Feld einzugeben.',
                          cameraNotFound: 'Bitte schalten Sie Ihre Kamera ein',
                          invalidBarCode: 'Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?',
                          uploaderTopText: 'Upload your receipt photo',
                          uploaderBottomText: 'Drag and drop',
                          scannerTopText: 'Klicken Sie hier und scannen Sie den Barcode',
                          scannerBottomText: ''
                        },
                        errorPageName: 'barcode-error',
                        cameraTimeOut: 20000,
                        maxProductCount: 1
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Upload
        pageName: 'pink-raffle/kaufbeleg-hochladen',
        locale: 'de',
        pageId: 103,
        sections: [
          { // Steps and Header
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center'
            },
            sectionId: 'fu30zq',
            fields: [
              { // Step counter
                fieldType: 'empty-bucket',
                fieldId: 'emp1099',
                properties: {
                  grid: {
                    columns: 12,
                    justifyContent: 'center'
                  },
                  fields: [
                    { // Progress Bar
                      fieldType: "step-counter",
                      fieldId: "step100",
                      properties: {
                        grid: {
                          columns: 8
                        },
                        activeIndex: 2,
                        texts: ['Produkt\nkaufen', 'Kaufbeleg\nhochladen', 'Am Gewinnspiel\nteilnehmen']
                      }
                    }
                  ]
                }
              },
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '60px',
                    padding: '0vw 2vw'
                  },
                  classes: 'form-top-wrapper maxWidthOverride transparentBg',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px'
                        },
                        text: 'Kaufbeleg hochladen',
                        classes: 'page-heading text-uppercase overrideMarginBot'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: 'calc(15px + 2vw)'
                        },
                        text: 'Bitte laden Sie in diesem Schritt den Kaufbeleg Ihres Produktes hoch.',
                        classes: 'page-subheading addPadding'
                      }
                    },
                    { // Receipt uploader
                      fieldType: 'empty-bucket',
                      fieldId: 'h453eadaf',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        styles: {
                          justifyContent: 'center',
                          columnGap: '3vw'
                        },
                        fields: [
                          { // Recipt upload
                            fieldType: 'receipt-processor-SM',
                            fieldId: 'klvakj',
                            properties: {
                              grid: {
                                columns: 3,
                                alignItems: 'center',
                                justifyContent: 'center'
                              },
                              styles: {
                                minHeight: '450px',
                                backgroundColor: '#FFF',
                                border: '2px dashed #E72174',
                                borderRadius: '20px',
                                outline: '2vw solid #FFF'
                              },
                              successAction: {
                                fn: 'goToPage',
                                params: 'pink-raffle/jetzt-registrieren'
                              },
                              texts: {
                                choose: 'Choose a file',
                                upload: 'Hochladen',
                                text1: `Hier klicken, um den Kaufbeleg hochzuladen.`,
                                text2: 'Only JPEG, PNG or PDF. Maximum 16MB',
                                wrongFormat: 'Wrong format receipt',
                                fileSizeLimit: 'File too big'
                              },
                              editTexts: {
                                crop: 'Crop',
                                erase: 'Erase',
                                drag: 'Drag',
                                zoomIn: 'Zoom In',
                                zoomOut: 'Zoom Out'
                              },
                              mainWrapperClass: 'swiffer-receipt-processor',
                              colorSchema: '#828282',
                              phrases: [
                                'Please erase your personal data with the eraser tool.',
                                'Please frame the receipt / invoice in the gray rectangle.'
                              ],
                              cornerDetectionPhrases: [
                                'Please erase your personal data with the eraser tool.',
                                'Please frame the receipt / invoice in the gray rectangle.'
                              ]
                            }
                          },
                          { // Receipt Examples
                            fieldType: 'empty-bucket',
                            properties: {
                              grid: {
                                columns: 6,
                                justifyContent: 'center'
                              },
                              classes: 'receiptUploader',
                              fields: [
                                {
                                  fieldType: 'receipt-information',
                                  fieldId: '5mq1p6',
                                  properties: {
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: '12'
                                    },
                                    mainWrapperClass: 'swiffer-receipt-information',
                                    shouldDisplayFull: true,
                                    descriptionText: `Die folgenden Informationen müssen deutlich lesbar sein.`,
                                    descriptionSubText: `Gefaltete oder eingescannte Belege oder Rechnungen werden nicht akzeptiert.`,
                                    hideExamples: false,
                                    receiptImg: 'receipt-example-pink',
                                    steps: [
                                      { // Retailer
                                        text: 'Retailer / Shop',
                                        label: 'A'
                                      },
                                      {
                                        text: 'Campaign product and its price',
                                        label: 'B'
                                      },
                                      {
                                        text: 'Total sum of the receipt / invoice and applied VAT',
                                        label: 'C'
                                      },
                                      {
                                        text: 'Date and time of purchase',
                                        label: 'D'
                                      },
                                      {
                                        text: 'Receipt / Invoice ID (not the bank transaction ID)',
                                        label: 'E'
                                      }
                                    ],
                                    texts: {
                                      correct: "Gültig",
                                      notReadabe: "Schwer zu lesen",
                                      folded: "Zerknittert",
                                      cropped: "Abgeschnitten"
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: '12',
                          marginTop: 'calc(30px + 2vw)',
                          marginBottom: '30px'
                        },
                        classes: 'btn-bucket',
                        fields: [
                          { // Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: 'Back',
                              classes: 'btn-primary mx-0',
                              action: [
                                {
                                  fn: 'goToPage',
                                  params: 'pink-raffle/produkt-auswählen'
                                }
                              ],
                              arrow: {
                                prev: true,
                                color: '#FFF'
                              }
                            }
                          },
                          { // Next Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: 'Next',
                              classes: 'btn-primary mx-0',
                              requires: ['klvakj'],
                              action: {
                                fn: 'sendReceipt',
                                params: ''
                              },
                              arrow: {
                                next: true,
                                color: '#FFF'
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Registration
        pageName: 'pink-raffle/registrieren',
        locale: 'de',
        pageId: 107,
        sections: [
          { // Steps and Heading
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              columns: '12'
            },
            fields: [
              { // Step counter
                fieldType: 'empty-bucket',
                fieldId: 'emp1099',
                properties: {
                  grid: {
                    columns: 12,
                    justifyContent: 'center'
                  },
                  fields: [
                    { // Progress Bar
                      fieldType: "step-counter",
                      fieldId: "step100",
                      properties: {
                        grid: {
                          columns: 8
                        },
                        activeIndex: 3,
                        texts: ['Produkt\nkaufen', 'Kaufbeleg\nhochladen', 'Am Gewinnspiel\nteilnehmen']
                      }
                    }
                  ]
                }
              },
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '60px',
                    padding: '4vw 2vw'
                  },
                  classes: 'form-top-wrapper maxWidthOverride transparentBg',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px'
                        },
                        text: '<span style="display: inline-block; height: 28px; width: 93.88px; position: relative;"><img src="/assets/images/forme-logo.png" alt="ForMe" style="height: 28px; position: absolute; top: 3.2px; left: 0;"></span> Mitglied werden & vorteile sichern!',
                        classes: 'page-heading text-uppercase overrideMarginBot'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '50px'
                        },
                        text: 'Als ForMe-Mitglied können Sie schneller & einfacher an zukünftigen Geld-Zurück-Aktionen teilnehmen. Außerdem finden Sie bei ForMe weitere spannende Gratis-Testen-Aktionen und Gewinnspiele von verschiedenen Marken und Produkten. <u>Unser Tipp:</u> Newsletter abonnieren & keine Aktion mehr verpassen!',
                        classes: 'page-subheading addPadding'
                      }
                    },
                    { // Form
                      fieldType: 'form-component',
                      fieldId: 'inzf4w',
                      properties: {
                        styles: {
                          margin: '0',
                          alignItems: 'center',
                          flexDirection: 'column'
                        },
                        class: 'col-sm-12',
                        grid: {
                          columns: '8',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '50px'
                        },
                        requires: ['1'],
                        successAction: {
                          fn: 'goToPage',
                          params: 'pink-raffle/registrierung-abgeschlossen'
                        },
                        successState: [
                          {
                            state: 'referenceNumber',
                            form: 'referenceNumber'
                          }
                        ],
                        endpoint: '/register',
                        fields: [
                          {
                            //form components
                            fieldType: 'empty-bucket',
                            fieldId: '4h3gweb',
                            properties: {
                              grid: {
                                columns: 12
                              },
                              styles: {
                                background: '#FFF',
                                boxShadow: '0px 8px 32px -3px rgba(194, 185, 224, 0.5)',
                                borderRadius: '24px',
                                paddingTop: '50px',
                                paddingBottom: '50px',
                                margin: 0
                              },
                              class:
                                'col-md-12 col-xl-12 d-flex flex-row justify-content-between px-0 px-sm-5',
                              fields: [
                                { // Headin 1
                                  fieldType: 'paragraph',
                                  fieldId: 'yh54weg',
                                  properties: {
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: 12
                                    },
                                    text: "Über Sie",
                                    class: 'form-heading mb-0'
                                  }
                                },
                                { // Uber Sie components
                                  fieldType: 'empty-bucket',
                                  fieldId: '4h3gwebz',
                                  properties: {
                                    grid: {
                                      columns: 12
                                    },
                                    class:
                                      'd-flex flex-row justify-content-between',
                                    fields: [
                                      {
                                        //Name
                                        fieldId: 'b4baqs',
                                        fieldType: 'text-input',
                                        properties: {
                                          name: 'firstName',
                                          label: 'Vorname*',
                                          placeholder: '',
                                          text: '',
                                          borderColor: '#66707E',
                                          validation:
                                            'required|namesWithHyphensAndSpaces',
                                          grid: {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            columns: 6
                                          },
                                          styles: {
                                            marginTop: '50px'
                                          }
                                        }
                                      },
                                      {
                                        //surname
                                        fieldId: 'gofije',
                                        fieldType: 'text-input',
                                        properties: {
                                          name: 'lastName',
                                          label: 'Nachname*',
                                          placeholder: '',
                                          text: '',
                                          validation:
                                            'required|namesWithHyphensAndSpaces',
                                          borderColor: '#66707E',
                                          grid: {
                                            columns: 6
                                          },
                                          styles: {
                                            marginTop: '50px'
                                          }
                                        }
                                      },
                                      { // Land
                                        fieldId: '2398ujfds',
                                        fieldType: 'select-input',
                                        properties: {
                                          grid: {
                                            columns: 6
                                          },
                                          name: 'gender',
                                          label: 'Anrede',
                                          borderColor: '#66707E',
                                          color: '#1D222A',
                                          placeholder: 'Anrede',
                                          text: 'other',
                                          validation: '',
                                          selectedValue: 'other',
                                          options: [
                                            {
                                              value: 'male',
                                              text: 'Herr'
                                            },
                                            {
                                              value: 'female',
                                              text: 'Frau'
                                            },
                                            {
                                              value: 'miscellaneous',
                                              text: 'Divers'
                                            },
                                            {
                                              value: 'other',
                                              text: 'Keine Angabe'
                                            }
                                          ],
                                          styles: {
                                            marginTop: '50px'
                                          }
                                        }
                                      },
                                      { // Birthdate
                                        fieldId: 'godate',
                                        fieldType: 'date-input',
                                        properties: {
                                          name: 'birthdate',
                                          label: 'Geburtsdatum',
                                          validation: '',
                                          borderColor: '#66707E',
                                          grid: {
                                            columns: 6
                                          },
                                          params: {
                                            disabledDates: {
                                              from: '18years',
                                              to: '01.01.1800'
                                            }
                                          },
                                          styles: {
                                            margin: '50px 10px',
                                            borderRadius: '16px'
                                          }
                                        }
                                      },
                                      { // Email
                                        fieldId: 'h7kzf9',
                                        fieldType: 'text-input',
                                        properties: {
                                          name: 'email',
                                          label: 'Email Adresse*',
                                          placeholder: '',
                                          text: '',
                                          borderColor: '#66707E',
                                          validation: 'required|email',
                                          grid: {
                                            columns: 6
                                          },
                                          class: 'mb-4 mb-sm-0'
                                        }
                                      },
                                      { // Password
                                        fieldId: 'sgol2x',
                                        fieldType: 'text-input',
                                        properties: {
                                          name: 'password',
                                          label: 'Passwort*',
                                          inputType: 'password',
                                          placeholder: '',
                                          borderColor: '#66707E',
                                          text: '',
                                          validation: 'required|password',
                                          grid: {
                                            columns: 6
                                          },
                                          class: 'mt-4 mt-sm-0'
                                        }
                                      },
                                      { // Uber Sie components
                                        fieldType: 'empty-bucket',
                                        fieldId: '4h3gwebz',
                                        properties: {
                                          grid: {
                                            columns: 12
                                          },
                                          class: 'd-flex justify-content-start justify-content-sm-end p-0',
                                          fields: [
                                            { // Password Instructions
                                              fieldType: 'paragraph',
                                              fieldId: 'uwxo2o',
                                              properties: {
                                                grid: {
                                                  columns: 6
                                                },
                                                text: `Das Passwort muss mindestens 8 Zeichen lang sein, mindestens einen Großbuchstaben (A-Z), einen Kleinbuchstaben (a-z) und eine Zahl (0-9) enthalten.`,
                                                class: 'text-left text-lg-right p-0 mt-2',
                                                styles: {
                                                  fontSize: '13px;',
                                                  paddingLeft: '30px !important'
                                                }
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                },
                                { // Headin 2
                                  fieldType: 'paragraph',
                                  fieldId: 'yh54weg',
                                  properties: {
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: 12
                                    },
                                    text: "Ihre Adresse",
                                    class: 'form-heading form-border-heading mb-0'
                                  }
                                },
                                { // Address components
                                  fieldType: 'empty-bucket',
                                  fieldId: '4h3gwxsa',
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginTop: '20px',
                                      marginBottom: '20px'
                                    },
                                    class:
                                      'd-flex flex-row justify-content-between',
                                    fields: [
                                      {
                                        //Street & houseNo
                                        fieldId: 'b5q97u',
                                        fieldType: 'text-input',
                                        properties: {
                                          name: 'strasse',
                                          label: 'Straße*',
                                          placeholder: '',
                                          text: '',
                                          validation: 'required',
                                          borderColor: '#66707E',
                                          grid: {
                                            columns: 6
                                          },
                                          styles: {
                                            marginTop: '50px'
                                          }
                                        }
                                      },
                                      { // Hausnr
                                        fieldId: 'ql6mu5',
                                        fieldType: 'text-input',
                                        properties: {
                                          name: 'hausnr',
                                          borderColor: '#66707E',
                                          label: 'Nr.',
                                          placeholder: '',
                                          text: '',
                                          validation: '',
                                          grid: {
                                            columns: 3
                                          },
                                          styles: {
                                            marginTop: '50px'
                                          }
                                        }
                                      },
                                      { // PLZ
                                        fieldId: 'ql6mfadu5',
                                        fieldType: 'text-input',
                                        properties: {
                                          name: 'plz',
                                          borderColor: '#66707E',
                                          label: 'PLZ*',
                                          placeholder: '',
                                          text: '',
                                          validation: 'required|postal:@land',
                                          grid: {
                                            columns: 3
                                          },
                                          dependOfCountry: true,
                                          styles: {
                                            marginTop: '50px'
                                          }
                                        }
                                      },
                                      { // City
                                        fieldId: 'jdtxik',
                                        fieldType: 'text-input',
                                        properties: {
                                          name: 'ort',
                                          label: 'Ort*',
                                          borderColor: '#66707E',
                                          placeholder: '',
                                          text: '',
                                          validation: 'required',
                                          grid: {
                                            columns: 6
                                          },
                                          styles: {
                                            marginTop: '50px'
                                          }
                                        }
                                      },
                                      { // Land
                                        fieldId: 'x3d8oz',
                                        fieldType: 'select-input',
                                        properties: {
                                          name: 'land',
                                          label: 'Land*',
                                          borderColor: '#66707E',
                                          color: '#1D222A',
                                          placeholder: 'Land*',
                                          text: '',
                                          validation: 'required',
                                          grid: {
                                            columns: 6
                                          },
                                          options: [
                                            {
                                              text: 'Deutschland',
                                              value: 'Germany'
                                            },
                                            {
                                              text: 'Österreich',
                                              value: 'Austria'
                                            }
                                          ],
                                          selectedValue: '',
                                          styles: {
                                            marginTop: '50px'
                                          }
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                          { // ROW BUCKET
                            fieldType: 'empty-bucket',
                            fieldId: 'row-bucket-8',
                            properties: {
                              grid: {
                                columns: '12'
                              },
                              classes: 'form-column-bucket mt-4',
                              fields: [
                                {
                                  fieldType: 'checkbox',
                                  fieldId: 'wgc2j7',
                                  properties: {
                                    id: 'campaignTermsAccepted',
                                    name: 'campaignTermsAccepted',
                                    validation: {
                                      mandatory: {
                                        allowFalse: false
                                      }
                                    },
                                    styles: {
                                      color: '#4F4F4F'
                                    },
                                    colorSchema: 'var(--color-4)',
                                    grid: {
                                      columns: '12',
                                      paddingTop: '10px'
                                    },
                                    label: `Ich habe die <a href='/pink-raffle/teilnahmebedingungen' target="_blank" style='color: #FFF;'><b><u>Teilnahmebedingungen</u></b></a> dieser Aktion gelesen und akzeptiere diese.`
                                  }
                                },
                                {
                                  fieldType: 'checkbox',
                                  fieldId: 'wgc3j7',
                                  properties: {
                                    id: 'terms',
                                    name: 'terms',
                                    validation: {
                                      mandatory: {
                                        allowFalse: false
                                      }
                                    },
                                    grid: {
                                      columns: '12'
                                    },
                                    styles: {
                                      color: '#4F4F4F'
                                    },
                                    colorSchema: 'var(--color-4)',
                                    label: `Ich bestätige, dass ich über 18 Jahre alt bin und die <a style='color: #FFF;' href="https://www.pg.com/de_DE/terms_conditions/index.shtml" target="_blank"><b><u>P&G Geschäftsbedingungen</u></b></a>, <a href='https://privacypolicy.pg.com/de/' target="_blank" style='color: #FFF'><b><u>P&G Datenschutzrichtlinie</u></b></a> & <a href='https://justsnap.de/Datenschutz/' target="_blank" style='color: #FFF'><b><u>Justsnap Datenschutzrichtlinien</u></b></a> akzeptiere.
                                    <br>Sie können Ihre <u class='modalOpener' data-text='Wie in unserer Datenschutzerklärung erläutert, dürfen Sie auf die personenbezogenen Daten, die wir über Sie besitzen, zugreifen, sie berichtigen, löschen, an einen anderen Anbieter übertragen, unsere Verarbeitung ablehnen oder einschränken oder Ihre Zustimmung entziehen.'><span onclick="return false;"><b>Datenschutzrechte</b></span></u> ausüben oder Ihre Einstellungen und Abonnements im Bereich <a href='https://preferencecenter.pg.com/de-de/' target='_blank' style='color: #fff;'>Meine Anfrage zum Datenschutz</a> ändern.`
                                  }
                                },
                                {
                                  fieldType: 'checkbox',
                                  fieldId: 'psyn1c',
                                  properties: {
                                    id: 'receiveEmails',
                                    name: 'receiveEmails',
                                    grid: {
                                      columns: '12'
                                    },
                                    validation: {
                                      required: {
                                        allowFalse: true
                                      }
                                    },
                                    styles: {
                                      color: '#4F4F4F'
                                    },
                                    colorSchema: 'var(--color-4)',
                                    label: `<div><p><span>Bleiben Sie mit uns in Kontakt!</span></p></div>
                                    Ich bin damit einverstanden, personalisierte Mitteilungen über Angebote, Neuigkeiten und andere Werbeinitiativen von ForMe und anderen <a style="color: #FFF;" href="https://de.pg.com/marken-und-produkte/" target="_blank"><b><u>P&G-Marken</u></b></a> per E-Mail und über Online-Kanäle zu erhalten. Ich kann die Mitteilungen jederzeit wieder <a style="color: #FFF;" href="https://preferencecenter.pg.com/de-de/datarequests/" target="_blank"><b><u>abbestellen</u></b></a>.
                                    <br><br>
                                    Procter & Gamble verarbeitet als Datenverantwortlicher Ihre personenbezogenen Daten, um Ihnen zu ermöglichen, sich auf dieser Website zu registrieren, mit deren Diensten zu interagieren und, sofern Sie zustimmen, Ihnen relevante werbliche Mitteilungen zukommen zu lassen, darunter auch personalisierte Werbebotschaften in den Online-Medien.
                                    <br><br>
                                    <u class="modalOpener" data-text='
                                    <div>
                                      <b>*Mehr lesen</b><br>
                                      Relevante Werbeinformationen und personalisierte Online-Werbebotschaften
                                      <br><br>
                                      Unser Ziel ist es, Ihren Kontakt mit uns und den Marken der P&G Familie interessant, angenehm und persönlich zu gestalten. Dabei versuchen wir, unsere werblichen Mitteilungen nach Ihren Vorlieben abzustimmen und vermeiden es, Ihnen Vorschläge zu schicken, die nicht Ihrem Geschmack entsprechen, oder Ihnen Werbebotschaften zukommen zu lassen, die Ihnen bereits mehrfach angezeigt wurden. Wie machen wir das?
                                      <br><br>
                                      <ul>
                                        <li>Wir kombinieren Daten, um unsere Mitteilungen besser auf Ihre Interessen abzustimmen. Wir können auf der Grundlage unseres berechtigten Interesses sowohl Ihre Registrierungsdaten, sowie auch die Daten, die Sie uns bei der Interaktion mit unseren Diensten offenlegen (wie Markenpräferenzen, ausgefüllte Coupons usw.), mit weiteren Attributen, Interessen und demografischen Daten anreichern, die wir aus geschäftlich verfügbaren Quellen oder von anderen Drittparteien erhalten. Ebenso können wir, wenn Sie unsere zielgerichteten Werbe-Cookies oder ähnliche Technologien auf den Websites oder in den Apps von P&G, oder in einigen Fällen auch auf Webseiten oder in Apps von Dritten, akzeptiert haben, die durch unsere zielgerichteten Werbe-Cookies erhobenen Daten auch mit weiteren Informationen ergänzen, die Sie uns bei der Erstellung eines Kontos oder bei der Interaktion mit unseren Diensten zur Verfügung stellen. Wir verarbeiten diese Daten dabei auch mit Hilfe automatisierter Verfahren, um unsere Inhalte und E-Mail-Angebote online (z. B. auf Plattformen Dritter, darunter auch Social Media-Plattformen) wie auch offline (z. B. per Briefpost) zu personalisieren, sofern Sie dem Erhalt geschäftlicher Mitteilungen von uns zugestimmt haben.</li>
                                        <br>
                                        <li>Lookalike-Targeting. Auf der Grundlage unseres berechtigten Interesses analysieren wir Ihre Daten auch, um neue Interessenten zu identifizieren, die ein ähnliches Profil wie Sie haben und sich für eine Kontaktaufnahme mit uns interessieren könnten. Wir und die mit P&G verbundenen Unternehmen können hierfür z. B. Ihre verschlüsselte E-Mail-Adresse in Facebook hochladen und Facebook darum bitten, unsere Werbeanzeigen auf der Grundlage von Daten, die über Sie und andere Personen vorliegen, für weitere Personen anzuzeigen, die ähnliche Interessen haben wie Sie, sogar in anderenLändern.</li>
                                        <br>
                                        <li>Verwendung Ihrer personenbezogenen Daten, um Sie in den Online-Medien zu adressieren. Wenn Sie eingewilligt haben, werbliche Mitteilungen von uns zu erhalten, werden wir Ihre verschlüsselten personenbezogenen Daten (d. h. Ihre E-Mail-Adresse, Telefonnummer oder Ihre Mobile Advertising-ID) verwenden, um Ihnen Werbebotschaften in den Online-Kanälen (z. B. auf Ihrem Social-Media-Konto) zukommen zu lassen. Wir tun dies mit Hilfe eines Verfahrens, das Ihre Privatsphäre respektiert, indem wir verschlüsselte Daten und sorgfältig ausgewählte Partner verwenden. Insbesondere geben wir Ihre personenbezogenen Daten nur an Dritte weiter, die uns eigens darauf zugeschnittene Werbedienste zur Verfügung stellen, darunter auch die Social-Media-Plattformen. Wir verwenden solche Dienste, um die von uns gespeicherten Hash-Daten mit den personenbezogenen Daten in deren Datenbank abzugleichen, um individuelle Zielgruppen zu erstellen und Werbebotschaften auf Ihre Interessen im Internet zuzuschneiden, darunter auch der Social Media-Plattformen, soweit dies nach geltendem Recht zulässig ist.</li>
                                      </ul>
                                      Das hilft uns, Ihnen relevantere Online-Werbebotschaften (Angebote, Informationen über Produkte und Innovationen) zukommen zu lassen, und Ihnen weniger Anzeigenwerbung zu zeigen, da wir erfahren, ob Ihnen eine bestimmte Werbebotschaft bereits zugesendet wurde, und ob wir Sie bei weiteren Werbebotschaften zu demselben Thema ausschließen können. Die meisten unserer Verbraucher wissen es zu schätzen, dass wir ihnen keine Werbeanzeigen für Produkte anzeigen lassen, die sie nicht interessieren oder die wir ihnen zuvor schon oft angezeigt haben, aber wenn Ihnen das nicht wichtig ist, ist das auch kein Problem, da Sie Ihre Präferenzen jederzeit ändern können. Sie können jederzeit Ihre Meinung ändern und die Zustimmung zur Verwendung Ihrer Daten für personalisierte Werbebotschaften und Anzeigenwerbung, darunter auch die Erstellung von benutzerdefinierten Zielgruppen („Custom Audiences“) und Lookalike-Zielgruppen, <a
                                        href="https://privacypolicy.pg.com/de-DE/"
                                        target="_blank"
                                      ><u>hier</u></a> widerrufen. Lesen Sie unsere Datenschutzrichtlinie.
                                    </div>'>Mehr lesen*</u>. Weitere Informationen über die Verarbeitung Ihrer Daten und Ihre Datenschutzrechte finden Sie <u class="modalOpener" data-text='
                                    <div>
                                      <b>**Wir legen Wert auf Ihre Privatsphäre</b><br>
                                      <div>
                                        <ul>
                                          <li>
                                            Unsere <a
                                              href="https://privacypolicy.pg.com/de-DE/"
                                              target="https://privacypolicy.pg.com/de-DE/"
                                            >Datenschutzrichtlinie</a> regelt die Erfassung und Verwendung Ihrer Daten.
                                          </li>
                                          <br>
                                          <li>Das ist der Grund, warum wir Ihre Daten erheben, unsere Rechtsgrundlage für deren Verarbeitung und der Zeitraum, für den Ihre personenbezogenen Daten speichern werden.</li>
                                          <br>
                                          <li>
                                            Unsere E-Mails enthalten Pixel, die P&G helfen zu verstehen, ob Sie mit ihnen interagieren und welche Inhalte bisher für Sie interessant waren. Erfahren Sie mehr dazu <a
                                              href="https://privacypolicy.pg.com/de-DE/"
                                              target="_blank"
                                            ><u>hier</u></a>. Sie können sich von unseren Programmen für E-Mail-Mitteilungen jederzeit <a
                                              href="https://privacypolicy.pg.com/de-DE/"
                                              target="_blank"
                                            ><u>abmelden</u></a>.
                                          </li>
                                          <br>
                                          <li>
                                            Ihre personenbezogenen Daten können in einem anderen Land verarbeitet werden, als dem Land, in dem sie erhoben wurden, darunter auch in den USA. Erfahren Sie mehr dazu <a
                                              href="https://privacypolicy.pg.com/de-DE/"
                                              target="_blank"
                                            ><u>hier</u></a>.
                                          </li>
                                          <br>
                                          <li>
                                            Sie können Ihre Betroffenen Rechte ausüben (also auf Ihre personenbezogenen Daten zugreifen, sie berichtigen, löschen, an einen anderen Anbieter übermitteln lassen, der Verarbeitung Ihrer Daten widersprechen oder diese einschränken oder Ihre Einwilligung widerrufen) oder Ihre Präferenzen und Abonnements ändern, indem Sie <a
                                              href="https://privacypolicy.pg.com/de-DE/"
                                              target="_blank"
                                            ><u>hier</u></a> klicken.
                                          </li>
                                        </ul>
                                      </div>
                                    </div>'>hier**</u> oder in unserer vollständigen <a style="color: #FFF;" href="https://privacypolicy.pg.com/de-DE" target="_blank"><b><u>Datenschutzrichtlinie</u></b></a>. Sie sind mindestens 18 Jahre alt, haben die Datenschutzrichtlinien zur Kenntnis genommen und stimmen unseren <a style="color: #FFF;" href="https://termsandconditions.pg.com/de-de/" target="_blank"><b><u>Geschäftsbedingungen</u></b></a> zu.`
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: '12',
                          marginBottom: '30px',
                          rowGap: '20px'
                        },
                        class: 'd-flex justify-content-center align-items-center flex-column-reverse flex-md-row custom-height',
                        fields: [
                          { // Next Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 2
                              },
                              text: 'Zurück',
                              classes: 'btn-primary col-md-3 col-sm-8 col-11 h-unset h-md-100 my-0',
                              action: {
                                fn: 'goToPage',
                                params: 'pink-raffle/kaufbeleg-hochladen'
                              },
                              arrow: {
                                prev: true,
                                color: '#FFF'
                              }
                            }
                          },
                          { // Next Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 5
                              },
                              text: 'REGISTRIERUNG ABSCHLIEßEN & VORTEILE SICHERN',
                              classes: 'btn-primary col-sm-8 col-11 h-unset h-md-100 my-0 btn-no-upper',
                              action: {
                                fn: 'sendForm',
                                params: 'inzf4w'
                              },
                              arrow: {
                                next: true,
                                color: '#FFF'
                              }
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 5,
                          marginBottom: '40px',
                          marginTop: '40px'
                        },
                        class: 'col-sm-12 col-md-10 col-lg-5 justify-content-center align-items-center',
                        styles: {
                          backgroundColor: '#FFF',
                          boxShadow: '0px 8px 32px -3px rgba(194, 185, 224, 0.5)',
                          padding: '20px 10px 20px 10px'
                        },
                        fields: [
                          {
                            fieldType: 'paragraph',
                            fieldId: 'fckzg7',
                            properties: {
                              text: 'Sie sind bereits <span style="display: inline-block; height: 18px; position: relative; width: 60.35px;"><img src="/assets/images/forme-logo.png" alt="ForMe" style="height: 18px; width: auto; position: absolute; top: 2.6px; left: 0;"></span> Mitglied? <span class="modalOpener" data-text="ForMe ist eine Online-Plattform von Procter & Gamble, die entwickelt wurde, um Familien mit Tipps und Tricks zur Seite zu stehen. Das Leben muss dabei nicht perfekt sein – wir von ForMe wissen aus eigener Erfahrung, dass das unmöglich ist. Unsere Mission ist es vielmehr, die kleinen Momente zu feiern, die Stärke in den eigenen vermeintlichen Schwächen zu finden und den Alltag etwas einfacher zu gestalten. Wir bieten dir handverlesene Angebote sowie neue Produkte zum Testen deiner Lieblingsmarken von P&G. Außerdem liefern wir dir praktische Tipps, inspirierende Geschichten und verlässliche Empfehlungen zu allen Themen rund um Familie, Haus & Garten, Gesundheit & Wellness sowie Beauty. Damit du alle Infos zuerst erhältst, kannst du dich hier für unseren Newsletter anmelden und Teil der ForMe-Community werden. Schau auch auf Instagram oder Facebook vorbei!" style="font-size: 12px; color: darkblue; vertical-align: middle; text-decoration: none;">&#9432;</span>',
                              classes: ['col-12'],
                              grid: {
                                alignItems: 'center',
                                justifyContent: 'center',
                                columns: 12
                              },
                              styles: {
                                fontSize: '22px',
                                fontWeight: '600',
                                textAlign: 'center',
                                margin: 0,
                                padding: 0
                              }
                            }
                          },
                          { // Login Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 12
                              },
                              classes: 'btn-primary',
                              text: 'Anmelden<br>& schneller teilnehmen',
                              action: {
                                fn: 'goToPage',
                                params: 'pink-raffle/anmelden'
                              }
                            }
                          },
                          { // Next Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 12
                              },
                              text: 'Als Gast fortfahren',
                              action: {
                                fn: 'goToPage',
                                params: 'pink-raffle/jetzt-registrieren'
                              },
                              classes: 'btn-primary'
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Guest Registration
        pageName: 'pink-raffle/jetzt-registrieren',
        locale: 'de',
        pageId: 108,
        sections: [
          { // Steps and Heading
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: '12'
            },
            fields: [
              { // Step counter
                fieldType: 'empty-bucket',
                fieldId: 'emp1099',
                properties: {
                  grid: {
                    columns: 12,
                    justifyContent: 'center'
                  },
                  fields: [
                    { // Progress Bar
                      fieldType: "step-counter",
                      fieldId: "step100",
                      properties: {
                        grid: {
                          columns: 8
                        },
                        activeIndex: 3,
                        texts: ['Produkt\nkaufen', 'Kaufbeleg\nhochladen', 'Am Gewinnspiel\nteilnehmen']
                      }
                    }
                  ]
                }
              },
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '60px',
                    padding: '4vw 2vw'
                  },
                  classes: 'form-top-wrapper maxWidthOverride transparentBg',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px'
                        },
                        text: 'INFORMATIONSFELDER AUSFÜLLEN UND AM GEWINNSPIEL TEILNEHMEN',
                        classes: 'page-heading text-uppercase overrideMarginBot'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '50px'
                        },
                        text: 'Bitte füllen Sie nachstehendes Formular aus, um am Gewinnspiel teilzunehmen. Falls Sie wünschen, können Sie sich weiter unten bei ForMe registrieren oder anmelden, dies hat keinerlei Einfluss auf Ihre Teilnahme an der Aktion. Als ForMe-Mitglied können Sie schneller & einfacher an zukünftigen Aktionen teilnehmen. Außerdem finden Sie bei ForMe weitere spannende Gewinnspiele und Gratis-Testen-Aktionen von verschiedensten Marken und Produkten.',
                        classes: 'page-subheading addPadding'
                      }
                    },
                    { // Form
                      fieldType: 'form-component',
                      fieldId: 'inzf4waa',
                      properties: {
                        styles: {
                          margin: '0',
                          alignItems: 'center',
                          flexDirection: 'column'
                        },
                        class: 'col-sm-12',
                        grid: {
                          columns: '8',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '50px'
                        },
                        requires: ['1'],
                        successAction: {
                          fn: 'goToPage',
                          params: 'pink-raffle/registrierung-abgeschlossen'
                        },
                        successState: [
                          {
                            state: 'referenceNumber',
                            form: 'referenceNumber'
                          }
                        ],
                        endpoint: '/registerAsGuest',
                        fields: [
                          {
                            //form components
                            fieldType: 'empty-bucket',
                            fieldId: '4h3gweb',
                            properties: {
                              grid: {
                                columns: 12
                              },
                              styles: {
                                background: '#FFF',
                                boxShadow: '0px 8px 32px -3px rgba(194, 185, 224, 0.5)',
                                borderRadius: '24px',
                                paddingTop: '50px',
                                paddingBottom: '50px',
                                margin: 0
                              },
                              class:
                                'col-md-12 col-xl-12 d-flex flex-row justify-content-between px-0 px-sm-5',
                              fields: [
                                { // Headin 1
                                  fieldType: 'paragraph',
                                  fieldId: 'yh54weg',
                                  properties: {
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: 12
                                    },
                                    text: "Über Sie",
                                    class: 'form-heading mb-0'
                                  }
                                },
                                { // Uber Sie components
                                  fieldType: 'empty-bucket',
                                  fieldId: '4h3gwebz',
                                  properties: {
                                    grid: {
                                      columns: 12
                                    },
                                    class:
                                      'd-flex flex-row justify-content-between',
                                    fields: [
                                      {
                                        //Name
                                        fieldId: 'b4baqs',
                                        fieldType: 'text-input',
                                        properties: {
                                          name: 'firstName',
                                          label: 'Vorname*',
                                          placeholder: '',
                                          text: '',
                                          borderColor: '#66707E',
                                          validation:
                                            'required|namesWithHyphensAndSpaces',
                                          grid: {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            columns: 6
                                          },
                                          styles: {
                                            marginTop: '50px'
                                          }
                                        }
                                      },
                                      {
                                        //surname
                                        fieldId: 'gofije',
                                        fieldType: 'text-input',
                                        properties: {
                                          name: 'lastName',
                                          label: 'Nachname*',
                                          placeholder: '',
                                          text: '',
                                          validation:
                                            'required|namesWithHyphensAndSpaces',
                                          borderColor: '#66707E',
                                          grid: {
                                            columns: 6
                                          },
                                          styles: {
                                            marginTop: '50px'
                                          }
                                        }
                                      },
                                      { // Land
                                        fieldId: '2398ujfds',
                                        fieldType: 'select-input',
                                        properties: {
                                          grid: {
                                            columns: 6
                                          },
                                          name: 'gender',
                                          label: 'Anrede',
                                          borderColor: '#66707E',
                                          color: '#1D222A',
                                          placeholder: 'Anrede',
                                          text: 'other',
                                          validation: '',
                                          selectedValue: 'other',
                                          options: [
                                            {
                                              value: 'male',
                                              text: 'Herr'
                                            },
                                            {
                                              value: 'female',
                                              text: 'Frau'
                                            },
                                            {
                                              value: 'miscellaneous',
                                              text: 'Divers'
                                            },
                                            {
                                              value: 'other',
                                              text: 'Keine Angabe'
                                            }
                                          ],
                                          styles: {
                                            marginTop: '50px'
                                          }
                                        }
                                      },
                                      { // Email
                                        fieldId: 'h7kzf9',
                                        fieldType: 'text-input',
                                        properties: {
                                          name: 'email',
                                          label: 'Email Adresse*',
                                          placeholder: '',
                                          text: '',
                                          borderColor: '#66707E',
                                          validation: 'required|email',
                                          grid: {
                                            columns: 6
                                          },
                                          styles: {
                                            marginTop: '50px'
                                          }
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                          { // ROW BUCKET
                            fieldType: 'empty-bucket',
                            fieldId: 'row-bucket-8',
                            properties: {
                              grid: {
                                columns: '12'
                              },
                              classes: 'form-column-bucket mt-4',
                              fields: [
                                {
                                  fieldType: 'checkbox',
                                  fieldId: 'wgc2j7',
                                  properties: {
                                    id: 'campaignTermsAccepted',
                                    name: 'campaignTermsAccepted',
                                    validation: {
                                      mandatory: {
                                        allowFalse: false
                                      }
                                    },
                                    styles: {
                                      color: '#4F4F4F'
                                    },
                                    colorSchema: 'var(--color-4)',
                                    grid: {
                                      columns: '12',
                                      paddingTop: '10px'
                                    },
                                    label: `Ich habe die <a href='/pink-raffle/teilnahmebedingungen' target="_blank" style='color: #FFF;'><b><u>Teilnahmebedingungen</u></b></a> dieser Aktion gelesen und akzeptiere diese.`
                                  }
                                },
                                {
                                  fieldType: 'checkbox',
                                  fieldId: 'wgc3j7',
                                  properties: {
                                    id: 'terms',
                                    name: 'terms',
                                    validation: {
                                      mandatory: {
                                        allowFalse: false
                                      }
                                    },
                                    grid: {
                                      columns: '12'
                                    },
                                    styles: {
                                      color: '#4F4F4F'
                                    },
                                    colorSchema: 'var(--color-4)',
                                    label: `Ich bestätige, dass ich über 18 Jahre alt bin und die <a style='color: #FFF;' href="https://www.pg.com/de_DE/terms_conditions/index.shtml" target="_blank"><b><u>P&G Geschäftsbedingungen</u></b></a>, <a href='https://privacypolicy.pg.com/de/' target="_blank" style='color: #FFF'><b><u>P&G Datenschutzrichtlinie</u></b></a> & <a href='https://justsnap.de/Datenschutz/' target="_blank" style='color: #FFF'><b><u>Justsnap Datenschutzrichtlinien</u></b></a> akzeptiere.
                                    <br>Sie können Ihre <u class='modalOpener' data-text='Wie in unserer Datenschutzerklärung erläutert, dürfen Sie auf die personenbezogenen Daten, die wir über Sie besitzen, zugreifen, sie berichtigen, löschen, an einen anderen Anbieter übertragen, unsere Verarbeitung ablehnen oder einschränken oder Ihre Zustimmung entziehen.'><span onclick="return false;"><b>Datenschutzrechte</b></span></u> ausüben oder Ihre Einstellungen und Abonnements im Bereich <a href='https://preferencecenter.pg.com/de-de/' target='_blank' style='color: #fff;'>Meine Anfrage zum Datenschutz</a> ändern.`
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: '12',
                          marginBottom: '30px',
                          rowGap: '20px'
                        },
                        class: 'd-flex justify-content-center align-items-center flex-column-reverse flex-md-row btn-bucket',
                        fields: [
                          { // Next Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 2
                              },
                              text: 'Zurück',
                              classes: 'btn-primary my-0 mx-0 col-sm-4',
                              action: {
                                fn: 'goToPage',
                                params: 'pink-raffle/kaufbeleg-hochladen'
                              },
                              arrow: {
                                prev: true,
                                color: '#FFF'
                              }
                            }
                          },
                          { // Next Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 2
                              },
                              text: 'Weiter',
                              classes: 'btn-primary my-0 mx-0 col-sm-4',
                              action: {
                                fn: 'sendForm',
                                params: 'inzf4waa'
                              },
                              arrow: {
                                next: true,
                                color: '#FFF'
                              }
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 9,
                          marginBottom: '20px',
                          marginTop: '20px'
                        },
                        class: 'justify-content-center align-items-center',
                        fields: [
                          { // Heading
                            fieldType: 'paragraph',
                            fieldId: 'par1001',
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: '30px'
                              },
                              text: 'KENNEN SIE SCHON <span style="display: inline-block; height: 28px; width: 93.88px; position: relative;"><img src="/assets/images/forme-logo.png" alt="ForMe" style="height: 28px; position: absolute; top: 3.2px; left: 0;"></span>?',
                              classes: 'page-heading text-center text-uppercase overrideMarginBot'
                            }
                          },
                          { // Subheading
                            fieldType: 'paragraph',
                            fieldId: 'par1001',
                            properties: {
                              grid: {
                                columns: 12
                              },
                              text: 'Als ForMe-Mitglied können Sie schneller & einfacher an zukünftigen Geld-Zurück-Aktionen teilnehmen. Außerdem finden Sie bei ForMe weitere spannende Gratis-Testen-Aktionen und Gewinnspiele von verschiedenen Marken und Produkten <u>Unser Tipp:</u> Newsletter abonnieren & keine Aktion mehr verpassen!',
                              classes: 'page-subheading addPadding'
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 5,
                          rowGap: '20px'
                        },
                        class: 'col-sm-12 col-md-10 col-lg-5 justify-content-center align-items-center',
                        fields: [
                          { // Buttons
                            fieldId: 'fu0923r',
                            fieldType: 'empty-bucket',
                            properties: {
                              grid: {
                                columns: 12
                              },
                              class: 'col-sm-12 col-md-10 col-lg-5 justify-content-center align-items-center',
                              styles: {
                                backgroundColor: '#FFF',
                                boxShadow: '0px 8px 32px -3px rgba(194, 185, 224, 0.5)',
                                padding: '20px 10px 20px 10px'
                              },
                              fields: [
                                {
                                  fieldType: 'paragraph',
                                  fieldId: 'fckzg7',
                                  properties: {
                                    text: 'Sie sind bereits <span style="display: inline-block; height: 18px; position: relative; width: 60.35px;"><img src="/assets/images/forme-logo.png" alt="ForMe" style="height: 18px; width: auto; position: absolute; top: 2.6px; left: 0;"></span> Mitglied? <span class="modalOpener" data-text="ForMe ist eine Online-Plattform von Procter & Gamble, die entwickelt wurde, um Familien mit Tipps und Tricks zur Seite zu stehen. Das Leben muss dabei nicht perfekt sein – wir von ForMe wissen aus eigener Erfahrung, dass das unmöglich ist. Unsere Mission ist es vielmehr, die kleinen Momente zu feiern, die Stärke in den eigenen vermeintlichen Schwächen zu finden und den Alltag etwas einfacher zu gestalten. Wir bieten dir handverlesene Angebote sowie neue Produkte zum Testen deiner Lieblingsmarken von P&G. Außerdem liefern wir dir praktische Tipps, inspirierende Geschichten und verlässliche Empfehlungen zu allen Themen rund um Familie, Haus & Garten, Gesundheit & Wellness sowie Beauty. Damit du alle Infos zuerst erhältst, kannst du dich hier für unseren Newsletter anmelden und Teil der ForMe-Community werden. Schau auch auf Instagram oder Facebook vorbei!" style="font-size: 12px; color: darkblue; vertical-align: middle; text-decoration: none;">&#9432;</span>',
                                    classes: ['col-12'],
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: 12
                                    },
                                    styles: {
                                      fontSize: '18px',
                                      fontWeight: '600',
                                      textAlign: 'center',
                                      margin: 0,
                                      padding: 0
                                    }
                                  }
                                },
                                { // Login Button
                                  fieldType: 'button-component',
                                  fieldId: 'but100',
                                  properties: {
                                    grid: {
                                      columns: 12
                                    },
                                    classes: 'btn-primary',
                                    text: 'Anmelden<br>& schneller teilnehmen',
                                    action: {
                                      fn: 'goToPage',
                                      params: 'pink-raffle/anmelden'
                                    }
                                  }
                                }
                              ]
                            }
                          },
                          { // Buttons
                            fieldId: 'fu0923r',
                            fieldType: 'empty-bucket',
                            properties: {
                              grid: {
                                columns: 12
                              },
                              class: 'col-sm-12 col-md-10 col-lg-5 justify-content-center align-items-center',
                              styles: {
                                backgroundColor: '#FFF',
                                boxShadow: '0px 8px 32px -3px rgba(194, 185, 224, 0.5)',
                                padding: '20px 10px 20px 10px'
                              },
                              fields: [
                                {
                                  fieldType: 'paragraph',
                                  fieldId: 'fckzg7',
                                  properties: {
                                    text: 'Sie haben noch kein <span style="display: inline-block; height: 18px; position: relative; width: 60.35px;"><img src="/assets/images/forme-logo.png" alt="ForMe" style="height: 18px; width: auto; position: absolute; top: 2.6px; left: 0;"></span> Profil? <span class="modalOpener" data-text="ForMe ist eine Online-Plattform von Procter & Gamble, die entwickelt wurde, um Familien mit Tipps und Tricks zur Seite zu stehen. Das Leben muss dabei nicht perfekt sein – wir von ForMe wissen aus eigener Erfahrung, dass das unmöglich ist. Unsere Mission ist es vielmehr, die kleinen Momente zu feiern, die Stärke in den eigenen vermeintlichen Schwächen zu finden und den Alltag etwas einfacher zu gestalten. Wir bieten dir handverlesene Angebote sowie neue Produkte zum Testen deiner Lieblingsmarken von P&G. Außerdem liefern wir dir praktische Tipps, inspirierende Geschichten und verlässliche Empfehlungen zu allen Themen rund um Familie, Haus & Garten, Gesundheit & Wellness sowie Beauty. Damit du alle Infos zuerst erhältst, kannst du dich hier für unseren Newsletter anmelden und Teil der ForMe-Community werden. Schau auch auf Instagram oder Facebook vorbei!" style="font-size: 12px; color: darkblue; vertical-align: middle; text-decoration: none;">&#9432;</span>',
                                    classes: ['col-12'],
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: 12
                                    },
                                    styles: {
                                      fontSize: '18px',
                                      fontWeight: '600',
                                      textAlign: 'center',
                                      margin: 0,
                                      padding: 0
                                    }
                                  }
                                },
                                { // Next Button
                                  fieldType: 'button-component',
                                  fieldId: 'but100',
                                  properties: {
                                    grid: {
                                      columns: 12
                                    },
                                    text: 'JETZT REGISTRIEREN<br>& VORTEILE SICHERN',
                                    action: {
                                      fn: 'goToPage',
                                      params: 'pink-raffle/registrieren'
                                    },
                                    classes: 'btn-primary'
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Login Page
        pageName: 'pink-raffle/anmelden',
        locale: 'de',
        pageId: 109,
        sections: [
          { // Section
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12
            },
            fields: [
              { // Step counter
                fieldType: 'empty-bucket',
                fieldId: 'emp1099',
                properties: {
                  grid: {
                    columns: 12,
                    justifyContent: 'center'
                  },
                  fields: [
                    { // Progress Bar
                      fieldType: "step-counter",
                      fieldId: "step100",
                      properties: {
                        grid: {
                          columns: 8
                        },
                        activeIndex: 3,
                        texts: ['Produkt\nkaufen', 'Kaufbeleg\nhochladen', 'Am Gewinnspiel\nteilnehmen']
                      }
                    }
                  ]
                }
              },
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '60px',
                    padding: '4vw 2vw'
                  },
                  classes: 'form-top-wrapper maxWidthOverride transparentBg',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '60px'
                        },
                        text: 'MELDEN SIE SICH MIT IHREN <span style="display: inline-block; height: 28px; width: 93.88px; position: relative;"><img src="/assets/images/forme-logo.png" alt="ForMe" style="height: 28px; position: absolute; top: 3.2px; left: 0;"></span> ZUGANGSDATEN AN & NEHMEN SIE SCHNELLER AN DIESER AKTION TEIL!',
                        classes:
                          'page-heading text-center text-uppercase'
                      }
                    },
                    { // Form
                      fieldType: 'form-component',
                      fieldId: 'inzf4w',
                      properties: {
                        styles: {
                          margin: '0',
                          alignItems: 'center',
                          flexDirection: 'column'
                        },
                        grid: {
                          columns: '12',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '70px'
                        },
                        requires: ['1'],
                        successAction: {
                          fn: 'goToPage',
                          params: 'pink-raffle/registrierung-abgeschlossen'
                        },
                        successState: [
                          {
                            state: 'referenceNumber',
                            form: 'referenceNumber'
                          }
                        ],
                        endpoint: '/login',
                        fields: [
                          { // Fields
                            fieldType: 'empty-bucket',
                            fieldId: '4h3gweb',
                            properties: {
                              grid: {
                                columns: 4,
                                marginTop: '20px'
                              },
                              styles: {
                                background: '#FFF',
                                boxShadow: '0px 8px 32px -3px rgba(194, 185, 224, 0.5)',
                                borderRadius: '24px',
                                padding: '30px 10px 30px 10px',
                                maxWidth: '500px'
                              },
                              class: 'col-md-10 col-lg-6 col-xl-5 d-flex justify-content-center',
                              fields: [
                                {
                                  fieldId: 'n6b54v',
                                  fieldType: 'text-input',
                                  properties: {
                                    name: 'email',
                                    label: 'E-Mail-Adresse*',
                                    placeholder: 'example@example.com',
                                    text: '',
                                    validation: 'required|email',
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: 12
                                    },
                                    styles: {
                                      marginTop: '30px'
                                    }
                                  }
                                },
                                {
                                  fieldId: '6h54bdd',
                                  fieldType: 'text-input',
                                  properties: {
                                    name: 'password',
                                    label: 'Passwort*',
                                    inputType: 'password',
                                    placeholder: 'Passwort*',
                                    text: '',
                                    validation: 'required',
                                    grid: {
                                      columns: 12
                                    },
                                    styles: {
                                      marginTop: '60px'
                                    }
                                  }
                                },
                                {
                                  fieldType: 'anchor',
                                  fieldId: 'fckzg7',
                                  properties: {
                                    text: 'Passwort vergessen?',
                                    url: 'https://www.for-me-online.de/passwort-vergessen',
                                    target: '_blank',
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: 12
                                    },
                                    styles: {
                                      color: '#28147B',
                                      fontSize: '14px',
                                      fontWight: '500',
                                      lineHeight: '20px',
                                      textAlign: 'left',
                                      margin: '20px 10px 20px 7px'
                                    }
                                  }
                                },
                                {
                                  fieldType: 'checkbox',
                                  fieldId: 'wgc2j7',
                                  properties: {
                                    id: 'campaignTermsAccepted',
                                    name: 'campaignTermsAccepted',
                                    validation: {
                                      mandatory: {
                                        allowFalse: false
                                      }
                                    },
                                    styles: {
                                      color: '#4F4F4F'
                                    },
                                    colorSchema: 'var(--color-4)',
                                    grid: {
                                      columns: '12',
                                      paddingTop: '10px'
                                    },
                                    label: `Ich habe die <a href='/pink-raffle/teilnahmebedingungen' target="_blank" style='color: #FFF;'><b><u>Teilnahmebedingungen</u></b></a> dieser Aktion gelesen und akzeptiere diese.`
                                  }
                                },
                                {
                                  fieldType: 'checkbox',
                                  fieldId: 'wgc3j7',
                                  properties: {
                                    id: 'terms',
                                    name: 'terms',
                                    validation: {
                                      mandatory: {
                                        allowFalse: false
                                      }
                                    },
                                    grid: {
                                      columns: '12'
                                    },
                                    styles: {
                                      color: '#4F4F4F'
                                    },
                                    colorSchema: 'var(--color-4)',
                                    label: `Ich bestätige, dass ich über 18 Jahre alt bin und die <a style='color: #FFF;' href="https://www.pg.com/de_DE/terms_conditions/index.shtml" target="_blank"><b><u>P&G Geschäftsbedingungen</u></b></a>, <a href='https://privacypolicy.pg.com/de/' target="_blank" style='color: #FFF'><b><u>P&G Datenschutzrichtlinie</u></b></a> & <a href='https://justsnap.de/Datenschutz/' target="_blank" style='color: #FFF'><b><u>Justsnap Datenschutzrichtlinien</u></b></a> akzeptiere.
                                    <br>Sie können Ihre <u class='modalOpener' data-text='Wie in unserer Datenschutzerklärung erläutert, dürfen Sie auf die personenbezogenen Daten, die wir über Sie besitzen, zugreifen, sie berichtigen, löschen, an einen anderen Anbieter übertragen, unsere Verarbeitung ablehnen oder einschränken oder Ihre Zustimmung entziehen.'><span onclick="return false;"><b>Datenschutzrechte</b></span></u> ausüben oder Ihre Einstellungen und Abonnements im Bereich <a href='https://preferencecenter.pg.com/de-de/' target='_blank' style='color: #fff;'>Meine Anfrage zum Datenschutz</a> ändern.`
                                  }
                                },
                                {
                                  fieldType: 'button-component',
                                  fieldId: '5n4b3vg4',
                                  properties: {
                                    class: 'btn-primary',
                                    grid: {
                                      columns: 9,
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginBottom: '30px',
                                      alignSelf: 'center'
                                    },
                                    text: 'Anmelden<br>& schneller fortfahren',
                                    disabled: false,
                                    action: {
                                      fn: 'sendForm',
                                      params: 'inzf4w'
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 9,
                          marginBottom: '20px',
                          marginTop: '20px'
                        },
                        class: 'justify-content-center align-items-center',
                        fields: [
                          { // Heading
                            fieldType: 'paragraph',
                            fieldId: 'par1001',
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: '30px'
                              },
                              text: 'SIE HABEN NOCH KEIN <span style="display: inline-block; height: 28px; width: 93.88px; position: relative;"><img src="/assets/images/forme-logo.png" alt="ForMe" style="height: 28px; position: absolute; top: 3.2px; left: 0;"></span> PROFIL?',
                              classes: 'page-heading text-center text-uppercase overrideMarginBot'
                            }
                          },
                          { // Subheading
                            fieldType: 'paragraph',
                            fieldId: 'par1001',
                            properties: {
                              grid: {
                                columns: 12
                              },
                              text: 'Mit einem ForMe Konto können Sie schneller und einfacher an zukünftigen Geld-Zurück-Aktionen teilnehmen. Außerdem finden Sie bei ForMe weitere spannende Gratis-Testen-Aktionen und Gewinnspiele von zahlreichen Markenprodukten.',
                              classes: 'page-subheading addPadding'
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 5,
                          rowGap: '20px'
                        },
                        class: 'col-sm-12 col-md-10 col-lg-5 justify-content-center align-items-center',
                        fields: [
                          { // Buttons
                            fieldId: 'fu0923r',
                            fieldType: 'empty-bucket',
                            properties: {
                              grid: {
                                columns: 12
                              },
                              class: 'col-sm-12 col-md-10 col-lg-5 justify-content-center align-items-center',
                              styles: {
                                backgroundColor: '#FFF',
                                boxShadow: '0px 8px 32px -3px rgba(194, 185, 224, 0.5)',
                                padding: '20px 10px 20px 10px'
                              },
                              fields: [
                                { // Login Button
                                  fieldType: 'button-component',
                                  fieldId: 'but100',
                                  properties: {
                                    grid: {
                                      columns: 12
                                    },
                                    classes: 'btn-primary',
                                    text: 'Registrieren<br>& vORTEILE SICHERN',
                                    action: {
                                      fn: 'goToPage',
                                      params: 'pink-raffle/registrieren'
                                    }
                                  }
                                },
                                { // Next Button
                                  fieldType: 'button-component',
                                  fieldId: 'but100',
                                  properties: {
                                    grid: {
                                      columns: 12
                                    },
                                    text: 'ALS GAST OHNE<br>FORME FORTHFAHREN',
                                    action: {
                                      fn: 'goToPage',
                                      params: 'pink-raffle/jetzt-registrieren'
                                    },
                                    classes: 'btn-primary'
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Thank you Page
        pageName: 'pink-raffle/registrierung-abgeschlossen',
        pageId: 113,
        sections: [
          {
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12
            },
            fields: [
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    justifyContent: 'flex-start',
                    marginBottom: '60px',
                    padding: '1w 2vw 2vw 2vw',
                    marginTop: '0px'
                  },
                  classes: 'form-top-wrapper maxWidthOverride transparentBg',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px'
                        },
                        text: 'DANKE FÜR IHRE TEILNAHME',
                        classes:
                          'page-heading text-center text-uppercase'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px'
                        },
                        text: 'Ihr Kassenbeleg und Ihre Daten wurden erfolgreich an uns übermittelt und werden jetzt überprüft.',
                        classes: 'page-subheading text-center'
                      }
                    },
                    { // Clipboard
                      fieldType: 'clipboard',
                      fieldId: 'ref123',
                      properties: {
                        grid: {
                          columns: 9
                        },
                        colorSchema: '#FFF',
                        disabledInput: true,
                        title: 'Referenznummer:',
                        variables: {
                          referenceNumber: 'referenceNumber'
                        }
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: '50px'
                        },
                        text: 'Sobald wir Ihre Daten überprüft haben, bekommen Sie entweder eine Bestätigungs-E-Mail oder eine E-Mail mit Rückfragen, falls Ihre Daten unvollständig waren oder Ihr Kassenbeleg nicht erfasst werden konnte. Dies kann bis zu 36 Stunden in Anspruch nehmen. Wir bitten um Ihre Geduld. Nach erfolgreicher Überprüfung des Kaufbelegs und aller weiteren Daten werden die Gewinner am 04.09.2024 ausgelost und benachrichtigt. Sollten sich Unklarheiten ergeben, werden wir Sie per E-Mail nochmal separat kontaktieren.',
                        classes: 'page-subheading text-center'
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: '12',
                          marginBottom: '60px',
                          justifyContent: 'center'
                        },
                        classes: 'btn-bucket survey-bucket mx-auto',
                        fields: [
                          { // Survey component
                            fieldType: 'survey',
                            fieldId: 'sur100',
                            properties: {
                              grid: {
                                columns: 8,
                                justifyContent: 'flex-start',
                                marginBottom: '50px'
                              },
                              class: 'mx-0',
                              mandatory: false,
                              endpoint: '/survey',
                              successAction: {
                                fn: 'goToPage',
                                params: 'pink-raffle/produkt-auswählen'
                              },
                              responseData: [
                                {
                                  variable: 'referenceNumber',
                                  token: 'referenceNumber'
                                }
                              ],
                              colorSchema: '#4E7593',
                              isIntegratedSurvey: true,
                              questions: [
                                {
                                  name: 'radio-1',
                                  type: 'radio',
                                  text: 'Haben Sie Swiffer das erste Mal ausprobiert?',
                                  options: ['Ja', 'Nein']
                                },
                                {
                                  name: 'radio-2',
                                  type: 'radio',
                                  text: 'Gefällt Ihnen das gekaufte Produkt? (1 = sehr schlecht - 5= sehr gut)',
                                  options: ['1', '2', '3', '4', '5'],
                                  otherOption: false,
                                  direction: 'row'
                                },
                                {
                                  name: 'radio-3',
                                  type: 'radio',
                                  text: 'Würden Sie das Produkt wieder kaufen?',
                                  options: ['Ja', 'Nein'],
                                  otherOption: false
                                },
                                {
                                  name: 'radio-2',
                                  type: 'radio',
                                  text: 'Wie oft verwenden Sie das Produkt?',
                                  options: [
                                    'Mehrfach pro Woche',
                                    '1x pro Woche',
                                    'mehrmals im Monat',
                                    'alle paar Monate',
                                    'weniger'
                                  ],
                                  otherOption: false
                                }
                              ]
                            }
                          },
                          { // Backto swiffer Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 9
                              },
                              text: 'Back to swiffer website',
                              classes: 'btn-primary mx-0',
                              action: {
                                anchor: true,
                                link: 'https://www.for-me-online.de/marken/swiffer'
                              },
                              arrow: {
                                next: true,
                                color: '#FFF'
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Reclaim Page
        pageName: 'pink-raffle/reclaim',
        locale: 'de',
        isPublic: true,
        sections: [
          {
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12,
              marginTop: '50px'
            },
            fields: [
              {
                fieldType: 'reclaim-bucket',
                fieldId: 'r3cla1m3uket',
                properties: {
                  grid: {
                    alignItems: 'center',
                    justifyContent: 'center',
                    columns: 9
                  },
                  colorSchema: '#4E7593',
                  endpoint: '/reclaim',
                  fields: [
                    { // Key visual
                      fieldType: 'key-visual',
                      fieldId: 'key100',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '50px'
                        },
                        styles: {
                          padding: '0 0 0 0'
                        },
                        noContainer: true,
                        desktopImageUrl:
                          '/assets/campaigns/pink-raffle/banner/desktop-banner.jpg',
                        tabletImageUrl:
                          '/assets/campaigns/pink-raffle/banner/desktop-banner.jpg',
                        mobileImageUrl:
                          '/assets/campaigns/pink-raffle/banner/mobile-banner.jpg'
                      }
                    },
                    { // Container
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: 12,
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '60px',
                          padding: '4vw 2vw'
                        },
                        classes: 'form-top-wrapper',
                        fields: [
                          { // Heading
                            fieldType: 'paragraph',
                            fieldId: 'vnaped',
                            properties: {
                              text: 'Finished',
                              classes: 'heading text-center',
                              grid: {
                                alignItems: 'center',
                                justifyContent: 'center',
                                columns: '12'
                              }
                            }
                          },
                          { // Subheading
                            fieldType: 'paragraph',
                            fieldId: 'par1001',
                            properties: {
                              grid: {
                                columns: 10,
                                marginBottom: '30px'
                              },
                              text: 'Thank you for your notification.',
                              classes: 'text-center',
                              styles: {
                                color: 'rgba(62, 62, 64, 0.8)',
                                fontWeight: '500'
                              }
                            }
                          },
                          { // Subheading
                            fieldType: 'paragraph',
                            fieldId: 'par1001',
                            properties: {
                              grid: {
                                columns: 10,
                                marginBottom: '50px'
                              },
                              text: 'We will check the refund amount again and then inform you. The review may take some time, we ask for your patience.',
                              classes: 'text text-center'
                            }
                          },
                          { // Buttons
                            fieldId: 'fu0923r',
                            fieldType: 'empty-bucket',
                            properties: {
                              grid: {
                                columns: '12',
                                marginBottom: '30px'
                              },
                              classes: 'btn-bucket',
                              fields: [
                                { // Next Button
                                  fieldType: 'button-component',
                                  fieldId: 'but100',
                                  properties: {
                                    grid: {
                                      columns: 8
                                    },
                                    text: 'Back to swiffer website',
                                    classes: 'btn-primary',
                                    action: {
                                      anchor: true,
                                      link: 'https://www.for-me-online.de/marken/swiffer'
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ],
                  failFields: [
                    {
                      fieldType: 'paragraph',
                      fieldId: 'vnaped',
                      properties: {
                        text: '<center>Es ist ein Fehler aufgetreten</center>',
                        classes: ['liners-heading'],
                        grid: {
                          alignItems: 'center',
                          justifyContent: 'center',
                          columns: '12'
                        }
                      }
                    },
                    {
                      fieldType: 'paragraph',
                      fieldId: 'oql2db',
                      properties: {
                        text: '<center>Derzeit liegt ein Problem bei unserer Website vor. Bitte versuchen Sie es in einigen Minuten erneut.</center>',
                        classes: ['liners-subheading'],
                        grid: {
                          alignItems: 'center',
                          justifyContent: 'center',
                          columns: '12',
                          marginBottom: '30vh'
                        }
                      }
                    }
                  ],
                  notFoundFields: [
                    {
                      fieldType: 'paragraph',
                      fieldId: 'vnaped',
                      properties: {
                        text: '<center>Ups, das hätte nicht passieren dürfen</center>',
                        classes: ['liners-heading'],
                        grid: {
                          alignItems: 'center',
                          justifyContent: 'center',
                          columns: '12'
                        }
                      }
                    },
                    {
                      fieldType: 'paragraph',
                      fieldId: 'oql2db',
                      properties: {
                        text: '<center>Es liegt ein technischer Fehler vor. Bitte warte einen Augenblick und versuche es erneut.</center>',
                        classes: ['liners-subheading'],
                        grid: {
                          alignItems: 'center',
                          justifyContent: 'center',
                          columns: '12',
                          marginBottom: '30vh'
                        }
                      }
                    }
                  ],
                  expiredFields: [
                    {
                      fieldType: 'paragraph',
                      fieldId: 'vnaped',
                      properties: {
                        text: '<center>Uuupps… Leider ist sind die 48 Stunden bereits abgelaufen.</center>',
                        classes: ['liners-heading'],
                        grid: {
                          alignItems: 'center',
                          justifyContent: 'center',
                          columns: '12'
                        }
                      }
                    },
                    {
                      fieldType: 'paragraph',
                      fieldId: 'oql2db',
                      properties: {
                        text: '<center>Bitte schreibe uns einfach eine E-Mail an kontakt@ariel-lenor-testwochen.de.<b><br>Bitte gebe in der E-Mail unbedingt deine persönliche Vorgangsnummer an.</b></center>',
                        classes: ['liners-subheading'],
                        grid: {
                          alignItems: 'center',
                          justifyContent: 'center',
                          columns: '12',
                          marginBottom: '30vh'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Terms and Conditions
        pageName: 'pink-raffle/teilnahmebedingungen',
        locale: 'de',
        isPublic: true,
        sections: [
          {
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: '12',
              marginBottom: '30px'
            },
            fields: [
              {
                fieldType: 'pdf-viewer',
                fieldId: 'vabd69',
                properties: {
                  grid: {
                    alignItems: 'center',
                    justifyContent: 'center',
                    columns: '8'
                  },
                  link: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/swiffer-promotions/pink-raffle/terms.pdf'
                }
              }
            ]
          }
        ]
      },
      { // FAQ
        pageName: 'pink-raffle/faq',
        locale: 'de',
        isPublic: true,
        sections: [
          {
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: '12',
              marginBottom: '30px'
            },
            fields: [
              {
                fieldType: 'pdf-viewer',
                fieldId: 'vabd69',
                properties: {
                  grid: {
                    alignItems: 'center',
                    justifyContent: 'center',
                    columns: '8'
                  },
                  link: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/swiffer-promotions/pink-raffle/faq.pdf'
                }
              }
            ]
          }
        ]
      },
      { // DOI
        pageName: 'pink-raffle/doi',
        isPublic: true,
        sections: [
          { // Section
            sectionId: 'asd3456hasd6',
            grid: {
              justifyContent: 'center',
              alignItems: 'center'
            },
            fields: [
              { // DOI CHECK
                fieldType: 'doi-SM',
                fieldId: 'doiasdasda',
                properties: {
                  endpoint: '/checkDoi'
                }
              },
              { // Key visual
                fieldType: 'key-visual',
                fieldId: 'key100',
                properties: {
                  grid: {
                    columns: 12,
                    marginBottom: '50px'
                  },
                  styles: {
                    padding: '0 0 0 0'
                  },
                  addClass: 'fullWidth',
                  noContainer: true,
                  desktopImageUrl: '/assets/campaigns/pink-raffle/banner/desktop-banner.jpg',
                  tabletImageUrl: '/assets/banner/forme-banner-desktop.png',
                  mobileImageUrl: '/assets/banner/forme-banner-mobile.png'
                }
              },
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '60px',
                    padding: '1vw 2vw 4vw 2vw'
                  },
                  classes: 'form-top-wrapper allTransparent',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px'
                        },
                        text: 'doi-first-paragraph',
                        classes: 'text-center'
                      }
                    },
                    { // Next Button
                      fieldType: 'button-component',
                      fieldId: 'formeButton1',
                      properties: {
                        grid: {
                          columns: 4,
                          marginBottom: '30px'
                        },
                        text: 'ICH MACHE MIT!',
                        classes: 'btn-forme',
                        action: {
                          anchor: true,
                          link: 'https://www.for-me-online.de/'
                        }
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: '30px'
                        },
                        text: 'doi-second-paragraph',
                        classes: 'text-center',
                        styles: {
                          //"color": "rgba(62, 62, 64, 0.8)",
                          fontWeight: '500'
                        }
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: '50px'
                        },
                        text: 'doi-third-paragraph',
                        classes: 'text-center'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'paro02012',
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: '50px'
                        },
                        text: 'doi-fourth-paragraph',
                        classes: 'text-center'
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // 404
        pageName: 'pink-raffle/error-page',
        locale: 'de',
        pageId: 114,
        isPublic: true,
        sections: [
          { // Heading
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12
            },
            fields: [
              { // Container
                fieldId: 'secondaryErrorBucket',
                fieldType: 'empty-bucket',
                properties: {
                  errorImages: true,
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '60px',
                    padding: '4vw 2vw',
                    flexDirection: 'column',
                    width: '80%'
                  },
                  classes: 'form-top-wrapper maxWidthOverride transparentBg',
                  fields: [
                    { // Key visual
                      fieldType: 'key-visual',
                      fieldId: 'key100',
                      properties: {
                        grid: {
                          columns: 4,
                          marginBottom: '50px'
                        },
                        styles: {
                          padding: '0 0 0 0'
                        },
                        noContainer: true,
                        desktopImageUrl: '/assets/images/error.svg',
                        tabletImageUrl: '/assets/images/error.svg',
                        mobileImageUrl: '/assets/images/error.svg'
                      }
                    },
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'headerErrorPage',
                      properties: {
                        text: `OOPS...`,
                        classes: 'heading text-uppercase text-center',
                        grid: {
                          alignItems: 'center',
                          justifyContent: 'center',
                          columns: '8',
                          marginTop: '20px'
                        }
                      }
                    },
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'subHeaderErrorPage',
                      properties: {
                        text: `Da ist etwas schief gelaufen. Bitte versuchen Sie es erneut.`,
                        classes: 'text text-center',
                        grid: {
                          alignItems: 'center',
                          justifyContent: 'center',
                          columns: '12'
                        },
                        styles: {
                          fontSize: '24px'
                        }
                      }
                    },
                    {
                      fieldType: 'button-component',
                      fieldId: 'errorBackButton',
                      properties: {
                        text: 'ZURÜCK ZUR STARTSEITE',
                        grid: {
                          columns: 6,
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '50px'
                        },
                        classes: 'btn-primary',
                        disabled: false,
                        action: {
                          anchor: true,
                          link: '/pink-raffle'
                        },
                        arrow: {
                          next: true,
                          color: '#FFF'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Expired page
        pageName: 'pink-raffle/expired-page',
        locale: 'de',
        pageId: 115,
        isPublic: true,
        sections: [
          { // Heading
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12,
              marginTop: '50px',
              marginBottom: '50px'
            },
            fields: [
              { // Container
                fieldId: 'secondaryErrorBucket',
                fieldType: 'empty-bucket',
                properties: {
                  errorImages: false,
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginTop: '0vh',
                    marginBottom: '0px',
                    padding: '4vw 2vw'
                  },
                  classes: 'form-top-wrapper',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'headerExpiredPage',
                      properties: {
                        text: `This session has expired`,
                        classes: 'heading text-uppercase',
                        grid: {
                          alignItems: 'center',
                          justifyContent: 'center',
                          columns: '8',
                          marginTop: '20px',
                          marginBottom: '40px'
                        }
                      }
                    },
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'subHeaderExpiredPage',
                      properties: {
                        text: `Unfortunately the link is no longer valid! Please participate again or contact our support team: kontakt@swiffer- gratis-testen.de`,
                        classes: 'text lh-lg',
                        grid: {
                          alignItems: 'center',
                          justifyContent: 'center',
                          columns: '12',
                          marginBottom: '50px'
                        }
                      }
                    },
                    {
                      fieldType: 'button-component',
                      fieldId: 'errorBackButton',
                      properties: {
                        text: 'ZURÜCK ZUR STARTSEITE',
                        grid: {
                          columns: 6,
                          alignItems: 'center',
                          justifyContent: 'center',
                          justifySelf: 'center',
                          marginTop: '50px',
                          marginRight: 'auto',
                          marginLeft: 'auto'
                        },
                        classes: 'btn-primary',
                        disabled: false,
                        action: {
                          anchor: true,
                          link: '/pink-raffle'
                        },
                        arrow: {
                          next: true,
                          color: '#FFF'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Campaign Is Over
        pageName: 'pink-raffle/campaign-is-over',
        locale: 'de',
        pageId: 118,
        isPublic: true,
        sections: [
          { // Heading
            sectionId: 'vw3456h756',
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center'
            },
            fields: [
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '60px',
                    padding: '4vw 2vw'
                  },
                  classes: 'form-top-wrapper',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'q432vg65',
                      properties: {
                        grid: {
                          columns: '9',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginBottom: '30px'
                        },
                        classes: 'page-heading text-center',
                        text: `The promotion period is over`,
                        styles: {
                          textTransform: 'uppercase'
                        }
                      }
                    },
                    { // Sub Heading
                      fieldType: 'paragraph',
                      fieldId: 'sh34f423',
                      properties: {
                        grid: {
                          alignItems: 'center',
                          justifyContent: 'center',
                          columns: 8,
                          marginBottom: '50px'
                        },
                        classes: 'page-subheading text-center',
                        text: `Sign up to stay up to date. Receive special offers and updates.`
                      }
                    },
                    {
                      fieldType: 'button-component',
                      fieldId: '9e9joo',
                      properties: {
                        grid: {
                          columns: 6,
                          alignItems: 'center',
                          justifyContent: 'center'
                        },
                        classes: 'btn-primary',
                        text: 'ZURÜCK ZUR SWIFFER-SEITE',
                        disabled: false,
                        action: {
                          anchor: true,
                          link: 'https://www.for-me-online.de/marken/swiffer'
                        },
                        arrow: {
                          next: true,
                          color: '#FFF'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt + Product Re Upload
        pageName: 'pink-raffle/receipt-product-reupload',
        locale: 'de',
        isPublic: true,
        pageId: 119,
        sections: [
          { // Steps and Header
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center'
            },
            sectionId: 'fu30zq',
            fields: [
              { // Query String
                fieldType: 'query-string-to-state',
                fieldId: 'uwxo2o1',
                properties: {
                  variables: [
                    {
                      query: 'referenceNumber',
                      state: 'referenceNumber'
                    },
                    {
                      query: 'token',
                      state: 'receiptToken'
                    }
                  ]
                }
              },
              { // 72 Hours check
                fieldType: 'checkReceiptToken-SM',
                fieldID: 'dksnfsdkjf',
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '60px',
                    padding: '0vw 2vw'
                  },
                  classes: 'form-top-wrapper maxWidthOverride transparentBg',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px',
                          marginTop: '50px'
                        },
                        text: 'Kaufbeleg + Produktrückseite mit BARCODE hochladen',
                        classes: 'page-heading text-uppercase overrideMarginBot'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: 'calc(15px + 2vw)'
                        },
                        text: 'Bitte laden Sie in diesem Schritt den Kaufbeleg Ihres Produktes, gemeinsam mit der Rückseite des Produktes hoch.<br>Achten Sie darauf, dass der EAN-Code Ihres Aktionsproduktes gut erkennbar ist.',
                        classes: 'page-subheading addPadding'
                      }
                    },
                    { // Receipt uploader
                      fieldType: 'empty-bucket',
                      fieldId: 'h453eadaf',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        styles: {
                          justifyContent: 'center',
                          alignItems: 'center',
                          columnGap: '3vw'
                        },
                        fields: [
                          { // Recipt upload
                            fieldType: 'product-upload-SM',
                            fieldId: 'klvakj',
                            properties: {
                              grid: {
                                columns: 4,
                                alignItems: 'center',
                                justifyContent: 'center'
                              },
                              styles: {
                                minHeight: '450px',
                                backgroundColor: '#FFF',
                                border: '2px dashed #E72174',
                                borderRadius: '20px',
                                outline: '2vw solid #FFF'
                              },
                              successAction: {
                                fn: 'goToPage',
                                params: 'pink-raffle/registrierung-abgeschlossen'
                              },
                              reUpload: true,
                              endpoint: '/uploadProductPhoto',
                              texts: {
                                choose: 'Choose a file',
                                upload: 'Hochladen',
                                text1: `Hier klicken, um den Kaufbeleg zusammen mit Ihrem Produkt hochzuladen.`,
                                text2: 'Only JPEG, PNG or PDF. Maximum 16MB',
                                wrongFormat: 'Wrong format receipt',
                                fileSizeLimit: 'File too big'
                              },
                              class: 'swiffer-receipt-processor',
                              colorSchema: '#828282'
                            }
                          },
                          { // Receipt Examples
                            fieldType: 'empty-bucket',
                            properties: {
                              grid: {
                                columns: 6,
                                justifyContent: 'center'
                              },
                              classes: 'receiptUploader',
                              fields: [
                                {
                                  fieldType: 'receipt-product-information',
                                  fieldId: '5mq1p6',
                                  properties: {
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: '12'
                                    },
                                    mainWrapperClass: 'swiffer-receipt-information',
                                    shouldDisplayFull: true,
                                    descriptionText: `Die folgenden Informationen müssen deutlich lesbar sein.`,
                                    descriptionSubText: `Gefaltete oder eingescannte Belege oder Rechnungen werden nicht akzeptiert.`,
                                    hideExamples: false,
                                    receiptImg: 'receipt-example-pink',
                                    steps: [
                                      { // Retailer
                                        text: 'Retailer / Shop',
                                        label: 'A'
                                      },
                                      {
                                        text: 'Campaign product and its price',
                                        label: 'B'
                                      },
                                      {
                                        text: 'Total sum of the receipt / invoice and applied VAT',
                                        label: 'C'
                                      },
                                      {
                                        text: 'Date and time of purchase',
                                        label: 'D'
                                      },
                                      {
                                        text: 'Receipt / Invoice ID (not the bank transaction ID)',
                                        label: 'E'
                                      }
                                    ],
                                    texts: {
                                      correct: "Gültig",
                                      notReadabe: "Schwer zu lesen",
                                      folded: "Zerknittert",
                                      cropped: "Abgeschnitten"
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: '12',
                          marginTop: 'calc(2vw + 30px)',
                          marginBottom: '30px'
                        },
                        classes: 'btn-bucket',
                        fields: [
                          { // Next Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 4
                              },
                              text: 'Next',
                              classes: 'btn-primary',
                              requires: ['klvakj'],
                              action: {
                                fn: 'sendProductPhotos',
                                params: 'klvakj'
                              },
                              arrow: {
                                next: true,
                                color: '#FFF'
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Re Upload
        pageName: 'pink-raffle/receipt-reupload',
        locale: 'de',
        isPublic: true,
        pageId: 1129,
        sections: [
          { // Steps and Header
            grid: {
              columns: 12,
              alignItems: 'center',
              justifyContent: 'center'
            },
            sectionId: 'fu30zq',
            fields: [
              { // Query String
                fieldType: 'query-string-to-state',
                fieldId: 'uwxo2o1',
                properties: {
                  variables: [
                    {
                      query: 'referenceNumber',
                      state: 'referenceNumber'
                    },
                    {
                      query: 'token',
                      state: 'receiptToken'
                    }
                  ]
                }
              },
              { // 72 Hours check
                fieldType: 'checkReceiptToken-SM',
                fieldID: 'dksnfsdkjf',
                properties: {
                  grid: { columns: 12 }
                }
              },
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '60px',
                    padding: '0vw 2vw'
                  },
                  classes: 'form-top-wrapper maxWidthOverride transparentBg',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px',
                          marginTop: '50px'
                        },
                        text: 'Kaufbeleg hochladen',
                        classes: 'page-heading text-uppercase overrideMarginBot'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: 'calc(15px + 2vw)'
                        },
                        text: 'Bitte laden Sie in diesem Schritt den Kaufbeleg Ihres Produktes hoch.',
                        classes: 'page-subheading addPadding'
                      }
                    },
                    { // Receipt uploader
                      fieldType: 'empty-bucket',
                      fieldId: 'h453eadaf',
                      properties: {
                        grid: {
                          columns: 12
                        },
                        styles: {
                          justifyContent: 'center',
                          columnGap: '3vw'
                        },
                        fields: [
                          { // Recipt upload
                            fieldType: 'receipt-processor-SM',
                            fieldId: 'klvakj',
                            properties: {
                              grid: {
                                columns: 3,
                                alignItems: 'center',
                                justifyContent: 'center'
                              },
                              styles: {
                                minHeight: '450px',
                                backgroundColor: '#FFF',
                                border: '2px dashed #E72174',
                                borderRadius: '20px',
                                outline: '2vw solid #FFF'
                              },
                              successAction: {
                                fn: 'goToPage',
                                params: 'pink-raffle/registrierung-abgeschlossen'
                              },
                              reUpload: true,
                              texts: {
                                choose: 'Choose a file',
                                upload: 'Hochladen',
                                text1: `Click here to upload the receipt photo`,
                                text2: 'Only JPEG, PNG or PDF. Maximum 16MB',
                                wrongFormat: 'Wrong format receipt',
                                fileSizeLimit: 'File too big'
                              },
                              editTexts: {
                                crop: 'Crop',
                                erase: 'Erase',
                                drag: 'Drag',
                                zoomIn: 'Zoom In',
                                zoomOut: 'Zoom Out'
                              },
                              mainWrapperClass: 'swiffer-receipt-processor',
                              colorSchema: '#828282',
                              phrases: [
                                'Please erase your personal data with the eraser tool.',
                                'Please frame the receipt / invoice in the gray rectangle.'
                              ],
                              cornerDetectionPhrases: [
                                'Please erase your personal data with the eraser tool.',
                                'Please frame the receipt / invoice in the gray rectangle.'
                              ]
                            }
                          },
                          { // Receipt Examples
                            fieldType: 'empty-bucket',
                            properties: {
                              grid: {
                                columns: 6,
                                justifyContent: 'center'
                              },
                              classes: 'receiptUploader',
                              fields: [
                                {
                                  fieldType: 'receipt-information',
                                  fieldId: '5mq1p6',
                                  properties: {
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: '12'
                                    },
                                    mainWrapperClass: 'swiffer-receipt-information',
                                    shouldDisplayFull: true,
                                    descriptionText: `Die folgenden Informationen müssen deutlich lesbar sein.`,
                                    descriptionSubText: `Gefaltete oder eingescannte Belege oder Rechnungen werden nicht akzeptiert.`,
                                    hideExamples: false,
                                    receiptImg: 'receipt-example-pink',
                                    steps: [
                                      { // Retailer
                                        text: 'Retailer / Shop',
                                        label: 'A'
                                      },
                                      {
                                        text: 'Campaign product and its price',
                                        label: 'B'
                                      },
                                      {
                                        text: 'Total sum of the receipt / invoice and applied VAT',
                                        label: 'C'
                                      },
                                      {
                                        text: 'Date and time of purchase',
                                        label: 'D'
                                      },
                                      {
                                        text: 'Receipt / Invoice ID (not the bank transaction ID)',
                                        label: 'E'
                                      }
                                    ],
                                    texts: {
                                      correct: "Gültig",
                                      notReadabe: "Schwer zu lesen",
                                      folded: "Zerknittert",
                                      cropped: "Abgeschnitten"
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: '12',
                          marginTop: 'calc(2vw + 30px)',
                          marginBottom: '30px'
                        },
                        classes: 'btn-bucket',
                        fields: [
                          { // Next Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 4
                              },
                              text: 'Next',
                              classes: 'btn-primary',
                              requires: ['klvakj'],
                              action: {
                                fn: 'sendReceipt',
                                params: ''
                              },
                              arrow: {
                                next: true,
                                color: '#FFF'
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Iban reuploader
        pageName: 'pink-raffle/iban-reupload',
        locale: 'de',
        isPublic: true,
        pageId: 123,
        requires: [1],
        sections: [
          { // Section
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12,
              marginTop: '50px'
            },
            fields: [
              {
                fieldType: 'iban-reupload-SM',
                fieldId: 'ij3411'
              },
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '60px',
                    padding: '4vw 2vw'
                  },
                  classes: 'form-top-wrapper maxWidthOverride transparentBg',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px'
                        },
                        text: 'Bankverbindung eingeben',
                        classes:
                          'page-heading text-uppercase overrideMarginBot'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '30px'
                        },
                        text: 'Bitte teilen Sie uns mit, auf welches Konto das Reisebudget in Höhe von 3.000€ überwiesen werden soll.',
                        classes: 'page-subheading addPadding overrideMarginBot'
                      }
                    },
                    { // Form
                      fieldType: 'form-component',
                      fieldId: 'bank1Nf0',
                      properties: {
                        successAction: {
                          fn: 'goToPage',
                          params: 'pink-raffle/thank-you-iban'
                        },
                        endpoint: '/updateIBAN',
                        requiresJwtToken: false,
                        grid: {
                          alignItems: 'center',
                          justifyContent: 'center',
                          columns: '12',
                          marginBottom: '20px'
                        },
                        styles: {
                          justifyContent: 'center'
                        },
                        fields: [
                          { // Fields
                            fieldType: 'empty-bucket',
                            fieldId: '4h3gweb',
                            properties: {
                              grid: {
                                columns: 5,
                                marginTop: '20px'
                              },
                              styles: {
                                background: '#FFF',
                                boxShadow: '0px 8px 32px -3px rgba(194, 185, 224, 0.5)',
                                borderRadius: '24px',
                                padding: '30px 10px 30px 10px',
                                maxWidth: '500px'
                              },
                              class: 'col-md-10 col-lg-6 col-xl-5 d-flex justify-content-center',
                              fields: [
                                {
                                  fieldId: 'n6b54v',
                                  fieldType: 'text-input',
                                  properties: {
                                    name: 'firstname',
                                    label: 'Vorname Kontoinhaber*',
                                    placeholder: 'Vorname Kontoinhaber*',
                                    text: '',
                                    validation: 'required|namesWithHyphensAndSpaces',
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: 12
                                    },
                                    styles: {
                                      marginTop: '30px'
                                    }
                                  }
                                },
                                {
                                  fieldId: '6h54bdd',
                                  fieldType: 'text-input',
                                  properties: {
                                    name: 'lastname',
                                    label: 'Nachname Kontoinhaber*',
                                    placeholder: 'Nachname Kontoinhaber*',
                                    text: '',
                                    validation: 'required|namesWithHyphensAndSpaces',
                                    grid: {
                                      columns: 12
                                    },
                                    styles: {
                                      marginTop: '60px'
                                    }
                                  }
                                },
                                {
                                  fieldId: 'n6b54v1212',
                                  fieldType: 'text-input',
                                  properties: {
                                    name: 'iban',
                                    label: 'IBAN*',
                                    placeholder: 'IBAN*',
                                    text: '',
                                    validation: 'required|iban|sepa',
                                    grid: {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      columns: 12
                                    },
                                    styles: {
                                      marginTop: '60px'
                                    }
                                  }
                                },
                                {
                                  fieldId: '6h54b421312dd',
                                  fieldType: 'text-input',
                                  properties: {
                                    name: 'bic',
                                    label: 'BIC*',
                                    placeholder: 'BIC*',
                                    text: '',
                                    validation: 'required|bic',
                                    grid: {
                                      columns: 12
                                    },
                                    styles: {
                                      marginTop: '60px'
                                    }
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Text
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 5,
                          marginBottom: '50px'
                        },
                        text: '* Vermeiden Sie eine Änderung bei den Umlauten: <b>Müller bzw. Mueller.</b> Vermeiden Sie ein Hinzufügen oder Weglassen von Bindestrichen: <b>Marie Luise bzw. Marie-Luise.</b> Geben Sie den zweiten Vornamen nur dann an, wenn auch das Konto so geführt wird. Sollten die Daten nicht mit den Kontodaten übereinstimmen, kommt es in den meisten Fällen zu Rückbuchungen seitens der Bank und erschwert den Ablauf einer unkomplizierten und raschen Rückerstattung.',
                        classes: 'text text-center'
                      }
                    },
                    { // Buttons
                      fieldId: 'fu0923r',
                      fieldType: 'empty-bucket',
                      properties: {
                        grid: {
                          columns: '12',
                          marginBottom: '30px'
                        },
                        classes: 'btn-bucket',
                        fields: [
                          { // Next Button
                            fieldType: 'button-component',
                            fieldId: 'but100',
                            properties: {
                              grid: {
                                columns: 4
                              },
                              text: 'Weiter',
                              classes: 'btn-primary',
                              action: {
                                fn: 'sendForm',
                                params: 'bank1Nf0'
                              },
                              arrow: {
                                next: true,
                                color: '#FFF'
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Iban thank you page
        pageName: 'pink-raffle/thank-you-iban',
        locale: 'de',
        // isPublic: true,
        pageId: 124,
        sections: [
          {
            grid: {
              alignItems: 'center',
              justifyContent: 'center',
              columns: 12,
              marginTop: '50px'
            },
            fields: [
              { // Container
                fieldId: 'fu0923r',
                fieldType: 'empty-bucket',
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '60px',
                    padding: '4vw 2vw'
                  },
                  classes: 'form-top-wrapper',
                  fields: [
                    { // Heading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: '50px'
                        },
                        text: 'Thank you',
                        classes: 'heading text-center text-uppercase'
                      }
                    },
                    { // Subheading
                      fieldType: 'paragraph',
                      fieldId: 'par1001',
                      properties: {
                        grid: {
                          columns: 10,
                          marginBottom: '50px'
                        },
                        text: "Vielen Dank für das Einreichen Ihrer Bankdaten! Sie erhalten Ihren Gewinn innerhalb von 4 Wochen. Für den Fall, dass Sie Fragen haben, kontaktieren Sie bitte <a style='color:blue !important; text-decoration: underline;' href='mailto:pink-raffle@swiffer-promotions.de.'>pink-raffle@swiffer-promotions.de.</a>",
                        classes: 'text text-center'
                      }
                    },
                    { // Next Button
                      fieldType: 'button-component',
                      fieldId: 'but100',
                      properties: {
                        grid: {
                          columns: 8
                        },
                        text: 'Back to swiffer website',
                        classes: 'btn-primary',
                        action: {
                          anchor: true,
                          link: 'https://www.for-me-online.de/marken/swiffer'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ],
    header: {
      fieldType: 'navbar',
      fieldId: 'cbx34t',
      properties: {
        grid: {
          columns: 12
        },
        styles: {
          boxShadow: '0px 0px 20px 0px #4E75934D',
          background: '#E72174',
          color: '#fff',
          margin: '0px auto',
          padding: '20px 40px'
        },
        font: {
          name: 'Gotham Rounded',
          url: '/assets/fonts/stylesheet.css'
        },
        logo: {
          imageSource: '/assets/images/logo-new.png',
          alt: 'Swiffer'
        },
        favicon: {
          imageSource: '/assets/images/logo-new.png'
        },
        fields: [
          {
            fieldId: 'ikz8vc',
            fieldType: 'anchor',
            properties: {
              text: `TEILNAHMEBEDINGUNGEN`,
              url: '/pink-raffle/teilnahmebedingungen',
              target: '_blank',
              cssClass: 'nav-link',
              styles: {
                fontWeight: '500 !important',
                color: '#FFF !important',
                fontFamily: 'Gotham Rounded !important',
                fontSize: '16px !important'
              }
            }
          },
          {
            fieldId: 'slnmdk',
            fieldType: 'anchor',
            properties: {
              text: `FAQ`,
              url: '/pink-raffle/faq',
              target: '_blank',
              cssClass: 'nav-link',
              styles: {
                fontWeight: '500 !important',
                color: '#FFF !important',
                fontFamily: 'Gotham Rounded !important',
                fontSize: '16px !important'
              }
            }
          }
        ],
        meta: [
          { name: 'title', content: 'Swiffer Promotions' },
          {
            name: 'description',
            content: `Swiffer Pink Produkt kaufen, Kaufbeleg hochladen und mit etwas Glück eine Traumreise gewinnen. Aktionszeitraum: 01.04.2024 - 17.08.2024`
          },
          {
            name: 'keywords',
            content: `Swiffer, Swiffer Pink, Swiffer Gewinnspiel, Swiffer Pink Gewinnspiel, Reise gewinnen, Reise gewinnen Swiffer, Reisebudget gewinnen Swiffer, Instax gewinnen, Instax-Kamera gewinnen`
          }
        ]
      }
    },
    footer: {
      fieldType: 'footer-component',
      fieldId: '4tfbzz',
      properties: {
        grid: {
          alignItems: 'center',
          justifyContent: 'center',
          columns: '12'
        },
        styles: {
          background: '#E72174',
          color: '#FFF',
          margin: 'auto',
          position: 'relative'
        },
        logo: {
          src: '/assets/images/logo-new.png',
          url: '/',
          alt: 'Swiffer',
          styles: {
            width: '100%',
            backgroundColor: '#E72174',
            minHeight: '20px'
          }
        },
        fields: [
          {
            fieldType: 'empty-bucket',
            fieldId: 'foot100',
            properties: {
              grid: {
                columns: 9,
                justifyContent: 'flex-start',
                alignSelf: 'center',
                margin: '20px 0 0 20px'
              },
              fields: [
                {
                  fieldType: 'paragraph',
                  fieldId: 'foot100',
                  properties: {
                    grid: {
                      columns: 12
                    },
                    text: `Unsere Supportabteilung steht Ihnen bis zum 12.10.2024 zur Verfügung:<br><b><a href="mailto:pink-raffle@swiffer-promotions.de">pink-raffle@swiffer-promotions.de</a></b>`,
                    styles: {
                      color: '#FFF',
                      padding: 0
                    }
                  }
                },
                {
                  fieldType: 'empty-bucket',
                  fieldId: 'foot100',
                  properties: {
                    grid: {
                      columns: 9,
                      margin: 0,
                      padding: 0
                    },
                    fields: [
                      {
                        fieldType: 'unordered-list',
                        fieldId: 'foot100',
                        properties: {
                          grid: {
                            columns: 6,
                            padding: 0,
                            margin: 0
                          },
                          fields: [
                            {
                              fieldId: 'ikz8vc',
                              fieldType: 'anchor',
                              properties: {
                                grid: {
                                  columns: 12
                                },
                                text: `Kontakt`,
                                url: 'mailto:pink-raffle@swiffer-promotions.de',
                                target: '_blank',
                                cssClass: 'nav-link',
                                styles: {
                                  padding: '0',
                                  minHeight: '45px'
                                }
                              }
                            },
                            {
                              fieldId: 'ikz8vc',
                              fieldType: 'anchor',
                              properties: {
                                grid: {
                                  columns: 12
                                },
                                text: `Impressum`,
                                url: 'https://de.pg.com/impressum/',
                                target: '_blank',
                                cssClass: 'nav-link',
                                styles: {
                                  padding: '0',
                                  minHeight: '45px'
                                }
                              }
                            },
                            {
                              fieldId: 'ikz8vc',
                              fieldType: 'anchor',
                              properties: {
                                grid: {
                                  columns: 12
                                },
                                text: `Datenschutz`,
                                url: 'https://privacypolicy.pg.com/de-de/',
                                target: '_blank',
                                cssClass: 'nav-link',
                                styles: {
                                  padding: '0',
                                  minHeight: '45px'
                                }
                              }
                            },
                            {
                              fieldId: 'ikz8vc',
                              fieldType: 'anchor',
                              properties: {
                                grid: {
                                  columns: 12
                                },
                                text: `for me`,
                                url: 'https://www.for-me-online.de/',
                                target: '_blank',
                                cssClass: 'nav-link',
                                styles: {
                                  padding: '0',
                                  minHeight: '45px'
                                }
                              }
                            }
                          ]
                        }
                      },
                      {
                        fieldType: 'unordered-list',
                        fieldId: 'foot100',
                        properties: {
                          grid: {
                            columns: 6,
                            padding: 0,
                            margin: 0
                          },
                          fields: [
                            {
                              fieldId: 'ikz8vc',
                              fieldType: 'anchor',
                              properties: {
                                grid: {
                                  columns: 12
                                },
                                text: `Victoria`,
                                url: 'https://www.for-me-online.de/victoria',
                                target: '_blank',
                                cssClass: 'nav-link',
                                styles: {
                                  padding: '0',
                                  minHeight: '45px'
                                }
                              }
                            },
                            {
                              fieldId: 'ikz8vc',
                              fieldType: 'anchor',
                              properties: {
                                grid: {
                                  columns: 12
                                },
                                text: `P&G-Seite`,
                                url: 'https://de.pg.com/de-DE',
                                target: '_blank',
                                cssClass: 'nav-link',
                                styles: {
                                  padding: '0',
                                  minHeight: '45px'
                                }
                              }
                            },
                            {
                              fieldId: 'ikz8vc',
                              fieldType: 'anchor',
                              properties: {
                                grid: {
                                  columns: 12
                                },
                                text: `Geschäftsbedingungen`,
                                url: 'https://termsandconditions.pg.com/de-de/',
                                target: '_blank',
                                cssClass: 'nav-link',
                                styles: {
                                  padding: '0',
                                  minHeight: '45px'
                                }
                              }
                            },
                            {
                              fieldId: 'ikz8vc',
                              fieldType: 'anchor',
                              properties: {
                                grid: {
                                  columns: 12
                                },
                                text: `Meine Daten`,
                                url: 'https://preferencecenter.pg.com/de-de/',
                                target: '_blank',
                                cssClass: 'nav-link',
                                styles: {
                                  padding: '0',
                                  minHeight: '45px'
                                }
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            fieldId: 'ikz8vc',
            fieldType: 'paragraph',
            properties: {
              grid: {
                columns: 12
              },
              text: `© 2024 Procter & Gamble`,
              styles: {
                padding: '0',
                color: 'white !important',
                fontFamily: 'Gotham Rounded',
                textAlign: 'center'
              }
            }
          }
        ]
      }
    },
    errors: {
      de: {
        default: 'Hoppla, da ist was schief gelaufen!',
        noEmail: 'Es ist kein Konto mit dieser E-Mail-Adresse vorhanden.',
        emailUsed: 'Diese E-Mail-Adresse wird bereits verwendet',
        incorrect: 'Unzulässiger Benutzername oder falsches Passwort. Bitte versuchen Sie es erneut!',
        invalidCode: 'Der Link, den Sie verwenden möchten, ist bereits abgelaufen. Bitte fordern Sie einen neuen Link zum Zurücksetzen des Passworts an.',
        manyTries: 'Ein Fehler ist aufgetreten, weil der Beleg zu oft hochgeladen wurde.',
        generateCode: 'Der Bestätigungscode konnte nicht gesendet wenden, weil es sich um eine nicht zugelassene Telefonnummer handelt. Bitte überprüfen Sie die eingegebene Nummer und versuchen Sie es erneut.',
        wrongCode: 'Der SMS-Code ist falsch',
        network: 'Hoppla. Auf dem Server ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        '15minsOver': 'Die Sitzung ist abgelaufen. Bitte laden Sie die Seite neu und versuchen Sie es erneut.',
        notActive: 'Ungültiger Teilnahmezeitraum. Bitte lesen Sie unsere Teilnahmebedingungen.',
        badRequest: 'Ungültige Anfrage: campaignKey ist erforderlich.',
        alreadyActive: 'Pro IP-Adresse ist nur eine Teilnahme innerhalb von 15 Minuten erlaubt. Bitte probieren Sie es in ein paar Minuten erneut.',
        dailyParticipationLimit: 'Das tägliche Limit wurde leider schon erreicht, aber es ist möglich, dass Plätze wieder frei werden, falls einige Kaufbelege abgelehnt werden. Bleiben Sie dran - vielleicht klappt es ja doch noch! Ansonsten können Sie es gerne morgen erneut versuchen. Der Zähler wird um 8 Uhr zurückgesetzt.'
      }
    },
    dictionary: {
      de: {
        messages: {
          required: (field) => `${field} ist ein Pflichtfeld`,
          mandatory: 'Das ist ein Pflichtfeld',
          namesWithHyphensAndSpaces: (field) => `${field} darf nur alphabetische Zeichen enthalten`,
          email: 'Geben Sie bitte eine gültige E-Mail-Adresse an',
          password: 'Das Passwort muss mindestens 8 Zeichen lang sein, mindestens einen Großbuchstaben (A-Z), einen Kleinbuchstaben (a-z) und eine Zahl (0-9) enthalten.',
          confirmPass: 'Passwörter stimmen nicht überein',
          postal: 'PLZ ist ungültig',
          iban: 'Bitte geben Sie eine gültige IBAN an',
          bic: 'Bitte geben Sie einen gültigen BIC ein',
          phoneNumber: 'Das Handynummer muss eine ganze Zahl sein',
          hausnr: 'Hausnummer ist nicht gültig',
          sepa: 'Bitte verwenden Sie eine gültige IBAN der teilnehmenden Länder'
        },
        names: {
          iban: 'IBAN',
          gender: 'Geschlecht',
          bic: 'BIC',
          firstname: 'Vorname',
          firstName: 'Vorname',
          lastname: 'Nachname',
          lastName: 'Nachname',
          email: 'E-mail',
          birthdate: 'Geburtsdatum',
          password: 'Passwort',
          newPassword: 'Passwort',
          confirmPassword: 'Bestätigen des Passwort',
          newPasswordConfirm: 'Bestätigen des Passwort',
          strasse: 'Straße',
          hausnr: 'Nr',
          plz: 'PLZ',
          ort: 'Ort',
          land: 'Land',
          phoneNumber: 'Handynummer',
          code: 'Bestatigungscode'
        }
      }
    },
    campaignId: 'swiffer-pink-de',
    url: 'https://www.for-me-online.de/marken/swiffer',
    name: 'swiffer-pink-de'
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'Ein Fehler ist aufgetreten',
    isActive: false
  },
  campaignId: 'swiffer-pink-de',
  campaignKey: 'a3378c4d-f5e4-4920-9c9a-572c5e29d859',
  customerToken: '',
  jwtToken: '',
  receiptToken: '',
  receiptImage: '',
  referenceNumber: '',
  formData: new FormData(),
  campaignStartDate: '2023-04-01', // TODO: change to 2024 before go live
  campaignEndDate: '2024-08-17',
  lastUploadDate: '2024-08-31',
  scannerActive: false,
  optIn: false,
  file: {},
  barcode: {
    code: '',
    format: ''
  },
  productCode: '',
  searchStatus: null,
  imgList: [],
  errorMessage: 'Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?',
  errorImageList: ['', ''],
  errorActivate: false,
  scannedProducts: [],
  types: [
    'ean_reader',
    'code_128_reader',
    'code_39_reader',
    'ean_8_reader',
    'upc_reader',
    'upc_e_reader',
    'i2of5_reader',
    '2of5_reader',
    'code_93_reader',
    'codabar_reader'
  ],
  receiptBarcode: '',
  convertedImagePointsData: {},
  headerInclude: [
    '',
    'pink-raffle/campaign-is-over',
    'pink-raffle/registrierung-abgeschlossen',
    'pink-raffle/reclaim',
    'pink-raffle/doi'
  ],
  disableAnchors: false,
  locale: 'de',
  frontAndBack: false,
  countryCode: '',
  tel: '',
  consumerId: ''
}
