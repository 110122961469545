<template>
  <span></span>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "checkReceiptToken-SM",
  mounted () {
    const value = this.$route.query['token']
    if (this.frontAndBack) {
      return
    }
    this.check72HoursSM(value)
      .then(res => {
        if (!res.response) {
          if (this.locale == 'fr') {
            location.href='fr-expired-page'
          } else {
            location.href='expired-page'
          }
        }
      })
    // Need to set frontAndBack to true, next time it will not do  72hrs check due to both front and back reupload
    if (this.$route.fullPath.includes("fe-be-produktfoto-hochladen")) {
      this.$store.commit('setFrontAndBack', true);
    }
  },
  methods: {
    ...mapActions(['check72HoursSM'])
  },
  computed: {
    ...mapGetters(['locale', 'frontAndBack'])
  }
}
</script>