<template>
  <ValidationObserver ref="form">
    <form v-if="!loading" :id="fieldId">
      <div v-if="state==='success'">
        <template v-for="field in fields">
          <componentRenderer
            :class="`col-lg-${field.properties.grid.columns}`"
            :field="field"
            style="margin-top: 20px"
            v-on:component-updated="updated"
            v-bind:key="field.id"
          />
        </template>
      </div>
      <div v-else-if="state==='notFound'">
        <template v-for="field in notFoundFields">
          <componentRenderer
            :class="`col-lg-${field.properties.grid.columns}`"
            :field="field"
            style="margin-top: 80px"
            v-on:component-updated="updated"
            v-bind:key="field.id"
          />
        </template>
      </div>
      <div v-else-if="state==='expired'">
        <template v-for="field in expiredFields">
          <componentRenderer
            :class="`col-lg-${field.properties.grid.columns}`"
            :field="field"
            style="margin-top: 80px"
            v-on:component-updated="updated"
            v-bind:key="field.id"
          />
        </template>
      </div>
      <div v-else>
        <template v-for="field in failFields">
          <componentRenderer
            :class="`col-lg-${field.properties.grid.columns}`"
            :field="field"
            style="margin-top: 80px"
            v-on:component-updated="updated"
            v-bind:key="field.id"
          />
        </template>
      </div>
      <!-- <button @click="submit">SUBMIT</button> -->
    </form>
  </ValidationObserver>
</template>
<script>
import componentRenderer from "@/components/component-renderer";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "reclaim-bucket",
  components: { componentRenderer },
  props: {
    fieldId: String,
    requires: Array,
    successAction: {
      type: Object,
      default: () => {
        return null
      }
    },
    endpoint: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    failFields: {
      type: Array,
      required: true
    },
    notFoundFields: {
      type: Array,
      required: true
    },
    expiredFields: {
      type: Array,
      required: true
    },
    grid: {
      type: Object
    }
  },
  data: () => {
    return {
      state: "",
      loading: true
    };
  },
  computed: {
    ...mapGetters(["campaignId"])
  },
  methods: {
    ...mapActions(['sendReclaimToken'])
  },
  mounted () {
    if(this.$route.query && this.$route.query.campaignId){
      const campaignId = this.$route.query.campaignId
      this.$store.commit('setCampaignId', campaignId);
    }

    if (this.$route.query && this.$route.query.token) {
      this.token = this.$route.query.token;
      this.sendReclaimToken({ endpoint: this.endpoint, receiptToken: this.token }).then(result => {
        if (result.response && !result.errorCode) {
          this.loading = false
          this.state = "success"
        }  else {
          if (this.locale == 'fr') {
            // location.href='fr-expired-page'
          } else {
            location.href='3EURcashback/expired-page'
          }
        }
      });
    }
  }
};
</script>
