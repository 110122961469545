<template>
  <button
    type="button"
    :class="`btn ${classes}`"
    :style="[styles]"
    :disabled="disabled || loading || dependencies"
    @click="clicked"
  >
    <div v-if="arrow.prev" class="arrow arrow-prev">
      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.41016 1.41L2.83016 6L7.41016 10.59L6.00016 12L0.000155878 6L6.00016 1.23266e-07L7.41016 1.41Z" :fill="arrow.color"/>
      </svg>
    </div>
    <div v-if="!loading" v-html="$t(text)"></div>
    <div
      v-else
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <span class="sr-only visually-hidden">Loading...</span>
    </div>
    <div v-if="arrow.next" class="arrow arrow-next">
      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.589844 10.59L5.16984 6L0.589844 1.41L1.99984 0L7.99984 6L1.99984 12L0.589844 10.59Z" :fill="arrow.color"/>
      </svg>
    </div>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "button-component",
  data () {
    return {
      loading: false
    };
  },
  props: {
    requires: {
      type: Array
    },
    text: {
      required: false,
      type: String,
      default: "Button"
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    action: {
      type: [Object, Array]
    },
    classes: {
      type: String,
      default: ""
    },
    styles: {
      type: Object,
      default: () => {}
    },
    arrow: {
      type: Object,
      default: () => {
        return {
          prev: false,
          next: false,
          color: '#FFF'
        }
      }
    },
    startCampaignButton: {
      type: Boolean,
      default: false
    },
    isTesting:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['hasUnresolvedDependencies']),
    dependencies () {
      return this.hasUnresolvedDependencies(this.requires);
    }
  },
  methods: {
    async clicked () {
      if (this.startCampaignButton) {
        const userIsEligible = await this.$store.dispatch("checkEligibility", { isTesting: this.isTesting })
        if (!userIsEligible) return
      }
      if (!this.action) return;
      if (this.action.anchor) {
        window.location.href = this.action.link
      }
      if (this.action.length > 0) {
        for (let i = 0; i < this.action.length; i++) {
          const act = this.action[i];
          const fn = act.fn;
          const params = act.params;
          await this[fn](params, this);
        }
        return;
      }
      const fn = this.action.fn;
      const params = this.action.params;
      await this[fn](params, this);
    }
  }
};
</script>

<style lang="scss">
.btn {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border-radius: var(--btn-radius);
  transition: all 0.3s;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  letter-spacing: 0.18px;
  text-align: center;
  padding: 15px 30px;
  text-transform: uppercase;
  position: relative;
  @media(max-width: 768px){
    font-size: 14px;
    padding: 15px 10px;
  }
  @media (max-width: 425.97px) {
    font-size: 11px;
    padding: 5px;
  }
  &_disabled {
    opacity: 0.3;
  }
  &-forme {
    background-color: #FF850F;
    border: 1px solid #FF850F;
    color: white;
    border-radius: 0px !important;
  }
  &-primary {
    background-color: var(--btn-pri-color);
    border: 1px solid var(--btn-pri-color);
    &:hover {
      background-color: var(--btn-pri-hover);
      border: 1px solid var(--btn-pri-color);
    }
    &:focus {
      background-color: var(--btn-pri-color);
      border: 1px solid var(--btn-pri-color);
    }
    &:disabled {
      background-color: var(--btn-pri-color);
      border: 1px solid var(--btn-pri-color);
    }
  }
  &-secondary {
    background-color: #FFF;
    border: 1px solid var(--btn-sec-color);
    color: var(--btn-sec-color);
    &:hover {
      background-color: #FFF;
      border: 1px solid var(--btn-sec-hover);
      color: var(--btn-sec-hover);
    }
    &:disabled {
      background-color: #FFF;
      border: 1px solid var(--btn-sec-color);
      color: var(--btn-sec-color);
    }
  }
  &-orange {
    background-color: #FF840E;
    border: 1px solid #FF840E;
    border-radius: 10px;
    &:hover {
      background-color: var(--btn-pri-hover);
      border: 1px solid var(--btn-pri-color);
    }
    &:focus {
      background-color: var(--btn-pri-color);
      border: 1px solid var(--btn-pri-color);
    }
    &:disabled {
      background-color: var(--btn-pri-color);
      border: 1px solid var(--btn-pri-color);
    }
  }
}
.arrow {
  position: absolute;
  content: '';
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  &-prev {
    left: 7.5%
  }

  &-next {
    right: 7.5%;
  }

  svg {
    width: 12px;
    height: auto;
  }
}
</style>
