<template>
  <div class="component">
    <h2 class="text-center mb-5">Bei welchem Händler haben Sie Ihr Aktionsprodukt gekauft?</h2>
    <div class="wrapper">
      <input type="hidden" v-model="selectedRetailer" />
      <button
        v-for="retailer in retailers"
        :key="retailer.title"
        class="retailer"
        :class="{ selected: selectedRetailer == retailer.title }"
        @click="chooseRetailer(retailer.title)"
      >
        <img v-if="retailer.logo" :src="retailer.logo" class="img" alt="" />
        <p class="m-0" v-else>{{ retailer.text }}</p>
      </button>
      <!-- <button class="retailer">Andere</button> -->
    </div>

    <button
      :class="{ disabled: !selectedRetailer }"
      :disabled="!selectedRetailer"
      type="button"
      class="col-lg-4 next-btn"
      @click="redirect"
    >
      Weiter
    </button>
  </div>
</template>

<script>
export default {
  name: "retailer",
  data () {
    return {
      retailers: [
        { 'title': 'DM', logo: require('../../assets/images/DM drogerie markt logoo.png') },
        { 'title': 'Rossmann', logo : require('../../assets/images/Rossmann_Logo.svg.png') },
        { 'title': 'Real', logo : require('../../assets/images/Real,-Logo.svg.png') },
        { 'title': 'Rewe', logo : require('../../assets/images/1200px-Rewe_-_Dein_Markt_Logo.svg.png') },
        { 'title': 'Edeka', logo : require('../../assets/images/Edeka-logo-png.png') },
        { 'title': 'Andere', text: "Bei einem anderen Händler" }
      ],
      selectedRetailer: ''
    }
  },
  methods: {
    chooseRetailer (e) {
      this.selectedRetailer = e;
      this.$store.state.retailer = e;

      if (this.selectedRetailer === "Andere") {
        this.$store.state.retailer = "";
      }

      if (e === 'DM') {
        this.$store.state.campaignId = 'always-dm';
      } else {
        this.$store.state.campaignId = 'always-3.0-testwochen';
      }
    },
    redirect () {
      if (this.$store.state.campaignId === 'always-dm') {
        this.goToPage('productBarcode', this)
      } else {
        this.goToPage('productSelection', this)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.img {
  height: 80%;
  max-width: 100%;
  object-fit: contain;
}
.component {
  display: flex;
  flex-direction: column;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.retailer {
  width: 34%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: white;
  border-radius: 4px;
  margin: 10px;
  color: var(--brand-color);
  height: 81px;
  border: 3px solid var(--brand-color);
}
.retailer.selected {
  background-color: #d9b7f0;
  border: 3px solid var(--brand-color);
  outline: none;
}
.next-btn {
  font-size: 18px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  letter-spacing: 0.18px;
  margin: 50px auto 0;
  line-height: 21px;
  text-align: center;
  padding: 15px 30px;
  text-transform: uppercase;
  width: 100%;
  color: white;
  background-color: var(--brand-color);
}
.disabled {
  background: #e3e3e3;
  border-color: #e3e3e3;
  color: #6f6f6f;
}
</style>
