<template>
  <div class="selectInputContainer">
    <ValidationProvider
      v-if="validation"
      :rules="validation"
      v-slot="{ errors }"
      :name="name"
    >
      <div class="selectInputWrapper form-group">
        <label :for="name" class="sr-only visually-hidden">{{ placeholder }}</label>
        <select
          v-model="localSelected"
          required
          ref="select"
          :name="name"
          :id="field.fieldId"
          class="form-control"
          @change="updateComponent"
          :class="{ 'is-invalid': errors.length }"
          :style="styles"
        >
          <option value disabled :selected="!selectedValue" hidden></option>
          <option
            v-for="option in options"
            :key="option.value"
            :selected="option.value == selectedValue"
            :disabled="option.disabled"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </select>
        <!-- <img
          v-if="errors.length"
          class="error_min inputError"
          src="../../assets/icons/risk-icon.svg"
          height="18px"
          width="18px"
          alt
        /> -->
        <span class="floating-label">{{ placeholder }}</span>
      </div>
      <div class="validation-error" v-if="errors.length">{{ errors[0] }}</div>
    </ValidationProvider>
    <template v-else>
      <div class="selectInputWrapper form-group">
        <label :for="name" class="sr-only visually-hidden">{{ placeholder }}</label>
        <select
          class="form-control"
          v-model="localSelected"
          required
          ref="select"
          :name="name"
          :id="field.fieldId"
          @change="updateComponent"
          :style="styles"
        >
          <option value disabled :selected="!selectedValue" hidden></option>
          <option
            v-for="option in options"
            :key="option.value"
            :selected="option.value == selectedValue"
            :disabled="option.disabled"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </select>
        <span class="floating-label" :style="`color: ${color}`">{{
          placeholder
        }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "select-input",
  data() {
    return {
      localSelected: this.selectedValue || "",
    };
  },
  props: {
    field: {
      required: false,
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    selectedValue: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "Select Something",
    },
    validation: {
      type: String,
      required: false,
    },
    styles: {
      type: Object,
      required: false,
      default: () => {},
    },
    color: {
      type: String,
      default: "#1D222A",
    },
  },
  methods: {
    updateComponent(event) {
      this.$refs.select.style.color = "#000";
      this.$emit("component-updated", {
        text: event.target.value,
      });
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
$medium: 800px;

.selectInputWrapper {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff url("../../assets/icons/form/dropdown.svg") no-repeat !important;
    background-size: 14px !important;
    background-position: right 10px center !important;
    border-radius: 0.25rem !important;
  }
  position: relative;
  .error_min {
    width: 16px;
  }
  .inputError {
    position: absolute;
    top: 11px;
    right: 10px;
  }
}

select {
  background: transparent;
  outline: 0;
  box-shadow: none;
  transition: border-color 0.15s;
  border-radius: 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-sizing: border-box !important;
  padding-left: 15px !important;
  height: 44px !important;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  color: #081821;
  &.is-invalid {
    border-color: #dc3545 !important;
    background: #fff0f1 !important;
  }
}

.floating-label {
  position: absolute;
  top: -17px;
  left: 0;
  padding-right: 7px;
  transition: transform 0.1s;
  pointer-events: none;
  transform-origin: 0 100%;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 20px;
  color: var(--color);
  font-weight: 700;
}

/* select:focus + .floating-label,
select.visited + .floating-label,
select:not(:focus):valid ~ .floating-label {
  transform: translateY(-135%) scale(0.8);
} */

.validation-error {
  position: absolute;
  bottom: -5px;
  left:10px;
  color: #dc3545;
  font-size: 13px;
  margin-left: 10px;
  margin-top: 5px;
  position: absolute;

  .error_min {
    width: 16px;
  }
}
</style>
