<template>
<div :class="`product-wrapper ${selected ? 'active' : ''}`" :style="cssVars" @click="$emit('itemClick')" @keypress.enter="$emit('itemClick')" tabindex="0" role="button">
  <div :class="`product ${!product.selectable ? 'notSelectable' : ''}`" :style="cssString">
    <img :src="product.backgroundImage" class="product-image" :alt="product.text.title" tabindex="-1">
    <div :class="`isSelectedBox ${selected ? 'active' : ''}`">
      <transition-group name="fade" mode="out-in">
        <svg key="checked" v-if="selected" width="30" height="30" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.5 0H1.5C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V16.5C0 16.8978 0.158035 17.2794 0.43934 17.5607C0.720644 17.842 1.10218 18 1.5 18H16.5C16.8978 18 17.2794 17.842 17.5607 17.5607C17.842 17.2794 18 16.8978 18 16.5V1.5C18 1.10218 17.842 0.720644 17.5607 0.43934C17.2794 0.158035 16.8978 0 16.5 0ZM7.5 13.125L3.75 9.40702L4.9431 8.25L7.5 10.7592L13.0565 5.25L14.2504 6.4329L7.5 13.125Z" :fill="colorSchema"/>
          </svg>

        <!-- <svg key="unchecked" v-else width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V27.5C0 28.163 0.263392 28.7989 0.732233 29.2678C1.20107 29.7366 1.83696 30 2.5 30H27.5C28.163 30 28.7989 29.7366 29.2678 29.2678C29.7366 28.7989 30 28.163 30 27.5V2.5C30 1.83696 29.7366 1.20107 29.2678 0.732233C28.7989 0.263392 28.163 0 27.5 0ZM2.5 27.5V2.5H27.5V27.5H2.5Z" :fill="colorSchema"/>
        </svg> -->

        <!-- <svg key="unchecked" v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" stroke="#97999B"/>
        </svg> -->
      </transition-group>
    </div>
  </div>
  <div class="text">
    <div>
      <div class="title" v-html="$t(product.text.title)"></div>
      <div class="description" v-html="$t(product.text.description)"></div>
    </div>
    <button class="button btn">{{ `${selected ? 'Gewählt' : 'Auswählen'}` }}</button>
  </div>
</div>
</template>

<script>
export default {
  props: {
    product: {
      required: true,
      type: Object
    },
    selected: {
      required: true,
      type: Boolean
    },
    colorSchema: {
      required: true,
      type: String
    },
    cssString: String
  },
  computed: {
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product-wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  padding: 5px;
  border: 2px solid transparent;
  border-radius: 10px;
  .btn {
    background-color: var(--color-8);
  }
  &.active {
    box-shadow: 0px 4px 20px rgba(0, 60, 123, 0.15);
    background: #FFFFFF;
    border: 2px solid var(--color);
    .btn {
      background-color: #648000;
    }
  }
  &:hover {
    box-shadow: 0px 4px 20px 2px rgba(78, 117, 147, 0.35);
    background: #FFFFFF;
  }
  .product {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 20px;
    // background-color: #F1F2F4;

    .product-image {
      max-width: 130px;
      margin: 12px auto;
    }

    &:hover {
      box-sizing: border-box;
      border-radius: 4px;
      transition: .4s ease-in-out
    }

    &.notSelectable {
      opacity: 0.6;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
    .isSelectedBox {
      position: absolute;
      border-radius: 5px;
      top: 10px;
      right: 40px;
      user-select: none;
      svg {
        position: absolute
      }
    }
  }

  .text {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    row-gap: 15px;
    text-align: center;
    font-weight: 500;
    color: #050E23;
    .title{
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      margin-bottom: 20px;
    }
    .description {
      color: #3E3E40;
      font-size: 16px;
      line-height: 189x;
      margin-bottom: 20px;
      text-align: center;
    }
    .button {
      width: 100%;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #FFF;
    }
  }
}
.fade-enter-active, .fade-leave-active {
  opacity: 1;
  transition: .4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
