<template>
  <span></span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "doi-SM",
  props: {
    endpoint: {
      type: String,
      required: true
    },
    title: String,
    text: String,
    button: String,
    campaignName: String
  },
  data: () => {
    return {
      verification_code: "",
      loading: true,
      sent: false
    };
  },
  computed: {
    ...mapGetters(["customerToken", "campaignId", "receiptToken"])
  },
  methods: {
    ...mapActions(['checkDoiSM'])
  },
  mounted() {
    this.verification_code = this.$route.query.verification_code;

    if (this.verification_code) {
      this.checkDoiSM({ verification_code: this.verification_code, endpoint: this.endpoint })
        .then(res => {
          if (window.dataLayer) {
            // eslint-disable-next-line
            dataLayer.push({
              event_action: "CompleteRegistration"
            });
          }
          if (res.response) {
            this.sent = true;
          }
        })
        .catch(er => {
          console.log(er)
          location.href = `/${this.campaignName}`
        });
    } else {
      this.$router.replace(`/${this.campaignName}`);
    }
  }
};
</script>