<template>
  <div class="smsVerificationWrapper col-sm-10">
    <ValidationObserver ref="codeInput" id="codeInput" class="w-100">
      <!-- <ValidationProvider tag="div" name="code" rules="required" v-slot="{ errors }"> -->
        <!-- <v-otp-input
          v-model="code"
          inputClasses="otp-input"
          :numInputs="4"
          separator="-"
          :shouldAutoFocus="true"
          @on-complete="handleOnComplete"
          @on-change="handleOnChange"
        /> -->
        <textInput
          name="code"
          id="code"
          v-model="code"
          label=""
          placeholder="Verification code"
          :validation="`required`"
          :labelOnTop="false"
          :noLabel="true"
          :styles="inputStyles"
        />
      <!-- </ValidationProvider> -->
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import textInput from "@/components/renderers/text-input.vue";

export default {
  name: "sms-receiving",
  components: {
    textInput
  },
  props: {
    authUrl: String,
    successAction: {
      type: Object
    },
    inputStyles:{
      type:Object,
      required:false,
      default:()=>{}
    }
  },
  data () {
    return {
      code: ""
    };
  },
  methods: {
    async verify () {
      const isValid = await this.$refs.codeInput.validate();
      if (!isValid) {
        this.scrollTo(`[id=codeInput`);
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      }

      const formData = {
        mobile: `+${this.countryCode}${this.tel}`,
        code: this.code
      };
      const endpoint = this.authUrl;
      this.$store.dispatch("verifyCode", { formData, endpoint })
        .then(r => {
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
        })
        .catch(err => {
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: false
          });
        });
    },
    handleOnComplete (value) {
      this.code = value;
    },
    handleOnChange (value) {
      this.code = value;
    }
  },
  computed: {
    ...mapGetters(['countryCode', 'tel'])
  },
  mounted () {
    this.$eventHub.$on('verifySms-event', this.verify)
  },
  beforeDestroy () {
    this.$eventHub.$off('verifySms-event', this.verify)
  }
};
</script>
<style lang="scss" scoped>
.smsVerificationWrapper {
  display: flex;
  justify-content: center;
  align-content: stretch;
}

::v-deep .otp-input {
  width: 80px;
  height: 80px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  @media (max-width: 767px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 400px) {
    width: 30px;
    height: 30px;
  }
}
.error {
  border: 1px solid red !important;
}

::v-deep .IZ-select {
  justify-content: center;
  width: 100%;
  border-radius: 100px;
  .validation-error {
    margin-left: 0;
  }

  .IZ-select__input {
    border: none;
    box-shadow: none;
    width: 100px;
    padding-left: 0;
  }

  .IZ-select__menu {
    width: 200px !important;
  }

  .IZ-select__input--selection-slot {
    & > div {
      flex: 1 1 auto;
    }
  }

  input {
    display: none;

    &.custom-input {
      display: block;
    }
  }
}

::v-deep .hide .IZ-select__menu {
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
}

</style>
