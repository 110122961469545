<template>
  <div class="steps_container mobileStepContainer" :style="[styles]">
    <div class="progressbar">
      <div
        v-for="(step, idx) in texts"
        :key="idx"
        class="progress-step"
        :data-step="idx + 1"
        :data-title="$t(step)"
        :class="activeIndex - 1 > idx ? 'done' : activeIndex - 1 == idx ? 'active' : ''"
      >
        {{ activeIndex > idx + 1 ? '' : idx + 1 }}
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "step-counter",
  props: {
    grid: {
      type: Object,
      default: () => {}
    },
    texts: {
      type: Array,
      required: true
    },
    actions: {
      type: Object
    },
    styles: {
      type: Object,
      default: () => {}
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000),
    triggerActions () {
      if (this.actions) {
        this.actions.forEach(action => {
          this.$store.dispatch(action, { val: 123 });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$gap-lg: 180px;
$gap-md: 90px;
$gap-sm: 70px;
$gap-xs: 50px;

$pri-color: #4F4F4F;
$sec-color: #BABABA;
$txt-color: #FFF;

li {
  list-style-type: none;
}

img {
  vertical-align: baseline;
}

.mobileStepContainer {
  @media (max-width: 991px) {
    max-width: 50% !important;

    .progressbar {
      gap: $gap-md;

      .progress-step {
        width: 40px !important;
        height: 40px !important;

        &:not(:first-child)::before {
          width: $gap-md;
        }
        &::after {
          display: flex;
          text-align: center;
          align-items: center;
        }
      }
    }
  }

  @media (max-width: 615px) {
    max-width: 100% !important;
    margin-bottom: 50px !important;
    .progressbar {
      gap: $gap-sm;

      .progress-step {
        width: 30px !important;
        height: 30px !important;

        &:not(:first-child)::before {
          width: $gap-sm;
          right: 28px;
        }

        &::after {
          display: flex;
          text-align: center;
          align-items: center;
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 479px) {
    max-width: 100% !important;
    margin-bottom: 50px !important;

    .progressbar {
      gap: $gap-xs;

      .progress-step {
        width: 30px !important;
        height: 30px !important;

        &:not(:first-child)::before {
          width: $gap-xs;
        }

        &::after {
          display: flex;
          text-align: center;
          align-items: center;
          font-size: 10px;
          width: fit-content;
        }
      }
    }
  }
}
.steps_ul {
  padding: 0;
  margin: 0;
  text-align: center;
  margin: 0 auto;

  li:not(:last-child).done {
    .bg > .doneContainer > .done {
      @media (max-width: 450px) {
        margin-top: 16px;
      }
    }
  }
}

.progresstexts {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 !important;
}

.progressbar {
  position: relative;
  display: flex;
  justify-content: center;
  gap: $gap-lg;

  .progress-step {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    aspect-ratio: 1/1 !important;
    border: 2px solid $sec-color;
    border-radius: 50%;
    background: transparent;
    text-align: center;
    color: $sec-color;
    font-weight: 700;
    font-size: 18px;
    position: relative;

    &:not(:first-child)::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 38px;
      transform: translate(0, -50%);
      height: 2px;
      width: $gap-lg;
      border-bottom: 2px dashed $sec-color;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &::after {
      content: attr(data-title);
      white-space: pre;
      position: absolute;
      top: calc(100% + 10px);
      width: max-content;
      color: $sec-color;
      font-size: 16px;
    }
  }

  .active {
    color: $txt-color;
    background-color: $pri-color;
    background-position: center;
    background-repeat: no-repeat;
    border-color: $pri-color;

    &::before {
      border-bottom-style: solid !important;
      border-bottom-color: $pri-color !important;
    }

    &::after {
      color: $pri-color;
    }
  }

  .done {
    color: $pri-color;
    background-image: url('../../assets/images/svg/checkMark2.svg') !important;
    background-color: $pri-color;
    background-position: center;
    background-repeat: no-repeat;
    border-color: $pri-color;

    &::before {
      border-bottom-style: solid !important;
      border-bottom-color: $pri-color !important;
    }

    &::after {
      color: $pri-color;
    }
  }
}

.progressbar li {
  list-style: none;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.steps_ul {
  padding: 0;
  margin: 0;
  text-align: center;
  margin: 0 auto;
  width: 100% !important;
}

.steps {
  padding-top: 160px;
  padding-bottom: 110px;
}

.steps_container {
  margin-top: 20px;
  margin-bottom: 80px;
}

.step_action {
  .bg {
    font-size: 25px;
    line-height: 37.5px;
    width: 50px;
    height: 50px;
    border: 2px solid #9DCAFF;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0px auto;
    background-color: transparent;
    z-index: 2;
    position: relative;
    @media (max-width: 450px){
      font-size: 7vw;
      width: 10vw;
      height: 10vw;
    }
    .stepNumber {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      transform: translateY(-50%);
      z-index: 2;
    }
    .done {
      max-width: 2vw;
      position: absolute;
      @media (max-width: 450px){
        max-width: 5vw;
      }
    }
    & > img {
      margin-left: 50px;
    }
  }
  .step_text {
    font-size: 14px;
    margin-top: 10px;
    line-height: 24px;
    text-align: center !important;
  }
  &.done {
    .bg {
      background: #E3F0FF;
      border: none;
    }
    .step_text {
      color: var(--color-5);
      font-weight: 700;
    }
  }
}

@media (max-width: 400px) {
   .steps_container {
     padding: 0 !important;
     margin-bottom: 50px;
   }
   .step_text {
     font-size: 14px !important;
   }
}
</style>
